<template>
  <v-app id="inspire" style="background:#F9F9F9;">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid>
        <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12" md="8">
              <v-card class="center mt-10 mb-15" height="100%" align="justify"
            justify="center">
                <v-card-text>
                  <v-row>
                     <v-col
                      cols="1"
                      md="1"
                    ></v-col>
                    <v-col
                      cols="4"
                      md="4"
                    >
                     <h3 class=" mb-5">Payment Method</h3>
                     <v-card
                            class="mx-auto"
                            outlined
                        >
                            <v-list-item three-line>
                            <v-list-item-content>
    
                                <v-list-item-title class="headline mb-1">
                                User Credit
                                </v-list-item-title>
                                <v-list-item-subtitle>Avaibale Credit:</v-list-item-subtitle>
                                <v-list-item-subtitle><h3>${{credit}}</h3></v-list-item-subtitle>
                            </v-list-item-content>

                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="green"
                            >
                            <v-icon dark>
                                fa-check
                            </v-icon>
                            </v-btn>
                            </v-list-item>
                        </v-card>

                    </v-col>
                    <v-col
                      cols="1"
                      md="1"
                    ></v-col>
                    <v-col
                      cols="6"
                      md="6"
                    >
                     <h3 class=" mb-5">Review Your Order</h3>
                     <v-card
                            class="mx-auto"
                            outlined
                            style="text-align: initial;"
                        >
                        <v-row v-for="item in cartList" :key="item.id">
                        <v-col
                        cols="8"
                        md="8"
                        >
                            <v-list-item three-line>
                            <v-list-item-content>
                            <v-list-item-subtitle><h3>Certified Mail - Letter in a 6 x 9.5 Envelope</h3></v-list-item-subtitle>
                            </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                        cols="4"
                        md="4"
                        >
                            <v-list-item three-line>
                            <v-list-item-content>
                            <v-list-item-subtitle><h1>$5.91</h1></v-list-item-subtitle>
                            </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                        cols="12"
                        md="12"
                        >
                        <v-divider></v-divider>
                        </v-col>
                        </v-row>
                        <v-row >
                            <v-col
                        cols="4"
                        md="4"
                        ></v-col>
                        <v-col
                        cols="4"
                        md="4"
                        >
                            <v-list-item three-line>
                            <v-list-item-content style="text-align: end;">
                            <v-list-item-subtitle><h4>Cart Subtotal:</h4></v-list-item-subtitle>
                            <v-list-item-subtitle ><h4 style="color:red;">Grand total:</h4></v-list-item-subtitle>
                            </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                        cols="4"
                        md="4"
                        >
                            <v-list-item three-line>
                            <v-list-item-content>
                            <v-list-item-subtitle><h2>${{subtotal}}</h2></v-list-item-subtitle>
                            <v-list-item-subtitle><h2 style="color:red;">${{grandtotal}}</h2></v-list-item-subtitle>
                            </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col
                        cols="12"
                        md="12"
                        >
                        <v-divider></v-divider>
                        </v-col>
                        <v-col
                        cols="12"
                        md="12"
                        >
                        <v-card-actions class="justify-center " style="padding: 0px;">
                          <v-form
                          ref="form"
                          lazy-validation
                        >
                        <v-checkbox
                        v-model="checkbox"
                        class="ml-5"
                        :rules="[rules.required]"
                        :label="`I accept the Terms of Service`"
                        ></v-checkbox>
                          </v-form>
                        </v-card-actions>
                        </v-col>
                        <v-col cols="12" >
                            <v-card-actions class="justify-center " style="padding: 0px;">
                                <v-btn  class="mb-10 ml-5" width="300px" dark color="blue" @click="checkout()" >Place Order</v-btn>
                            </v-card-actions>
                            
                        </v-col>
                        </v-row>
                        </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
        </v-row>
    </v-container>

<v-overlay :value="loading">
   <rotate-loader :loading="true" color="#fff"></rotate-loader>
  </v-overlay>
</v-main>
  </v-app>
  
</template>

<script>
import moment from 'moment';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
    components: {'RotateLoader': ClipLoader},
    data: () => ({
        credit: localStorage.getItem('credit'),
        job_count: localStorage.getItem('job_count'),
        doc_count: localStorage.getItem('doc_count'),
        mail_list_count: localStorage.getItem('mail_list_count'),
        cartList: [],
        subtotal:localStorage.getItem('subtotal'),
        grandtotal: localStorage.getItem('grandtotal'),
        cartList: JSON.parse(localStorage.getItem('cart_items')),
        loading:false,
        checkbox:'',
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 3 || 'Min 3 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },

    }),
    created () { 
    },
    methods:{
        formatDate(value) {
            return moment(value).format("MM/DD/YYYY")
        },
        checkout(){
          if(this.$refs.form.validate()){
            this.loading = true;
           axios
            .post("/api/checkout",{'jobs': JSON.parse(localStorage.getItem('cart_items'))},this.header)
            .then(response => {
                let data = response.data;
              console.log(data);
            if (data.flag == 1) {
                this.loading = false;
                  Vue.$toast.success('Items puchased', {
                    position: 'top-right'
                    });
                localStorage.setItem('subtotal','');
                localStorage.setItem('grandtotal','');
                localStorage.setItem('cart_items','');
                this.$router.push('/');
                
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
            });
          }
        }

    }
}
</script>

<style>

</style>