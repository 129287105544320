<template>
  <div class="hv-state-stepper">
    <!-- <v-stepper class="hidden-sm-and-up pa-0" vertical>
      <template v-for="(state, stateIndex) in states">
        <v-stepper-step
          :color="state.current ? currentColor : previousColor"
          :complete="stateIndex <= currentIndex"
          :complete-icon="state.current ? currentIcon : previousIcon"
          :key="stateIndex"
          step=""
        >
          <span>{{ state.name }}</span>
          <small v-if="state.actualDate">
            Last actual date:
            <b>{{ state.actualDate | i18nDateTime }}</b>
          </small>
        </v-stepper-step>
        <v-divider :key="`d${stateIndex}`" v-if="stateIndex < states.length - 1" />
      </template>
    </v-stepper> -->
    <!-- <v-stepper alt-labels class="hidden-xs-only">
      <v-stepper-header>
        <template v-for="(state, stateIndex) in states">
          <v-menu :key="stateIndex" bottom offset-y open-on-hover>
            <template #activator="{ on, value }">
              <v-stepper-step

                :class="[value && 'v-stepper__step--hover']"
                :color="state.current ? currentColor : previousColor"
                :complete="stateIndex <= currentIndex"
                :complete-icon="state.current ? currentIcon : previousIcon"
                step=""
              >
                {{ state.name }}
              </v-stepper-step>
            </template>
          </v-menu>
          <v-divider :key="`d${stateIndex}`" v-if="stateIndex < states.length - 1" />
        </template>
      </v-stepper-header>
    </v-stepper> -->
  
  
<!-- 
<div class="arrow-steps clearfix">
          <div class="step done"> <span> Customer Details</span> </div>
          <div class="step done"> <span>Agreement Signed</span> </div>
          <div class="step done"> <span> Required Document</span> </div>
          <div class="step done"> <span>Credit Import</span> </div>
          <div class="step current"> <span> Dispute Round 1</span> </div>
          <div class="step"> <span>Dispute Round 2</span> </div>
          <div class="step"> <span> Dispute Round 3</span> </div>
          <div class="step"> <span>Dispute Round 4</span> </div>
			</div> -->
<ul id="progress">
    <li v-for="(state, stateIndex) in states" v-bind:key="stateIndex" :class="state.current ? 'active' : state.done ? 'done': ''">{{state.name}}</li>
</ul>
  
  
  </div>
</template>

<script>
import {
  VCard,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VMenu,
  VStepper,
  VStepperHeader,
  VStepperStep
} from 'vuetify/lib';

export default {
  components: {
    VCard,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VMenu,
    VStepper,
    VStepperHeader,
    VStepperStep,
  },
  computed: {
    currentIndex() {
      return this.states.findIndex(state => state.current);
    },
  },
  name: 'HVStateStepper',
  props: {
    currentColor: String,
    currentIcon: String,
    previousColor: String,
    previousIcon: String,
    states: {
      type: Array,
      required: true
    },
  },
};
</script>

<style>
.hv-state-stepper .v-stepper__header .v-stepper__step {
  flex-basis: 100px !important;
  padding: 8px 0 !important;
  /* height: 25px;
  width: 25px; */
}
.hv-state-stepper .v-stepper__header .v-stepper__step__step {
  border-style: groove;
  border-width: 0;
  transition: border-width .3s;
}
.hv-state-stepper .v-stepper__header .v-stepper__step--hover .v-stepper__step__step {
  border-width: 12px;
}
.hv-state-stepper .v-stepper__header .v-stepper__label {
  text-align: center;
}
/* .hv-state-stepper .v-stepper__header .v-divider {
  margin: 20px -34px 0;
} */
.v-stepper--alt-labels .v-stepper__step__step {
  margin-bottom: 16px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 16px;
  height: 25px;
  width: 25px;
}
.hv-state-stepper .v-stepper__header .v-divider {
  margin: 35px -26px 0!important;
}
.hv-state-stepper .theme--light.v-stepper {
  background: transparent!important;
}
.hv-state-stepper .v-stepper, .v-stepper__header {
  box-shadow: none!important;
}
















/* Global CSS, you probably don't need that */

.clearfix:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
}

/* .container {
	font-family: 'Lato', sans-serif;
	width: 1000px;
	margin: 0 auto;
} */

.wrapper {
	display: table-cell;
	height: 400px;
	vertical-align: middle;
}

.nav {
	margin-top: 40px;
}

.pull-right {
	float: right;
}

a, a:active {
	color: #333;
	text-decoration: none;
}

a:hover {
	color: #999;
}

/* Breadcrups CSS */

.arrow-steps .step {
font-size: 12px;
text-align: center;
color: #666;
cursor: default;
margin: 0 3px;
padding: 10px 10px 10px 30px;
min-width: 154px;
float: left;
position: relative;
background-color: #d9e3f7;
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
	content: " ";
	position: absolute;
	top: 0;
	right: -17px;
	width: 0;
	height: 0;
	border-top: 19px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 17px solid #d9e3f7;	
	z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
	right: auto;
	left: 0;
	border-left: 17px solid #fff;	
	z-index: 0;
}

.arrow-steps .step:first-child:before {
	border: none;
}

.arrow-steps .step:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
	position: relative;
}

/* .arrow-steps .step span:before {
	opacity: 0;
	content: "✔";
	position: absolute;
	top: -2px;
	left: -20px;
} */

.arrow-steps .step.done span:before {
	opacity: 1;
	-webkit-transition: opacity 0.3s ease 0.5s;
	-moz-transition: opacity 0.3s ease 0.5s;
	-ms-transition: opacity 0.3s ease 0.5s;
	transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
	color: #fff;
	background-color: #23468c;
}
.arrow-steps .step.done {
	color: #fff;
	background-color: #4CAF50;
}
.arrow-steps .step.done:after {
	border-left: 17px solid #4CAF50;	
}
.arrow-steps .step.current:after {
	border-left: 17px solid #23468c;	
}





#progress {
    padding: 0;
    list-style-type: none;
    font-family: arial;
    font-size: 11px;
    clear: both;
    line-height: 1em;
    margin: 0 -1px;
    text-align: center;
}

#progress li {
    float: left;
    padding: 10px 10px 10px 30px;
    background: #D9E3F7;
    color: #666;
    position: relative;
    border-top: 1px solid #D9E3F7;
    border-bottom: 1px solid #D9E3F7;
    width: auto;
    margin: 0 3px;
}

#progress li:before {
    content: '';
    border-left: 16px solid #fff;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    
}
#progress li:after {
    content: '';
    border-left: 16px solid #D9E3F7;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 20;
}

#progress li.active {
    background: #23468c;
    color: white;
}

#progress li.active:after {
    border-left-color: #23468c;
}
#progress li.done {
    background: #4CAF50;
    color: white;
}

#progress li.done:after {
    border-left-color: #4CAF50;
}
</style>
