<template>
<v-app-bar absolute dark elevation="10" extension-height="64" color="white" height="auto">
      <v-container class="fill-height text-center" style="justify-content: center;">
        <img  :height="this.$vuetify.breakpoint.xs ? 50 : 70" class="image-logo2" src="@/assets/img/logo.png" />
        <!-- <img  :height="this.$vuetify.breakpoint.xs ? 50 : 50" class="image-logo2" src="@/assets/img/logo.png" /> -->
        <!-- <h1 class="" style="color:#5DB461;">
            QUANTUM DISPUTE
          </h1> -->
      </v-container>
      <template #extension>
        <v-toolbar color="secondary" dark>
          <v-container>
            <v-row>
              <v-col cols="3"></v-col>
            <v-col cols="6" class="mx-auto text-center ">
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-menu
              v-for="item in links"
              :key="item.name"
              :open-on-hover="!item.single"
              offset-y
              bottom
              min-width="360"
              max-width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.name != 'Profile'"
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  color="transparent"
                  :class="$route.path == item.link ? 'active-button' : 'text-white'"
                  class="
                    
                    font-weight
                    text-capitalize
                    ls-0
                    align-center
                  "
                  depressed
                  link
                  :to="item.link ? item.link : '#'"
                >
                  {{ item.name }}
                  <v-img
                  v-if="!item.single"
                    src="@/assets/img/down-arrow-white.svg"
                    alt="down-arrow"
                    class="arrow ms-1 d-lg-block d-none"
                  />
                </v-btn>
                  <v-btn
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                  color="transparent"
                  :class="$route.path == item.link ? 'active-button' : 'text-white'"
                  class="
                    
                    font-weight
                    text-capitalize
                    ls-0
                    align-center
                  "
                  depressed
                  link
                >
                  {{ item.name }}
                  <v-img
                  v-if="!item.single"
                    src="@/assets/img/down-arrow-white.svg"
                    alt="down-arrow"
                    class="arrow ms-1 d-lg-block d-none"
                  />
                </v-btn>
              </template>

              <v-list class="border-radius-xl overflow-hidden">
                <template v-if="item.name == 'Pages'">
                  <app-bar-pages></app-bar-pages>
                </template>
                <template v-if="item.name == 'Authentication'">
                  <app-bar-authentication></app-bar-authentication>
                </template>
                <template v-if="item.name == 'Applications'">
                  <app-bar-applications></app-bar-applications>
                </template>
                <template v-if="item.name == 'Ecommerce'">
                  <app-bar-ecommerce></app-bar-ecommerce>
                </template>
                <template v-if="item.name == 'Profile'">
                      <v-list>
                        <v-list-item>
                          <!-- <v-list-item-avatar> -->
                            <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img> -->
                             <v-avatar size="100" class="my-3 ms-2">
                            <img v-if="!avatar" src="@/assets/img/avatar.png" alt="Brooklyn" />
                            <img v-else :src="avatar" alt="Brooklyn" />
                          </v-avatar>
                          <!-- </v-list-item-avatar> -->
                        </v-list-item>

                        <v-list-item link>
                          <v-list-item-content>
                            <v-list-item-title class="text-h3">
                              {{name}}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{email}}</v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-icon>mdi-menu-down</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                  <v-list class="px-3">
                   
                      <v-list-item v-for="item_child in item.pages" :key="item_child.title" class="mb-0 ps-0">
                        <v-btn
                          :ripple="false"
                          text
                          v-if="item_child.title != 'Logout'"
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          :to="item_child.link"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }}</span>
                          <v-spacer></v-spacer>
                        </v-btn>
                        <v-btn
                          :ripple="false"
                          text
                          v-if="item_child.title == 'Logout'"
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          @click="logout()"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }}</span>
                          <v-spacer></v-spacer>
                        </v-btn>
                      </v-list-item>
                    </v-list>
                </template>
                <template v-if="item.name == 'Custom Links'">
                  <v-list class="px-3">
                   
                      <v-list-item v-for="item_child in item.pages" :key="item_child.title" class="mb-0 ps-0">
                        <v-btn
                          :ripple="false"
                          text
                          v-if="item_child.is_iframe"
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          :to="item_child.link"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }}</span>
                          <v-spacer></v-spacer>
                        </v-btn>
                        <v-btn
                          :ripple="false"
                          text
                          v-else
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          @click="gotoLink(item_child.link_url)"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }} </span>
                          <v-spacer></v-spacer>
                        </v-btn>
                        <v-btn
                          :ripple="false"
                          text
                          v-if="item_child.title == 'Logout'"
                          class="text-dark font-weight-normal text-capitalize ls-0"
                          depressed
                          link
                          @click="logout()"
                        >
                          <v-icon v-if="item_child.title != 'Logout'" size="16" class="material-icons-round text-dark opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <v-icon v-if="item_child.title == 'Logout'" size="16" class="material-icons-round active-button opacity-6 me-3">
                            {{ item_child.icon }}
                          </v-icon>
                          <span :class="item_child.title == 'Logout'  ? 'active-button' : ''">{{ item_child.title }}</span>
                          <v-spacer></v-spacer>
                        </v-btn>
                      </v-list-item>
                    </v-list>
                </template>
              </v-list>
            </v-menu>
          </template>
        </v-col>
         <v-col cols="3" class="text-right align-center">
          
          <template v-if="!$vuetify.breakpoint.mobile && !company_id">
            
            <v-btn
              elevation="0"
              color="#FF5353"
              @click="$router.push('/pricing')"
              class="font-weight-bolder py-4 px-4 ms-2"
              :class="'btn-primary '
              "
              href="javascript:;"
            >
              Buy Credit
            </v-btn>
          </template>
          <v-btn
              elevation="0"
              color="blue"
              v-if="from_admin"
              @click="loginAdmin"
              class="font-weight-bolder py-4 px-4 ms-2"
              :class="'btn-primary '
              "
              href="javascript:;"
            >
              Back to admin
            </v-btn>
          <v-btn
            v-show="$vuetify.breakpoint.mobile"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-600
              text-capitalize
              drawer-toggler
              btn-toggler-hover
              py-3
              px-6
              rounded-sm
            "
            color="white"
            @click="dialog = true"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
              <i class="drawer-toggler-line" :class="{ 'bg-white': hasBg }"></i>
            </div>
          </v-btn>

          <v-dialog
            v-model="dialog"
            content-class="position-absolute top-0"
            width="95%"
          >
            <v-card class="card-shadow card-padding">
              <v-card-title
                class="
                  pt-0
                  px-4
                  card-padding
                  text-h6
                  font-weight-bold
                  text-typo
                  justify-space-between
                  border-bottom
                "
              >
                QUANTUM DISPUTE

                <v-btn icon width="31" :ripple="false" @click="dialog = false">
                  <v-icon size="18" class="text-typo">fas fa-times</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="card-padding pb-0 d-flex flex-column px-0">
                <v-btn
                  v-for="item in links"
                  :key="item.name"
                  :ripple="false"
                  text
                  class="text-typo text-capitalize ls-0"
                  depressed
                  link
                  :to="item.link"
                >
                  {{ item.name }}
                  <v-spacer></v-spacer>
                </v-btn>

                <div class="border-bottom my-7"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
            </v-row>




            <v-dialog
                      v-model="dialog2"
                      persistent
                      max-width="890"
                    >
                      <v-card>
                        <v-card-title class="text-h5" style="text-align: center;display: block ruby;">
                          <img  :height=" 100" class="image-logo2 mt-10" src="@/assets/img/logo.png" style="height: 100%;
                width: 300px; ">
                        </v-card-title>
                        <v-card-text><h1 class="text-h3 text-typo font-weight-bold text-center " style="color:#4CAF50 !important;" v-if="e1 != 3">Let's get started</h1><h1 class="text-h3 text-typo font-weight-bold text-center " style="color:#4CAF50 !important;" v-else>Initial Setup Complete</h1></v-card-text>
                        <v-card-text>

                          <v-stepper
                            alt-labels
                            elevation="0"
                            v-model="e1"
                            class="bg-transparent overflow-visible mt-10"
                          >
                            <div class="pt-1">
                              <v-stepper-header
                                class="
                                  shadow-green
                                  border-radius-lg
                                  mx-4
                                  mt-n10
                                "
                              >
                                <v-stepper-step step="1" color="green">
                                  <span
                                    class="
                                      text-black
                                      text-xs
                                      font-weight-normal
                                    "
                                    >Create Account</span
                                  >
                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step step="2" color="green">
                                  <span
                                    class="
                                      text-black
                                      text-xs
                                      font-weight-normal
                                    "
                                    >Personal Details</span
                                  >
                                </v-stepper-step>

                                <v-divider></v-divider>

                            
                                <v-stepper-step step="3" color="green">
                                  <span
                                    class="
                                      text-black
                                      text-xs
                                      font-weight-normal
                                    "
                                    > Start Crushing!</span
                                  >
                                </v-stepper-step>
                              </v-stepper-header>
                            </div>
                            <v-stepper-items class="border-radius-xl overflow-hidden mt-5">
                              <v-stepper-content
                                step="2"
                                class="bg-white border-radius-xl px-7 pt-2"
                              >
                                <v-card>
                                  <div>
                                    <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                                      Personal Details
                                    </h5>
                                    <p class="mb-0 text-sm text-body font-weight-light">
                                      Mandatory informations
                                    </p>
                                    <v-row class="mt-2">
                                      <v-col sm="6" cols="12" class="py-0">
                                        <v-text-field
                                        id="name"
                                        name="name"
                                        class="font-size-input input-style"
                                        label="First Name*"
                                        width="300px"
                                        ref="name"
                                        v-model="company.first_name"
                                        />
                                      </v-col>
                                      <v-col sm="6" cols="12" class="py-0">
                                        <v-text-field
                                        id="name"
                                        name="name"
                                        class="font-size-input input-style"
                                        label="Last Name*"
                                        width="300px"
                                        ref="name"
                                        v-model="company.last_name"
                                        />
                                      </v-col>
                                      <v-col sm="6" cols="12" class="py-0">
                                        <v-text-field
                                        id="name"
                                        name="name"
                                        class="font-size-input input-style"
                                        label="Email*"
                                        width="300px"
                                        ref="name"
                                        v-model="company.email"
                                        />
                                      </v-col>

                                      <v-col sm="6" cols="12" class="py-0">
                                        <v-text-field
                                        id="name"
                                        name="name"
                                        class="font-size-input input-style"
                                        label="Phone*"
                                        v-mask="'(###) ###-####'"
                                        width="300px"
                                        ref="name"
                                        v-model="company.phone_m"
                                        />
                                      </v-col>
                                      <v-col sm="6" cols="12" class="py-0">
                                        <v-text-field
                                        id="name"
                                        name="name"
                                        label="Business Name"
                                        class="font-size-input input-style"
                                        width="300px"
                                        ref="name"
                                        v-model="company.business_name"
                                        />
                                      </v-col>
                                      <v-col sm="6" cols="12" class="py-0">
                                        <v-text-field
                                        id="name"
                                        name="name"
                                        label="Business Adress"
                                        class="font-size-input input-style"
                                        width="300px"
                                        ref="name"
                                        v-model="company.business_address"
                                        />
                                      </v-col>
                                    </v-row>

                                    <v-row class="mt-2">
                                      <v-col sm="6" cols="12" class="py-0">
                                        <v-text-field
                                          label="Instagram"
                                          v-model="company.instagram"
                                          class="font-size-input input-style" />
                                      </v-col>
                                      <v-col sm="6" cols="12" class="py-0">
                                        <v-text-field
                                          label="Facebook"
                                          v-model="company.facebook"
                                          class="font-size-input input-style" />
                                      </v-col>
                                      <v-col sm="6" cols="12" class="py-0">
                                        <v-text-field
                                          label="Youtube Channel"
                                          v-model="company.youtube"
                                          class="font-size-input input-style" />
                                      </v-col>
                                      <v-col sm="6" cols="12" class="py-0">
                                        <v-text-field
                                          label="LinkedIn"
                                          v-model="company.linkedin"
                                          class="font-size-input input-style" />
                                      </v-col>
                                      <v-col sm="12" cols="12" class="py-0">
                                        <v-text-field
                                          label="Website"
                                          v-model="company.website"
                                          class="font-size-input input-style" />
                                      </v-col>
                                   
                                    
                                      </v-row>
                                  </div>
                                  <div class="text-end">
                                    <v-btn
                                      :ripple="false"
                                      :elevation="0"
                                      @click="saveProfile()"
                                      class="
                                        font-weight-bold
                                        text-xs
                                        btn-default
                                        bg-gradient-success
                                        py-5
                                        px-6
                                        mt-6
                                        mb-2
                                        me-2
                                      "
                                      color="primary"
                                    >
                                      Save & Continue
                                    </v-btn>
                                  </div>
                                </v-card>
                              </v-stepper-content>


                              <v-stepper-content
                                step="3"
                                class="bg-white border-radius-xl px-7 pt-4"
                              >
                                <v-card>
                                  <div>
                                    <!-- <h1 class="text-h3 text-typo font-weight-bold text-center " >Congratulations, your account is all </h1><h1 class="text-h3 text-typo font-weight-bold text-center " >
                                      set and ready to go!</h1> -->
                                      <h1 class="text-center text-typo  mt-5 mb-5">Here is your Personal Link: </h1>
                                      <h3 class="text-center text-typo  mt-5 mb-5"><span style="color:#4CAF50">{{ base_url }}/contact/{{ company.user_id }}</span></h3>
                                    <p class="mb-0 text-sm text-body font-weight-light text-center">
                                      If you need to make changes to any of the setup items, you can find them in the Profile tab.
                                    </p>
                                    
                                  </div>
                                  <div class="d-flex mt-5">
                                    
                                    <v-btn
                                      :ripple="false"
                                      :elevation="0"
                                      @click="compleStep()"
                                      class="
                                        font-weight-bold
                                        text-xs
                                        bg-gradient-success
                                        py-5
                                        px-6
                                        mt-6
                                        mb-2
                                        me-2
                                        ms-auto
                                        shadow-none
                                      "
                                      color="primary"
                                    >
                                      Finish
                                    </v-btn>
                                  </div>
                                </v-card>
                              </v-stepper-content>
                            </v-stepper-items>
                          </v-stepper>


                        </v-card-text>
                      
                        <v-card-actions>

                        </v-card-actions>
                      </v-card>
                    </v-dialog>
          </v-container>
        </v-toolbar>
      </template>
    </v-app-bar>
    
</template>
<script>


export default {
  name: "app-bar",
  components: {
    // AppBarPages,
    // AppBarAuthentication,
    // // AppBarApplications,
    // AppBarEcommerce,
    // AppBarDocs,
  },
  props: {
    background: String,
    hasBg: true,
    linkColor: String,
  },
  created () {
    console.log(this.$route.path);
    this.getUserProfile()
    if( localStorage.getItem('skip_startup') != 1 &&  localStorage.getItem('completed_startup') != 1)
      this.dialog2 = true;
    this.getLinks();
  },
  methods: {
    skipStartUp(){
      localStorage.setItem('skip_startup',1)
      this.dialog2 = false
    },
    compleStep(){
      localStorage.setItem('completed_startup',1)
      this.dialog2 = false
    },
    loginAdmin(){
      if(confirm('Are you sure, You want to login as a admin?')){
            axios({
              method: "POST",
              
              url: "/api/auth/login-admin-client-admin",
              data: {
                email: this.from_admin_email            }
            })
              .then(response => {
                let data = response.data;
                if (data.flag == 1) {
                  let result = data.data;
                  localStorage.setItem("token", data.token.token);
                  localStorage.setItem("user_id", result.user_id);
                  localStorage.setItem("first_name", result.first_name);
                  localStorage.setItem("email", result.email);
                  localStorage.setItem("is_admin", result.is_admin);
                  localStorage.setItem("name", result.first_name+' '+result.last_name);
                  localStorage.setItem("role", JSON.stringify(result.roles[0]));
                  localStorage.setItem("company_name", result.company_name);
                  localStorage.setItem("no_tiptext_flag", '');
                  localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+result.logo);
                  localStorage.setItem("company_id", result.company_id);
                  this.$router.push("/admin/dashboard");
                  location.reload();
                } else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
              })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              });
          }
    },
    getLinks(){
          let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
          axios
            .get("/api/get-all-link", {headers: headers})
            .then(response => {
              this.custom_links = response.data.data
              this.custom_links.forEach(val =>{
                if(val.client)
                  this.links[4].pages.push({
                      title: val.title,
                      icon: val.icon,
                      link: "/custom-link/"+val.id,
                      link_url  : val.url,
                      is_iframe : val.is_iframe
                    })
              })
              
            })
        },
        gotoLink(item){
          window.open(item, "_blank");
        },
        getUserProfile() {
          let flag = 1;
          let id  = localStorage.getItem('company_id');
          let header = { headers : {
                      Authorization:"Bearer " + localStorage.getItem('token')
                      }
              }
            axios
              .get("/api/user",header)
              .then(response => {
                  let data = response.data.user;
                if (flag == 1) {
                  console.log(data);
                      this.company = data
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });

                });

     },
     saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.company.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        if (!this.company.phone_m && flag == 1) {
            Vue.$toast.error('Phone is required', {
                position: 'top-right'
            });
            this.$refs['phone'].focus()
            flag = 0;
        }
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.company.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }

        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/updateprofile",
          data: self.company
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Profile updated', {
                    position: 'top-right'
                  })
                  self.e1 = 3;

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },
    logout() {
      let self = this;
        let headers = {
            Authorization: "Bearer " + localStorage.getItem('token')
        }
      axios
        .get("/api/logout", {headers: headers})
        .then(response => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            if(self.company.company_url)
              this.$router.push("/login/"+self.company.company_url);
            else
              this.$router.push("/");
            window.location.reload();
        })
        .catch(error => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            this.$router.push("/");
            location.reload();
        });
    },
  },
  data() {
    return {
      plan : localStorage.getItem('plan') ,
      company : '',
      custom_links: [],
      dialog: false,
      dialog2 : false,
      e1 : 2,
      base_url: window.location.origin,
      name : localStorage.getItem('name'),
      user_id: localStorage.getItem('user_id'),
      email: localStorage.getItem('email'),
      avatar : localStorage.getItem('avatar') ? localStorage.getItem('avatar') : '',
      loadingLogo : false,
      from_admin : localStorage.getItem('from_admin') ? localStorage.getItem('from_admin') : 0,
      from_admin_email : localStorage.getItem('from_admin_email') ? localStorage.getItem('from_admin_email') : '',
      logo  : localStorage.getItem('company_logo'),
      company_id : localStorage.getItem('company_id') == "null" ? '' : localStorage.getItem('company_id'),
      doc_flag : localStorage.getItem('agreement_flag'),
      links: [
        {
          name: "Dashboard",
          single: true,
          link: "/dashboard",
        },
        // {
        //   name: "My Requests",
        //   link: "/my-request",
        //   single: true
        // },
        {
          name: "Billing",
          link: "/credit-history",
          single: true
        },
        {
          name: "Profile",
          link: "/pages/pages/register",
          single: false,
          pages: [
                  {
                    title: "Edit Profile",
                    icon: "fa-user",
                    link: "/profile",
                  },
                  {
                    title: "Change Password",
                    icon: "fa-lock",
                    link: "/change-password",
                  },
                  {
                    title: "Logout",
                    icon: "fa-power-off",
                    link: "/pages/applications/wizard",
                  },
                 
                ],
        },
        {
          name: "Custom Links",
          link: "",
          single: false,
          pages: [
                  
                 
                ],
        },
       
      ],
    };
  },
};
</script>
<style>
.v-toolbar__content, .v-toolbar__extension {
  padding: 0;
}
.btn-dark-hover:hover, .btn-dark-hover:focus {
  color: #53AD57 !important;
}
.active-button{
  color : #FF5353 !important;
}
</style>