<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6 mb-10">
        <v-row
            align="center"
            justify="center"
            class=" mb-5"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <v-card-text style="margin-bottom: -30px;" >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Affiliates ({{totalCount}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        style="float: left;"
                                        @click="dialogAssign = true"
                                    >
                                        Add Affiliate 
                                    </v-btn>
                      </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_jobs"
                            :options.sync="datatable_options"
                            :items="users"
                            loading-text="Loading... Please wait"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                            class="table"
                        >
                     
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="product = item, dialogAssign=true"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.ref_url="{ item }">
                           <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="copyURL(baseUrl+'ref/'+item.id)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              copy
                            </v-icon>
                          </v-btn> <span>{{baseUrl}}ref/{{item.id}}</span>
                           
                        </template>
                         
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="dialogDetail = true,selectedItem = item"
                            x-small
                            color="green"
                          >
                            {{item.total_count}}
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                       
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination mb-5"
                            circle
                            color="#4CAF50"
                                v-model="page"
                                :length="pageCount"
                                @input="getProducts"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
      
           <v-dialog
        persistent
      v-model="dialogAssign"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="product.id">
          Add new Affiliate
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Affiliate
        </v-card-title>
        <v-card-text style="margin-top:30px;"> 
          <v-text-field
          label="Name"
                v-model="product.name"
          ></v-text-field>
          <v-text-field
          label="URL"
                v-model="product.url"
          ></v-text-field>
              
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            v-if="product.id"
            :disabled="loadingAssign"
            @click="editPromo()"
          >
            Submit
          </v-btn>
          <v-btn
            color="primary"
            text
            v-else
            :disabled="loadingAssign"
            @click="addPromo()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssign = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogCustomLink"
      width="500"
    >

      <v-card>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
        <v-card-title class="text-h5 grey lighten-2" v-if="editFlag">
          Edit custom menu link 
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Create a custom menu link 
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <span style="color:#5D6167;">Link title</span>
          <v-text-field
                v-model="link.title"
                required
                :rules="nameRules"
                solo
          ></v-text-field>
          <span style="color:#5D6167;">Link icon</span>
          <v-dialog
            v-model="showDialog"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="700px"
            max-height="850px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="icon"
                :prepend-inner-icon="selectedIcon"
                :label="selectedIcon"
                v-bind="attrs"
                v-on="on"
                color="deep-purple"            
                readonly
                solo  
              >
              </v-text-field>
            </template>
            <v-card>
              <v-app-bar
                color="white"
                scroll-target="#scrolling-techniques-7"
                elevation="0"
              >
                <v-text-field
                  v-model="searchIcon"
                  placeholder="Search icon"
                  prepend-icon="mdi-magnify"
                  type="text"
                  onautocomplete="off"
                  dense
                />
              </v-app-bar>
              <v-sheet
                id="scrolling-techniques-7"
                class="overflow-y-auto"
                max-height="600"
              >
                <v-col cols="12">
                  <v-btn
                    v-for="(item, i) in allIcons"
                    :key="i"
                    @click="saveIcon(item)"
                    color="white"
                    class="mr-2 mb-2 "
                    fab
                    small
                    depressed
                  >
                    <v-icon color="grey darken-3"> mdi-{{ item }} </v-icon>
                  </v-btn>
                </v-col>
                
              </v-sheet>
            </v-card>
          </v-dialog>
          <span style="color:#5D6167;">URL</span>
          <v-text-field
                v-model="link.url"
                required
                :rules="nameRules"
                solo
          ></v-text-field>
          <span style="color:#5D6167;">Show link on sidebar</span>
          <v-switch
            v-model="link.sidebar"
            inset
          ></v-switch>
          <span style="color:#5D6167;">Show to all accounts</span>
          <v-switch
            v-model="link.all_account"
            inset
          ></v-switch>
          <v-select
          v-if="link.all_account == 0"
            v-model="link.list_account"
            :items="companies"
            chips
            item-text="name"
            item-value="id"
            label="Chips"
            multiple
            solo
          ></v-select>
          <v-radio-group
            v-model="link.is_iframe"
            row
          >
            <v-radio
              label="Open in iFrame"
              :value="1"
            ></v-radio>
            <v-radio
              label="Open in a New Tab"
              :value="0"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            v-if="!editFlag"
            :disabled="loadingCustomLink"
            @click="addLink()"
          >
            Submit
          </v-btn>
          <v-btn
            color="primary"
            text
            v-if="editFlag"
            :disabled="loadingCustomLink"
            @click="editLink()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingCustomLink"
            @click="dialogCustomLink = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    </v-container>
    <vue-footer-admin></vue-footer-admin>
</v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        id: '',
        name:'',
        phone:'',
        password : '',
        users : [],
        nameRules: [
        v => !!v || 'Field is required'
      ],
        valid: true,
        page:1,
        itemsPerPage:10,
        confpassword : '',
        role : '',
        headers_letter : [
           { text: 'Title', value: 'title',
            sortable: true },
          { text: 'Category', value: 'category',
            sortable: true },
          { text: 'Staus', value: 'status',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        datatable_options_letter: {},
        page_letter : 1,
        itemsPerPage_letter: 10,
        dialogAssign: false,
        pageCount: 1,
        pageLink:1,
        pageCountLink: 1,
        totalCount: 0,
        totalCountLink: 0,
        companyname : '',
        sector : '',
        baseUrl : process.env.VUE_APP_API_URL,
        no_of_employee : '',
        showPassword : false,
        loadingAssign: false,
        limit: 10,
        limit_letter: 10,
        totalCountLetter : 0,
        letters : [],
        dialogCustomLink: false,
        loadingCustomLink: false,
        phoneFlag:0,
        emailFlag:0,
        link: {
          title:'',
          icon: 'mdi-web',
          url: '',
          is_iframe: 1,
          sidebar:1,
          all_account: 1,
          list_account:[],
        },
        product: {
          id:'',
          name: '',
          url: ''
        },
        country :'',
        datatable_options:{},
        otp_email:'',
        otp_phone:'',
        links: [],
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        editFlag: false,
        companies: [],
        user_email:'',
        headers_jobs: [
          { text: 'Name', value: 'name',
            sortable: true },
          { text: 'URL', value: 'url',
            sortable: true },
         { text: 'Referral URL', value: 'ref_url',
            sortable: true },
            { text: 'Visits', value: 'visit_count',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: false,width: '50' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        headers_links: [
          { text: 'Title', value: 'title',
            sortable: false },
          { text: 'URL', value: 'url',
            sortable: false },
          { text: 'Open Mode', value: 'is_iframe',
            sortable: false },
          { text: 'Created at', value: 'created_at',
            sortable: false,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: false,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: false,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        status:0,
        settings :{
            test_mode : 1,
            pricing :{
                user_credit : "",
            }

        },
        tab: null,
        showDialog: false,
      icon: '',
      searchIcon: '',
      selectedIcon: 'mdi-check-outline',


      }
    },

    methods: {
    openAdd(){
      this.link = {
          title:'',
          icon: 'mdi-web',
          url: '',
          is_iframe: 1,
          sidebar:1,
          all_account: 1,
          list_account:[],
        };
      this.dialogCustomLink = true;
    },
    openEdit(item){
      
      this.editFlag = true;
      this.link = item;
      this.link.list_account = Array.isArray(this.link.list_account) ? this.link.list_account : JSON.parse(this.link.list_account)
      console.log(this.link);
      this.selectedIcon = item.icon;
      this.dialogCustomLink = true;
    },
    getSettings() {
    let flag = 1;
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
           
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.id = data.id;
              this.settings = data.settings;
            }


         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     getAllCompanies(){
            let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            axios
            .get("/api/get-all-company", {headers: headers})
            .then(response => {
                this.companies = response.data.data;
                this.companies.push({name: 'None',id:'None'})
            })
        },
    deleteItem(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-affiliate/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getProducts();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemLetter(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-letter/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getLetters();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemLink(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-link/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getLinks();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        alert('Copied');
      } catch($e) {
        alert('Cannot copy');
      }
    },
    addPromo(){
        
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        if(!pattern.test(this.product.url)){
          Vue.$toast.error('Invalid URl provided', {
                      position: 'top-right'
                      });
        }
        else{
          this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/create-affiliate",
          data: this.product
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Affiliate added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getProducts();
                      this.product.price = '';
                      this.product.credit = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
        }
          
            
      },
      editPromo(){
        
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        if(!pattern.test(this.product.url)){
          Vue.$toast.error('Invalid URl provided', {
                      position: 'top-right'
                      });
        }
        else{
          this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-affiliate/"+this.product.id,
          data: this.product
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Affiliate edit successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getProducts();
                      this.product.price = '';
                      this.product.credit = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
        }
          
            
      },
    getProducts(){
      this.loading = true;
            this.offset = (this.page - 1) * 10
            this.selected_project = name;
            let req = {
              limit : this.limit,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-affiliates",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getLinks(){
      this.loading = true;
            this.offset = (this.pageLink - 1) * 10
            this.selected_project = name;
            let req = {
              limit : this.limit,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-custom-links",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.links = data.data;
                this.pageCountLink = data.total_page
                this.totalCountLink = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getLetters(){
      this.loading = true;
            var offset = (this.page_letter - 1) * 10
            let req = {
              limit : this.limit_letter,
              offset : offset,
              sort : this.datatable_options_letter.sortBy,
              sortDesc : this.datatable_options_letter.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search_letter
            }
            axios({
          method: "POST",
           
          url: "/api/get-all-letter",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.letters = data.data;
                this.page_letter = data.total_page
                this.totalCountLetter = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    saveSettings: function(e) {

        axios({
          method: "POST",
           
          url: "/api/update-company-settings",
          data: {
            id: this.id,
            settings: this.settings
          }
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Settings saved', {
                    position: 'top-right'
                  })
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
    },
    saveIcon: function(icon) {
      this.icon = icon;
      this.selectedIcon = "mdi-" + icon;
      this.showDialog = false;
    }

 },
watch: {
    searchIcon: function(oldSearchIcon, newSearchIcon) {
    let filteredIcons = [];
    if (this.searchIcon.length === 0) {
      filteredIcons = this.allIcons;
    } else {
      filteredIcons = this.allIcons.filter(i => i.includes(this.searchIcon));
    }
    this.allIcons = filteredIcons;
  }
},
created() {
  if(!localStorage.getItem('token')){
    this.$router.push('/login')
    }else{
      console.log('created called.');
      this.getSettings();
      this.getProducts();
      this.getLinks()
      this.getAllCompanies();
      this.getLetters();
    }
      
    },

}
</script>

<style>
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>