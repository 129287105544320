<template>
<v-app id="inspire" style="background:#F9F9F9;">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6">
      <v-tabs
       class="mt-3"
       color="#4CAF50"
                  left
                >
                  <v-tab>Contract Templates</v-tab>
                  <v-tab>Letter Templates</v-tab>
                  <v-tab-item
                  style="background-color: transparent!important;"
                    :key="0"
                  >
        <v-row
            align="center"
            justify="center"
            class="mt-10 mb-10"
          >
            <v-col cols="12" md="12">
               <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;" >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Contract Templates({{countLetter}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          mr-5
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        style="float: left;"
                                        @click="addNew()"
                                    >
                                        Add New
                                    </v-btn>
                                    <v-btn
                                    color="green"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        @click="changeStatus(0)"
                                    >
                                        mass activate
                                    </v-btn>
                                    
                                    <v-btn
                                    color="red"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        @click="changeStatus(1)"
                                    >
                                        mass Inactivate
                                    </v-btn>
                      </v-col>
                    <v-col cols="12"
                      md="12">
                      
                      <v-data-table
                            :headers="headers_letter"
                            :options.sync="datatable_options_letter"
                            :items="letters"
                            loading-text="Loading... Please wait"
                            :page.sync="page_letter"
                            v-model="selectedItems"
                            show-select
                              :items-per-page="itemsPerPage_letter"
                            hide-default-footer
                            class="table"
                        >
                     
                        <template v-slot:item.client="{ item }">
                            {{item.client == 1 ? 'Yes' :'No'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.price="{ item }">
                            {{'$'+item.price}}
                        </template>
                        <template v-slot:item.title="{ item }" >
                           <span style="cursor:pointer;color:blue;" @click="openedit(item)"> {{item.title}} </span>
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="editFlag = true, letter = item, loadingAssign = true"
                            x-small
                            :disabled="item.company_id == '1'"
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              editaa
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.send="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="openDialogSendClient(item.id)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              send
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="dialogDetail = true,selectedItem = item"
                            x-small
                            color="green"
                          >
                            {{item.total_count}}
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                          <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            :dark="item.company_id != '1' && role.name != 'super_admin'"
                           @click="openedit(item)"
                            x-small
                            :disabled="item.company_id == '1' && role.name != 'super_admin'"
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItemLetter(item.id)"
                            x-small
                            color="red"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            circle
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            color="#4CAF50"
                                v-model="page_letter"
                                :length="pageCountLetter"
                                @input="getLetters"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-tab-item>
          <v-tab-item
                  style="background-color: transparent!important;"
                    :key="2"
                  >
                  <v-row
            align="center"
            justify="center"
            class="mt-10 mb-5"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;" >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Letters({{totalCountLetter}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        style="float: left;"
                                        @click="$router.push('add-letter')"
                                    >
                                        Create New Letter
                                    </v-btn>
                      </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_letter"
                            :options.sync="datatable_options_letter_template"
                            :items="letters_template"
                            loading-text="Loading... Please wait"
                            :page.sync="page_letter_template"
                            :items-per-page="itemsPerPage_letter"
                            hide-default-footer
                            class="table"
                        >
                     
                        <template v-slot:item.is_iframe="{ item }">
                            {{item.is_iframe == 1 ? 'Iframe' :'New Tab'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.send="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="openDialogSendClientLetter(item.id)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              send
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.price="{ item }">
                            {{'$'+item.price}}
                        </template>
                        <template v-slot:item.title="{ item }" >
                           <span style="cursor:pointer;color:blue;" @click="$router.push('edit-letter/'+item.id)"> {{item.title}} </span>
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                            :disabled="item.company_id == '1'"
                           @click="openEdit(item)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="dialogDetail = true,selectedItem = item"
                            x-small
                            color="green"
                          >
                            {{item.total_count}}
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                          <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            
                            :dark="item.company_id != '1' && role.name != 'super_admin'"
                            :disabled="item.company_id == '1' && role.name != 'super_admin'"
                           @click="$router.push('edit-letter/'+item.id)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItemLetterTemplate(item.id)"
                            x-small
                            color="red"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            circle
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            color="#4CAF50"
                                v-model="page_letter_template"
                                :length="pageCountLetterTemplate"
                                @input="getLettersTemplate"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-tab-item>
          </v-tabs>
          <v-dialog
        persistent
      v-model="dialogAssign"
      width="1000"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ editFlag ? 'Edit Template' : 'Add new Template'}}
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 


          <v-row>
            <!-- <v-col
                      cols="12"
                      md="6"
                    >
                    <v-select
                      :items="categories"
                      label="Category *"
                      item-text="category"
                      item-value="id"
                      v-model="letter.category"
                    ></v-select>
                    <p style="color:#0075cc !important;cursor:pointer;padding-top:-10px;" @click="letter_category_dialog = true"><b>Manage template category</b></p>
                    </v-col> -->
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <v-select
                      :items="['Active','Inactive']"
                      label="Status *"
                      v-model="letter.status"
                    ></v-select>

                    </v-col>
                    <!-- <v-col
                      cols="12"
                      md="6"
                    >
                    <v-switch
                    label="Show on client profile"
                      v-model="letter.client"
                      inset
                    ></v-switch>
                  
                  </v-col> -->



                  <v-col
                      cols="12"
                      v-if="role.name =='super_admin'"
                      md="12"
                    >

                    <v-select
                      label="Share with"
                        v-model="letter.display_to"
                        :items="['All','Specific Company']"
                        inset
                      ></v-select>
                      <v-select
                      v-if="letter.display_to == 'Specific Company'"
                        v-model="letter.list_account"
                        :items="companies"
                        chips
                        item-text="name"
                        item-value="id"
                        label="Select Company"
                        multiple
                        solo
                      >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    
                    
                    </v-select>

                    </v-col>
                     <v-col
                      cols="12"
                      md="9"
                    >
                    <v-text-field
                        label="Contract name *"
                        v-model="letter.title"
                      ></v-text-field>
                     </v-col>
                      <v-col
                      cols="12"
                      md="3"
                    >
                      <v-btn
                      outlined
                      style="float: right;"
                        height="55px"
                        color="success"
                        class="justify-center"
                        @click="showPlaceholder = !showPlaceholder"
                      >View Placeholders</v-btn>
                    </v-col>
                      <div class="col-12" v-if="showPlaceholder">
                        <div class="content-box m-b-0" style="border: solid 1px #dddbda;
padding: 20px !important;">
                            <div class="row">
                                <div class="col-4">
                                    <p class="sans-p font-15 m-b-0">
                                    {company_logo} - <span class="font-400">Company logo</span><br>
                                        {client_suffix} - <span class="font-400">Suffix of client</span><br>
                                        {client_first_name} - <span class="font-400">First name of client</span><br>
                                        {client_middle_name} - <span class="font-400">Middle name of client</span><br>
                                        {client_last_name} - <span class="font-400">Last name of client</span><br>
                                        {client_address} - <span class="font-400">Address of client</span><br>
                                        {client_previous_address} - <span class="font-400">Previous address of client</span><br>
                                        {bdate} - <span class="font-400">Birth date of client</span><br>
                                        {client_ip} - <span class="font-400">Client IP Address</span></br>
                                        {client_sign_address} - <span class="font-400">Client Sign Address</span></br>
                                        {client_sign_operation system} - <span class="font-400">Client Sign Operating System</span>
                                    </p>
                                </div>
                                <div class="col-4">
                                    <p class="sans-p font-15 m-b-0">
                                    {ss_number} - <span class="font-400">SSN of client</span><br>
                                        {t_no} - <span class="font-400">Telephone number of client</span><br>
                                        {curr_date} - <span class="font-400">Current date</span><br>
                                        {client_signature} - <span class="font-400">Client's signature</span><br>
                                        {bureau_name} - <span class="font-400">Credit bureau name</span><br>
                                        {bureau_address} - <span class="font-400">Credit bureau name and address</span><br>
                                        {account_number} - <span class="font-400">Account number</span><br>
                                        {dispute_item_and_explanation} - <span class="font-400">Dispute items and explanation</span>
                                    </p>
                                </div>
                                <div class="col-4">
                                    <p class="sans-p font-15 m-b-0">
                                        {creditor_name} - <span class="font-400">Creditor/Furnisher name</span><br>
                                        {creditor_address} - <span class="font-400">Creditor/Furnisher address</span><br>
                                        {creditor_phone} - <span class="font-400">Creditor/Furnisher phone number</span><br>
                                        {creditor_city} - <span class="font-400">Creditor/Furnisher city</span><br>
                                        {creditor_state} - <span class="font-400">Creditor/Furnisher state</span><br>
                                        {creditor_zip} - <span class="font-400">Creditor/Furnisher zip</span><br>
                                        {report_number} - <span class="font-400">Report number</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                     <v-col
                      cols="12"
                      md="12"
                      class="mb-10"
                    >
                    
                    <!-- <editor v-model="letter.body" api-key="gy0tehv0d7n934gbood5c6jyemezfrywnh9b6p5keila4odp" :init="{plugins: 'wordcount', height: 500,}" /> -->
                    <html-editor style="height:300px;" v-model="letter.body"></html-editor>
                    
                    </v-col>
          </v-row>
             
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            v-if="!editFlag"
            text
            :disabled="loadingAssign"
            @click="saveLetter()"
          >
            Save
          </v-btn>
          <v-btn
            color="primary"
            v-else
            text
            :disabled="editFlag && letter.company_id == '1' && role.name != 'super_admin'"
            @click="editLetter()"
          >
            Save
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssign = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
          v-model="letter_category_dialog"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Manage Template Category
            </v-card-title>
            <div  class="mt-5">
              <v-card-text >
               <v-btn
                        color="blue"
                        dark
                        v-if="!addCategoryFlag"
                        @click="addCategoryFlag = true"
                      >Add new category</v-btn>
                       <v-text-field
                       v-if="addCategoryFlag"
                        label="Category Name *"
                        class="mt-5"
                        v-model="category_name"
                      ></v-text-field>
                       <v-btn
                        v-if="addCategoryFlag"
                        class="mr-5"
                        color="blue"
                        dark
                        @click="saveCategory"
                      >Save</v-btn>
                       <v-btn
                       v-if="addCategoryFlag"
                      outlined
                        color="red"
                        @click="addCategoryFlag = false"
                      >Cancel</v-btn>
              </v-card-text>
            </div>
            <div  class=" mb-5">
             
             <v-card-text >
               
              <v-simple-table class="table">
                  <template v-slot:default>
                    <thead style="background:#e0e0e0">
                      <tr>
                        <th class="text-left">
                          Categories
                        </th>
                        <th class="text-left">
                          
                        </th>
                        <th class="text-left">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in categories"
                        :key="item.category"
                      >
                        <td>{{ item.category }}</td>
                        <td>

                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="categoryEditId = item.id,addCategoryFlag = true, category_name = item.category"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </td>
                        <td class="text-right"> 
                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="letter_category_dialog = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
    <v-dialog
        persistent
      v-model="dialogDetail"
      width="700"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Credentials
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <h3 style="color:#5D6167;">API User: <span style="color:green;">{{selectedItem.api_user}}</span></h3>
        </v-card-text>
         <v-card-text style="margin-top:30px;"> 
          <h3 style="color:#5D6167;">API Password: <span style="color:green;">{{selectedItem.api_password}}</span></h3>
        </v-card-text>
         <v-card-text style="margin-top:30px;"> 
          <h3 style="color:#5D6167;">API Signature: <span style="color:green;">{{selectedItem.api_signature}}</span></h3>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
         
           <v-btn
            color="primary"
            text
            @click="dialogDetail = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogSendClient"
      width="700"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Send Contract to client
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-select
                      label="Share with"
                        v-model="send_to"
                        :items="['None','All','Specific Client']"
                        inset
                      ></v-select>
                      <v-select
                      v-if="send_to == 'Specific Client'"
                        v-model="selcted_clients"
                        :items="list_client"
                        chips
                        item-text="name"
                        item-value="user_id"
                        label="Select Client"
                        multiple
                        solo
                      ><template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field v-model="searchTermClient" placeholder="Search" @input="searchClient"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template></v-select>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
         
           <v-btn
            color="primary"
            text
            @click="dialogSendClient = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="SendContractClient()"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        persistent
      v-model="dialogSendClientLetter"
      width="700"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Send Letter to client
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-select
                      label="Share with"
                        v-model="send_to_letter"
                        :items="['None','All','Specific Client']"
                        inset
                      ></v-select>
                      <v-select
                      v-if="send_to_letter == 'Specific Client'"
                        v-model="selcted_clients_letter"
                        :items="list_client"
                        chips
                        item-text="name"
                        item-value="user_id"
                        label="Select Client"
                        multiple
                        solo
                      >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field v-model="searchTermClient" placeholder="Search" @input="searchClient"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-select>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
         
           <v-btn
            color="primary"
            text
            @click="dialogSendClientLetter = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="SendContractClientLetter()"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
    <vue-footer-admin></vue-footer-admin>
</v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import HtmlEditor from "../HtmlEditor.vue";
export default {
components: {
      VueTelInput,HtmlEditor
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        id: '',
        name:'',
        countLetter : 0,
        editFlag : false,
        phone:'',
        list_client : [],
        searchTerm : '',
        send_to : 'None',
        send_to_letter :'None',
        selectedLetterId: '',
        dialogSendClientLetter: false,
        selcted_clients_letter: [],
        companiesCopy :[],
        letters_template: [],
        role : JSON.parse(localStorage.getItem('role')),
        datatable_options:{},
        password : '',
        confpassword : '',
        selectedItems : [],
        companies : [],
        companyname : '',
        list_clientCopy : [],
        searchTermClient: '',
        showPlaceholder : false,
        pageCountLetterTemplate: 0,
        totalCount : 0,
        letter_category_dialog: false,
        letter : {
          category: "",
          status : "",
          title: "",
          body : "",
          client : true,
          display_to : 'All',
          list_account : []

        },
        datatable_options_letter: {},
        page_letter : 1,
        page_letter_template: 1,
        itemsPerPage_letter: 10,
        pageCountLetter : 1,
        limit_letter: 10,
        pageCount : 1,
        dialogSendClient: false,
        selectedItem: '',
        letters : [],
        headers_letter : [
           { text: 'Title', value: 'title',
            sortable: true },
          // { text: 'Show on client', value: 'client',
          //   sortable: true },
          { text: 'Staus', value: 'status',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: true,width: '200' },
            { text: 'Send to client', value: 'send',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        dialogDetail : false,
        promo: {
          name : '',
          type : 'Paypal',
          credentials : {
            api_user :  "",
            api_password : "",
            api_signature : ""
          },
        },
        sector : '',
        limit: 10,
        loadingAssign : false,
        totalCountLetter : 0,
        datatable_options_letter_template : {},
        no_of_employee : '',
        dialogAssign: false,
        showPassword : false,
        phoneFlag:0,
        emailFlag:0,
        country :'',
        selcted_clients: [],
        users : [],
        page:1,
        itemsPerPage:10,
        otp_email:'',
        otp_phone:'',
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,
        headers_jobs: [
          { text: 'Name', value: 'name',
            sortable: true },
          { text: 'Type', value: 'type',
            sortable: true },
        
           
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            
             { text: 'Credentials', value: 'password',
            sortable: false,width: '150' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        settings :{
            day1: 30,
            day2: 21,
            day2_price: 1500,
            pricing :{
                family_plan_price_monthly : "",
                family_plan_price_yearly : "",
                individual_plan_price_monthly : "",
                individual_plan_price_yearly : "",
                individual_plus_plan_price_monthly : "",
                individual_plus_plan_price_yearly : "",
            }

        },
        tab: null,


      }
    },

    methods: {
      getLetters(){
      this.loading = true;
            var offset = (this.page_letter - 1) * 10
            let req = {
              limit : this.limit_letter,
              offset : offset,
              sort : this.datatable_options_letter.sortBy,
              sortDesc : this.datatable_options_letter.sortDesc && this.datatable_options_letter.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search_letter
            }
            axios({
          method: "POST",
           
          url: "/api/get-all-contract-template",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.letters = data.data;
                this.pageCountLetter = data.total_page
                this.countLetter = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    changeStatus(value){
            this.loading = true
            let multiple_flag = 1
              var r = confirm("Are you sure, You went to change status?");
              if(this.selectedItems.length == 0){
                Vue.$toast.error("Select atleast one item", {
                  position: 'top-right'
                  });
                  r = false
                  this.loading = false
              }

            if (r == true) {
                    axios
                .post("/api/change-status-contract/"+value,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Contract status changed successfully', {
                            position: 'top-right'
                            });
                            this.getLetters();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
              this.loading = false
                return false;
            }
        },
        addNew(){
          this.letter = {
          category: "",
          status : "",
          title: "",
          body : "",
          client : true,
          display_to : 'All',
          list_account : []

        };
          this.dialogAssign = true
        },
      addPromo(){
              this.loadingAssign = true;
              axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-payment-gateway",
          data: this.promo
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Payment gateway added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getUsers();
                      this.promo.name = '';
                      this.promo.value = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error(err.message, {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      deleteItemLetter(id){
       
       var r = confirm("Are you sure, You went to delete?");
       if (r == true) {
         axios
           .post("/api/delete-contract-template/"+id,this.header)
           .then(response => {
               let data = response.data;

           if (response.data) {
               Vue.$toast.success('Deleted successfully', {
                   position: 'top-right'
                   });
                 this.getLetters();
               }else {
                   Vue.$toast.error(data.message, {
                   position: 'top-right'
                   });
               }

           })
         .catch(err => {
           Vue.$toast.error(err, {
           position: 'top-right'
           });
         })
       }
     },
     deleteItemLetterTemplate(id){
       
      var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-letter/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getLettersTemplate();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
     },
     openDialogSendClient(id){
      this.selectedContractId = id
      axios
              .get("/api/get-user-template-map/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                if(data.data.length == this.list_client.length)
                  this.send_to = 'All'
                else if(data.data.length  == 0)
                  this.send_to = 'None'
              else if(data.data.length > 0)
                  this.send_to = 'Specific Client'
                this.selcted_clients = []
                data.data.forEach(val => {
                  this.selcted_clients.push(val.user_id)
                })
                //this.selcted_clients = data.data
                this.dialogSendClient = true
              }

              })
      
     },
     openDialogSendClientLetter(id){
      this.selectedLetterId = id
      axios
              .get("/api/get-user-letter-map/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                if(data.data.length == this.list_client.length)
                  this.send_to_letter = 'All'
                else if(data.data.length  == 0)
                  this.send_to_letter = 'None'
              else if(data.data.length > 0)
                  this.send_to_letter = 'Specific Client'
                this.selcted_clients_letter = []
                data.data.forEach(val => {
                  this.selcted_clients_letter.push(val.client_id)
                })
                //this.selcted_clients = data.data
                this.dialogSendClientLetter = true
              }

              })
      
     },
     SendContractClient(){
      this.dialogSendClient = true
      axios({
          method: "POST",
           
          url: "/api/send-template-map",
          data: {
            contract_id : this.selectedContractId,
            send_to : this.send_to,
            list_client : this.selcted_clients,
            all_client : this.list_client
          },
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
              .then(response => {
                  let data = response.data;

              if (response.data) {
                Vue.$toast.success('Send successfully', {
                   position: 'top-right'
                   });
                this.dialogSendClient = false
              }

              })
      
     },
     searchFruits(e) {
      if (!this.searchTerm) {
        this.companies = this.companiesCopy;
      }
      this.companies = this.companiesCopy.filter((fruit) => {
        return fruit.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
      });
    },
    searchClient(e) {
      if (!this.searchTermClient) {
        this.list_client = this.list_clientCopy;
      }
      this.list_client = this.list_clientCopy.filter((fruit) => {
        return fruit.name.toLowerCase().indexOf(this.searchTermClient.toLowerCase()) > -1;
      });
    },
     SendContractClientLetter(){
      this.dialogSendClientLetter = true
      axios({
          method: "POST",
           
          url: "/api/send-template-letter-map",
          data: {
            contract_id : this.selectedLetterId,
            send_to : this.send_to_letter,
            list_client : this.selcted_clients_letter,
            all_client : this.list_client
          },
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
              .then(response => {
                  let data = response.data;

              if (response.data) {
                Vue.$toast.success('Send successfully', {
                   position: 'top-right'
                   });
                this.dialogSendClientLetter = false
              }

              })
      
     },
     openedit(item){
      this. editFlag = true
      this.letter.id = item.id
      this.letter.title = item.title
      this.letter.company_id = item.company_id
      this.letter.display_to = item.display_to
      this.letter.list_account = JSON.parse(item.list_account)
      this.letter.body = item.letter
      this.letter.status = item.status == 1 ? 'Active' : 'Inactive'
      this.letter.client = item.client == 1 ? true : false
      this.dialogAssign = true
     },
      deleteItem(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-payment-gateway/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getUsers();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        getLettersTemplate(){
      this.loading = true;
            var offset = (this.page_letter - 1) * 10
            let req = {
              limit : this.limit_letter,
              offset : offset,
              sort : this.datatable_options_letter_template.sortBy,
              sortDesc : this.datatable_options_letter_template.sortDesc && this.datatable_options_letter_template.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search_letter
            }
            axios({
          method: "POST",
           
          url: "/api/get-all-letter",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.letters_template = data.data;
                this.pageCountLetterTemplate = data.total_page
                this.totalCountLetter = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
        getAllCompanies(){
            let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            axios
            .get("/api/get-all-company", {headers: headers})
            .then(response => {
                this.companies = response.data.data;
                this.companies.push({name: 'None',id:'None'})
                this.companiesCopy = [...this.companies]
            })
        },
    getUsers(){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            this.selected_project = name;
            let req = {
              limit : this.limit,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-payment-gateway",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },

        saveLetter: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.letter.status && flag == 1) {
            Vue.$toast.error('Status email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.letter.title && flag == 1) {
            Vue.$toast.error('Title phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        

        if (flag == 1) {
          let req = self.letter;
          req.company_id = localStorage.getItem('company_id') != '' && localStorage.getItem('company_id') != null && localStorage.getItem('company_id') != 'null' ? localStorage.getItem('company_id') : 1
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/save-contract-template",
          data: req
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Template added', {
                    position: 'top-right'
                  })
                  self.dialogAssign = false
                  self.getLetters()
            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },
    getAllClints() {
    let flag = 1;
    let url = process.env.VUE_APP_WASBI_URL
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-all-client",header)
        .then(response => {
            let data = response.data.data;
            this.list_client = data
            this.list_clientCopy = [...this.list_client]
         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
    editLetter: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.letter.status && flag == 1) {
            Vue.$toast.error('Status email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.letter.title && flag == 1) {
            Vue.$toast.error('Title phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        

        if (flag == 1) {
          let req = self.letter;
          req.company_id = localStorage.getItem('company_id') != '' && localStorage.getItem('company_id') != null && localStorage.getItem('company_id') != 'null' ? localStorage.getItem('company_id') : 1
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-contract-template/"+this.letter.id,
          data: req
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Template added', {
                    position: 'top-right'
                  })
                  self.dialogAssign = false
                  self.getLetters()
            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },


    saveSettings: function(e) {

        axios({
          method: "POST",
           
          url: "/api/update-company-settings",
          data: {
            id: this.id,
            settings: this.settings
          }
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Settings saved', {
                    position: 'top-right'
                  })
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
    },

 },
watch: {
  datatable_options_letter : {
    handler () {
                this.getLetters()
                },
                deep: true,
  },
  datatable_options_letter_template : {
    handler () {
                this.getLettersTemplate()
                },
                deep: true,
  },
},
created() {
  if(!localStorage.getItem('token')){
    this.$router.push('/login')
    }else{
      console.log('created called.');
      this.getLetters();
      this.getLettersTemplate();
      this.getAllCompanies()
      this.getAllClints()
    }
      
    },

}
</script>

