<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
  <v-main >
    <v-container style="margin-bottom: 72px;" fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl mt-6" id="basic">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">Manage Subscription</h5>
    </div>
    <div class="px-6 pb-6 pt-0">
                        <v-card-text>
                            <v-row class="mt-3 pl-5" style="">
                                <v-col
                                cols="12"
                                md="12"
                                >
                                <div class="account-pricing"><span class="account-pricing__heading">Subscription plan</span> <br> <span class="account-pricing__description text-right">Current Subscription</span> 
                                 <span class="account-pricing__plan" style="color:green;margin-top:-10px;padding-left:15px;padding-right:15px;" >
                                    <b>{{current_plan.name}} </b>
                                 </span>
                                <em class="account-pricing__plan mr-5">(Active)</em>  <strong>Next Payment Date:</strong> {{subscription.next_payment_date}}</div>
                                </v-col>
                                <v-col   sm="4" md="4" lg="4" xs="4" v-for="(price,index) in settings" :key="price.id">
                                <v-card
                                
                                    class="mx-auto"
                                    max-width="448"
                                    :style="current_plan.name == price.name ? 'border-radius: 10px;margin-bottom:50px;background-color:#F6F6F6' : 'border-radius: 10px;margin-bottom:50px;'"
                                    outlined
                                    >
                                    <v-list-item three-line style="padding-left: 40px;">
                                        <v-list-item-content>
                                        <v-list-item-title class=" card-header-1 mt-5">
                                        </v-list-item-title>
                                        </v-list-item-content>

                                        <v-chip
                                        v-show="current_plan.name == price.name"
                                        color="white"
                                        style="color:#178793;padding: 5px 28px 5px 28px;margin: -3px !important;"
                                        >
                                        CURRENT PLAN
                                        </v-chip>
                                    </v-list-item>
                                    <v-list-item three-line style="padding-left: 40px;margin-top: -60px;">
                                        <v-list-item-content>
                                        <v-list-item-title class=" card-header-11 mt-5">
                                            {{price.name}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-for="item in price.items" style="color: #505050;
                                            font-size: 14px;
                                            font-weight: 500;
                                            line-height: 140%;
                                            margin-top: 8px;"> <v-icon color="green" >fa-check</v-icon> {{item.item}}</v-list-item-subtitle>
                                        <v-list-item-title class=" mt-5" v-show="current_plan.name == price.name"><strong>Additional Team Members:</strong> {{subscription.ad_ons_team}} </v-list-item-title>
                                       <v-list-item-title class=" mt-5" v-show="current_plan.name == price.name"><strong>Additional Client Slots:</strong> {{subscription.ad_ons_client}} </v-list-item-title>
                                        </v-list-item-content>

                                        
                                    </v-list-item>
                                   
                                    <v-divider></v-divider>
                                    <v-card-text  style="">
                                        
                                        <div class="my-4 text-subtitle-1" style="font-size: 16px !important;margin-right: 15px;">
                                       <v-btn
                                        class="ma-2"
                                        Block 
                                        dark
                                        @click="upgrade('upgrade',price,'off',index)"
                                        v-if="recurring_type == 'Monthly' && current_plan.name == price.name"
                                        height="55"
                                        width="100%"
                                        color="#f06b66"
                                        >
                                        <span style="text-transform: none;">Monthly</span><v-spacer></v-spacer><span style="text-transform: none;">${{price.monthly}} /mo</span>
                                        </v-btn>
                                        <v-btn
                                        class="ma-2"
                                        outlined
                                        Block 
                                        @click="upgrade('downgrade',price,'off',index)"
                                        v-else
                                        height="55"
                                        width="100%"
                                        color="#f06b66"
                                        >
                                        <span style="text-transform: none;">Monthly</span><v-spacer></v-spacer><span style="text-transform: none;">${{price.monthly}} /mo</span>
                                        </v-btn>
                                        <v-btn
                                        class="ma-2"
                                        Block 
                                        dark
                                        v-if="recurring_type=='Yearly' && current_plan.name == price.name"
                                        height="55"
                                        width="100%"
                                        color="#f06b66"
                                        >
                                        <span style="text-transform: none;">Annually</span><v-spacer></v-spacer><span style="text-transform: none;">${{price.yearly}}  /mo</span>
                                        </v-btn>
                                        <v-btn
                                        class="ma-2"
                                        outlined
                                        Block 
                                         @click="upgrade('upgrade',price,'on',index)"
                                        v-else
                                        height="55"
                                        width="100%"
                                        color="#f06b66"
                                        >
                                        <span style="text-transform: none;">Annually</span><v-spacer></v-spacer><span style="text-transform: none;">${{price.yearly}}  /mo</span>
                                        </v-btn>
                                        </div>
                                        
                                    </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
      <!-- <v-row>
        <v-btn
          color="#4CAF50"
          large
           @click="saveProfile"
                        :loading="loading"
          class="
            font-weight-bolder
            btn-default
            mb-5
            px-8
            ms-auto
          "
          
        >
          Save
        </v-btn>
      </v-row> -->
      <v-dialog
                v-model="dialog2"
                persistent
                max-width="800px"
                >
                <v-card>
                  <v-card-title>
                    <span class="text-h5" style="color: #505050;"><strong v-if="is_downgrade == 1">Confirm Change Plan</strong><strong v-if="is_downgrade == 0">Confirm Change Plan</strong></span><v-spacer></v-spacer><v-icon color="#f06b66" @click="dialog2 = false">mdi-close</v-icon>
                    </v-card-title>
                    <div v-show="!paypal_flag">

              
                    <v-card-title>
                    <h3 style="color:#f06b66;">Total: ${{upgrade_data.amount}}</h3>
                    </v-card-title>
                     <v-card-title>
                    <h4> Add-ons</h4>
                    </v-card-title>
                    <v-card-title>
                     <span class="mr-5 text-subtitle-1"> ${{selected_plan.ad_ons_team_price}}/mo </span> <span  class="mr-5 text-subtitle-1"> Additional Team Members </span>
                    <v-text-field max-width="100px;"  v-model="add_on_team"  append-outer-icon="fa-plus" @click:append-outer="addon('add','team')" prepend-icon="fa-minus" @click:prepend="addon('remove','team')"></v-text-field>
                    </v-card-title>
                     <v-card-title>
                     <span class="mr-5 text-subtitle-1"> ${{selected_plan.ad_ons_client_price}}/mo </span> <span class="mr-5 text-subtitle-1"> Additional Client Slots </span>
                    <v-text-field v-model="add_on_client"  append-outer-icon="fa-plus" @click:append-outer="addon('add','client')" prepend-icon="fa-minus" @click:prepend="addon('remove','client')"></v-text-field>
                    </v-card-title>
                     <v-card-title>
                    <div data-v-3733a2fd="" class="my-4 text-subtitle-1" style="font-size: 16px !important;">You're <span v-if="is_downgrade == 1">downgrading</span><span v-if="is_downgrade == 0">upgrading</span> to the {{upgrade_data.plan}} {{upgrade_data.type}} plan at ${{upgrade_data.amount}}. We'll now change your current plan and charge you ${{upgrade_data.amount}} as the difference between your current plan and the new one. 
                    </div>
                    
                    </v-card-title>

                    <v-card-text >
                    <v-container>
                        <v-row>
                        <v-col
                            cols="6"
                            
                            sm="6"
                            style="padding: 0;"
                            md="6"
                        >
                        <v-text-field color="#f06b66" prepend-icon="mdi-credit-card" v-mask="'####-####-####-####'" v-model="newCard.number" label="Card Number"></v-text-field>
                        </v-col>
                        <v-col
                            cols="3"
                            sm="3"
                            style="padding: 0;"
                            md="3"
                        >
                        <v-text-field color="#f06b66" v-mask="'##/##'" v-model="newCard.exp" label="MM/YY"></v-text-field>
                        </v-col>
                        <v-col
                            cols="3"
                            sm="3"
                            style="padding: 0;"
                            md="3"
                        >
                        <v-text-field color="#f06b66" v-mask="'####'" v-model="newCard.cvv" label="CVC"></v-text-field>
                        </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        width="100"
                        class="mb-5"
                        style="text-transform: none;"
                        @click="dialog2 = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                      dark
                      class="mb-5"
                      width="100"
                      :loading="upgradeButtonDisable"
                      style="text-transform: none;"
                        color="#4CAF50"
                        @click="upgradePlan()"
                    >
                       Change Plan
                    </v-btn>
                    </v-card-actions>
                    </div>
                    <div v-show="paypal_flag">
                      <div class="mx-auto" style="width: 300px;" ref="paypal" v-if="payment_gateway =='Paypal'"></div>
                    </div>
                </v-card>
                </v-dialog>
    </div>
  </v-card>

    </v-container>

    <vue-footer-admin></vue-footer-admin>
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import moment from 'moment';
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        name:'',
        is_downgrade : false,
        upgrade_data:[],
        add_on_client : 0,
        add_on_team : 0,
        selected_plan : '',
        phone:'',
        password : '',
        is_admin:0,
        confpassword : '',
        role : '',
        credentials: '',
        payment_gateway: '',
        current_plan : [],
        user: [],
        paypal_flag : false,
        settings : [],
        companyname : '',
        sector : '',
        isDark: true,
        newCard: {
        number: '',
        exp: '',
        cvv: ''
        },
        recurring_type : '',
        dialog2: false,
        loading:false,
        isDisable :false,
        no_of_employee : '',
        showPassword : false,
        file_flag : {
          agreement : {
                          name : '',
                          id :''
                        },
          id :{
                          name : '',
                          id :''
                        },
          bill :{
                          name : '',
                          id :''
                        },
          letter_a : {
                          name : '',
                          id :''
                        },
          letter_b : {
                          name : '',
                          id :''
                        },
        },
        phoneFlag:0,
        emailFlag:0,
        files : {
          agreement : [],
          id : [],
          bill:[],
          letter_a : [],
          letter_b : []
        },
        country :'',
        upgradeButtonDisable : false,
        otp_email:'',
        subscription: '',
        plan_id : '',
        otp_phone:'',
        company : {
          name: "",
          contact_email: "",
          contact_phone: "",
          details:"",
          email: "",
          password: "",
          password_confirmation: ""

        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    disableinput($event){
       $event.preventDefault();
    },
    downloadFile(key){
      let self = this;
            this.isDisableDownload = true;
            this.isDarkDownload = false;
                axios({
                    method: "POST",
                    url: "/api/download-file",
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                    data: {key : key}
                    })
                    .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   var fileLink = document.createElement('a');

                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', response.headers['file-name']);
                   document.body.appendChild(fileLink);

                   fileLink.click();
                    
                    self.isDisableDownload = false;
                    self.isDarkDownload = true;
                    })
                    .catch(err => {
                        Vue.$toast.error(err, {
                        position: 'top-right'
                        });
                    });
    },
    getCompanySettings(){
     
    },
    getAmount(){
      let self = this;
      let amount = self.upgrade_data.amount;
      let recurring_type = self.upgrade_data.type;
      
      if(self.selected_plan_id == 'plan1'){
            self.plan_id = recurring_type == 'Monthly' ? self.credentials.plan1_m : self.credentials.plan1_y
      }else if(self.selected_plan_id == 'plan2'){
       self.plan_id = recurring_type == 'Monthly' ? self.credentials.plan2_m : self.credentials.plan2_y
        
      }else if(self.selected_plan_id == 'plan3'){
       self.plan_id = recurring_type == 'Monthly' ? self.credentials.plan3_m : self.credentials.plan3_y
        
      }else if(self.selected_plan_id == 'plan4'){
       self.plan_id = recurring_type == 'Monthly' ? self.credentials.plan4_m : self.credentials.plan4_y
        
      }
      self.amount = amount;
      self.recurring_type = recurring_type;
    },
    setLoaded: function(resp) {
      
      let self = this;
      this.loaded = true;
        window.paypal.Buttons({
            
          style: {

          layout: 'vertical',

          color:  'blue',

          shape:  'pill',

          label:  'paypal'

          },
            createSubscription: function(data, actions) {

              return actions.subscription.create({

                'plan_id': self.plan_id // Creates the subscription

              });

            },

            onApprove: function(data, actions) {
              let req = {
                  card_number: 'XXXX',
                  sub_id : data.subscriptionID,
                  selected_product: self.selected_plan_id,
                  add_on_team : self.add_on_team,
                  add_on_client : self.add_on_client,
                  recurring_type: self.upgrade_data.type,
                  amount: self.upgrade_data.amount,
                  start_date: moment(moment(), "YYYY-MM-DD").format('YYYY-MM-DD') ,
                  next_date: self.upgrade_data.type == 'Yearly' ? moment(moment(), "YYYY-MM-DD").add(1, 'years').format('YYYY-MM-DD') : moment(moment(), "YYYY-MM-DD").add(1, 'months').format('YYYY-MM-DD')
                };

            axios({
                method: "POST",

                headers : {
                            Authorization:"Bearer " + localStorage.getItem('token')
                    },
                  url: "/api/update-subscription-paypal",
                  data: req
                })
                .then(response => {
                  if (response.data.flag == 1) {
                    let result = response.data.data;
                   location.roload();
                    self.$router.push('/admin/manage-subscription')
                  }else {
                      Vue.$toast.error('Unable to complete your payment at this momemnt. Please contact support team', {
                      position: 'top-right'
                      });
                  }
                  });
              

            }

          }).render(this.$refs.paypal); // Renders the PayPal button
      
    },
    upgradePlan(){
      let self = this;
      axios({
          method: "POST",
           
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.credentials = JSON.parse(data.credentials.credentials);
              //this.getAmount()
              this.payment_gateway = data.credentials.type;
              if(this.payment_gateway == 'Paypal'){
                self.upgradeButtonDisable = true;
                let req = {
                  plan: this.selected_plan_id,
                  add_on_team : self.add_on_team,
                  add_on_client : self.add_on_client,
                  recurring_type: self.upgrade_data.type,
                  amount: self.upgrade_data.amount,
                  start_date: moment(moment(), "YYYY-MM-DD").format('YYYY-MM-DD') ,
                  next_date: self.upgrade_data.type == 'Yearly' ? moment(moment(), "YYYY-MM-DD").add(1, 'years').format('YYYY-MM-DD') : moment(moment(), "YYYY-MM-DD").add(1, 'months').format('YYYY-MM-DD')
                };
                axios({
                  method: "POST",
                  data : req,
                  url: "/api/create-subscription-plan",
                  headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                  self.plan_id = response.data.plan_id;
                  self.upgradeButtonDisable = false;
                  self.paypal_flag = true;
                  const script = document.createElement("script");
                  script.src =
                    "https://www.paypal.com/sdk/js?client-id="+self.credentials.api_user+"&vault=true";
                  script.addEventListener("load", this.setLoaded);
                  document.body.appendChild(script);
                })
                  
              }else{
      
                let flag = 1;
                let exp = '';
                var dt = new Date();
                let year =  dt.getFullYear().toString().substr(-2) ; 
                let month =  ("0" + (dt.getMonth() + 1)).slice(-2); 
                if(!self.newCard.number ){
                    Vue.$toast.error('Card number is required', {
                            position: 'top-right'
                        });
                    flag = 0;
                }
                else if(self.newCard.number && self.newCard.number.length != 19 && flag == 1 ){
                      Vue.$toast.error('Invalid card number', {
                            position: 'top-right'
                        });
                        flag = 0;
                }
                else if(!self.newCard.exp && flag == 1){
                  Vue.$toast.error('Card expiry is required', {
                            position: 'top-right'
                        });
                    flag = 0;
                }
                if(self.newCard.exp && flag == 1 ){
                  exp = self.newCard.exp.split('/');
                  if(exp[0] < month || exp[1] < year){
                  Vue.$toast.error('Card expiry should be greater than current date', {
                            position: 'top-right'
                        });
                    flag = 0;
                  }
                }
                if(!self.newCard.cvv && flag == 1 ){
                    Vue.$toast.error('CVC is required', {
                            position: 'top-right'
                        });
                    flag = 0;
                }
                if(flag == 1){
                  self.upgradeButtonDisable = true;
                  self.upgradeButtonDark = false;
                  let req = {
                    new_card : self.changecardFlag ? 1 : 0,
                    card_number: self.newCard.number.replace(/ /g,''),
                    card_month: exp ? exp[0] : '',
                    card_year: exp ? exp[1] : '',
                    card_cvv: self.newCard.cvv,
                    plan: this.selected_plan_id,
                    add_on_team : self.add_on_team,
                    add_on_client : self.add_on_client,
                    recurring_type: self.upgrade_data.type,
                    amount: self.upgrade_data.amount,
                    start_date: moment(moment(), "YYYY-MM-DD").format('YYYY-MM-DD') ,
                    next_date: self.upgrade_data.type == 'Yearly' ? moment(moment(), "YYYY-MM-DD").add(1, 'years').format('YYYY-MM-DD') : moment(moment(), "YYYY-MM-DD").add(1, 'months').format('YYYY-MM-DD')
                  };
                        axios({
                        method: "POST",
                        headers : {
                                    Authorization:"Bearer " + localStorage.getItem('token')
                            },
                        url: "/api/update-subscription-stripe",
                        data: req
                        })
                        .then(response => {
                            if (response.data.flag == 1) {
                                let result = response.data.data;
                                localStorage.setItem("next_payment_date", result.next_payment_date);
                                localStorage.setItem("plan", result.plan);
                                localStorage.setItem("recurring_type", result.recurring_type);
                                localStorage.setItem("card_last", result.card_number);
                                Vue.$toast.success('Subscription changed successfuly', {
                                        position: 'top-right'
                                        });
                                location.reload();
                                self.upgradeButtonDisable = false;
                                self.upgradeButtonDark = true;

                            } else {
                              self.upgradeButtonDisable = false;
                                Vue.$toast.error(response.data.data, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            self.upgradeButtonDisable = false;
                            self.upgradeButtonDark = true;
                            Vue.$toast.error(err.response.data.message, {
                            position: 'top-right'
                            });
                        });
                        }
                      }
              }
            });
    
        },
    addon(flag,flag2){
      if(flag == 'add' && flag2 == 'team'){
        this.add_on_team = this.add_on_team + 1;
        this.upgrade_data.amount = parseInt(this.upgrade_data.amount) + parseInt(this.upgrade_data.type =='Monthly' ? this.selected_plan.ad_ons_team_price : this.selected_plan.ad_ons_team_price *12)
      }
      if(flag != 'add' && flag2 == 'team'){
        if(this.add_on_team != 0){
          this.add_on_team = this.add_on_team - 1;
          this.upgrade_data.amount = parseInt(this.upgrade_data.amount) - parseInt(this.upgrade_data.type =='Monthly' ? this.selected_plan.ad_ons_team_price : this.selected_plan.ad_ons_team_price *12)
        }
      }
      if(flag == 'add' && flag2 == 'client'){
        this.add_on_client = this.add_on_client + 1;
        this.upgrade_data.amount = parseInt(this.upgrade_data.amount) + parseInt( this.upgrade_data.type =='Monthly' ? this.selected_plan.ad_ons_client_price : this.selected_plan.ad_ons_client_price*12)
      }
      if(flag != 'add' && flag2 == 'client'){
        if(this.add_on_client != 0){
          this.add_on_client = this.add_on_client - 1;
          this.upgrade_data.amount = parseInt(this.upgrade_data.amount) - parseInt(this.upgrade_data.type =='Monthly' ? this.selected_plan.ad_ons_client_price : this.selected_plan.ad_ons_client_price*12)
        }
      }
    },
    uploadFile(id){
      let self = this;
      let flag = 1;
      if(id =='agreement' && self.files.agreement.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }
      else if(id =='id' && self.files.id.length == 0){
         Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='bill' && self.files.bill.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_a' && self.files.letter_a.length == 0){
         Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_b' && self.files.letter_b.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }
      else{
          const data = new FormData();
          if(id == 'agreement')
            data.append('file_agreement', self.files.agreement);
          if(id == 'id')
            data.append('file_id', self.files.id);
          if(id == 'bill')
            data.append('file_bill', self.files.bill);
          if(id == 'letter_a')
            data.append('file_letter_a', self.files.letter_a);
          if(id == 'letter_b')
            data.append('file_letter_b',self.files.letter_b);
          data.append('flag',id);
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      }
    },
    deleteChip(index){
      if(index == 'agreement')
        this.file_flag.agreement.name = ''
      if(index == 'id')
        this.file_flag.id.name = '';
      if(index == 'bill')
        this.file_flag.bill.name = '';
      if(index == 'leter_a')
        this.file_flag.letter_a.name = '';
      if(index == 'letter_b')
        this.file_flag.letter_b.name = '';
    },
    addEmail(){
        this.emailFlag = 1;
    },
    upgrade(flag,plan,swithFamily,index){
    
            if(flag == 'upgrade')
            this.is_downgrade = 0;
            else
            this.is_downgrade = 1;
            if(swithFamily=='on'){
            this.upgrade_data.plan = plan.name;
            this.upgrade_data.type = 'Yearly';
            this.upgrade_data.amount = (plan.yearly * 12).toFixed(2);
            this.selected_plan = plan
            this.selected_plan_id = index
            }
            if(swithFamily!='on'){
            this.upgrade_data.plan = plan.name;
            this.upgrade_data.type = 'Monthly';
            this.upgrade_data.amount = parseFloat(plan.monthly).toFixed(2);
            this.selected_plan = plan
            this.selected_plan_id = index
            }
            
            this.dialog2 = true;
            
        },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password is required', {
                position: 'top-right'
            });
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password is required', {
                position: 'top-right'
            });
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success('Password has been changed', {
                position: 'top-right'
                });
            this.password = '';
            this.confpassword = '';

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },

    add: function () {
         // for setting tab
    },

    getUserProfile() {
    let flag = 1;
    let self = this;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.user = data.user;
              this.subscription = data.subscription;
              if(data.subscription){
                for (const val in this.settings) {
                  if(val == data.subscription.plan_name){
                    this.current_plan = this.settings[val];
                    this.recurring_type = data.subscription.recurring_type
                  }
                }
              }else{
                this.$router.push('/admin/subscription');
              }
            }else {
                // Vue.$toast.error(data.message, {
                // position: 'top-right'
                // });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
    getSettings() {
    let flag = 1;
    axios({
          method: "POST",
           
          url: "/api/get-site-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            console.log(data);
            if(data){
              localStorage.setItem("settings",JSON.stringify(data.settings))
              this.settings = data.settings;
              
            }


         })
          .catch(err => {
            

           });
     },



    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.user.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.user.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }

        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/updateprofile",
          data: self.user
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Profile updated', {
                    position: 'top-right'
                  })
                  //this.$router.push('company-profiles')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },

created() {
      console.log('created called.');
      this.getSettings();
      this.getUserProfile();
      
    },

}
</script>

<style >
.sub-header-account{
    color: #003452!important;
    font-size: 14px!important;
    font-weight: 600!important;
    opacity: .6!important;
    width: 100%!important;
    display: inline-block!important;
    text-transform: uppercase!important;
}
.sub-header-account-2{
    font-weight: 600!important;
    font-size: 16px!important;
    color: #f06b66!important;
    cursor: pointer;
    text-transform: none!important;
}
.account-pricing__heading{
    color: #303030;
    font-size: 18px;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 5px;
}
.account-pricing__description {
    color: #003452;
    opacity: .6;
    text-transform: uppercase;
    font-size: 14px;
    padding-right: 10px;
}
.account-pricing__plan {
    font-size: 14px;
    color: #f06b66;
}
em.account-pricing__plan {
    color: #f06b66;
}
.card-header-11{
    color: #f06b66;
    font-size: 18px!important;
    font-weight: 900;
    text-transform: uppercase;
}
</style>
