<template>
<v-app id="inspire">

<vue-header-admin v-if="role.name != 'client'"></vue-header-admin>
<vue-header v-if="role.name == 'client'"></vue-header>
<v-main :style="role.name == 'client' ? 'padding:0px!important;margin-top: 150px;background:white;'  :'background:white;margin-top:15px;'">
    <v-container fluid style="margin-bottom:150px;margin-bottom: 72px;">
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="8">
              
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h1 class="heading-h2 mt-10 text-h3 text-typo font-weight-bold" color="#57AF5B">Preview Credit Report</h1>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h2 class=" mt-5 text-h3 " style="color:#666666;">Personal Profile</h2>
                    <p class="">Personal information as it appears on the credit file. Check carefully, as inaccuracies can mean identity theft. if any personal information is incorrect, click the record to save it as a saved dispute item for the wizard.</p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    
                    <v-simple-table>
                        <template v-slot:default>
                          <thead style="background: aliceblue;">
                            <tr>
                              <th class="text-left">
                                
                              </th>
                              <th class="text-left">
                                <img height="35" class="image-logo2" src="@/assets/img/tr.png">
                              </th>
                              <th class="text-left">
                                 <img height="35" class="image-logo2" src="@/assets/img/ex.png">
                              </th>
                              <th class="text-left">
                                <img height="35" class="image-logo2" src="@/assets/img/eq.png">
                              </th>
                              
                            </tr>
                          </thead>
                          <tbody v-if="user">
                            <tr
                            >
                              <td><b>Score:</b></td>
                              <td>{{FinalData.profile.score.transunion}}</td>
                              <td>{{FinalData.profile.score.experian}}</td>
                              <td>{{FinalData.profile.score.equifax}}</td>
                              
                            </tr>
                            <tr>
                              <td><b>CREDIT REPORT DATE:</b></td>
                              <td>{{FinalData.profile.report_date.transunion}}</td>
                              <td>{{FinalData.profile.report_date.experian}}</td>
                              <td>{{FinalData.profile.report_date.equifax}}</td>
                            </tr>
                            <tr>
                              <td><b>NAME:</b></td>
                              <td>{{FinalData.profile.name.transunion}}</td>
                              <td>{{FinalData.profile.name.experian}}</td>
                              <td>{{FinalData.profile.name.equifax}}</td>
                             
                            </tr>
                            <tr>
                              <td><b>ALSO KNOWN AS:</b></td>
                                <td>{{FinalData.profile.known_as.transunion}}</td>
                                <td>{{FinalData.profile.known_as.experian}}</td>
                                <td>{{FinalData.profile.known_as.equifax}}</td>
                            </tr>
                            <tr>
                              <td><b>DATE OF BIRTH: </b></td>
                               <td>{{FinalData.profile.birth.transunion}}</td>
                                <td>{{FinalData.profile.birth.experian}}</td>
                                <td>{{FinalData.profile.birth.equifax}}</td>
                            </tr>
                             <tr>
                              <td><b>CURRENT ADDRESS: </b></td>
                                <td><span v-for="data in FinalData.profile.current_address.transunion">{{data}}<br/></span></td>
                                <td><span v-for="data in FinalData.profile.current_address.experian">{{data}} <br/></span></td>
                                <td><span v-for="data in FinalData.profile.current_address.equifax">{{data}}<br/></span></td>
                            </tr>
                            <tr>
                              <td><b>EMPLOYER: </b></td>
                              <td><span v-for="data in FinalData.profile.employer.transunion">{{data}}<br/></span></td>
                                <td><span v-for="data in FinalData.profile.employer.experian">{{data}} <br/></span></td>
                                <td><span v-for="data in FinalData.profile.employer.equifax">{{data}}<br/></span></td>
                            </tr>
                             <tr>
                              <td><b>PREVIOUS ADDRESS: </b></td>
                                <td><span v-for="data in FinalData.profile.previous_address.transunion">{{data}}<br/></span></td>
                                <td><span v-for="data in FinalData.profile.previous_address.experian">{{data}} <br/></span></td>
                                <td><span v-for="data in FinalData.profile.previous_address.equifax">{{data}}<br/></span></td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h2 class=" mt-10" style="color:#666666;">Credit Summary</h2>
                    <p class=" greycolor">An overview of present and past credit status including open and closed accounts and balance information.</p>
                    </v-col>
                     <v-col
                      cols="12"
                      md="12"
                    >
                    
                    <v-simple-table>
                        <template v-slot:default>
                          <thead style="background: aliceblue;">
                            <tr>
                              <th class="text-left">
                                
                              </th>
                              <th class="text-left">
                                <img height="35" class="image-logo2" src="@/assets/img/tr.png">
                              </th>
                              <th class="text-left">
                                 <img height="35" class="image-logo2" src="@/assets/img/ex.png">
                              </th>
                              <th class="text-left">
                                <img height="35" class="image-logo2" src="@/assets/img/eq.png">
                              </th>
                              
                            </tr>
                          </thead>
                          <tbody v-if="user">
                            <tr
                            >
                              <td><b>TOTAL ACCOUNTS:</b></td>
                              <td>{{FinalData.credit_summary.transunion.TotalAccounts}}</td>
                              <td>{{FinalData.credit_summary.experian.TotalAccounts}}</td>
                              <td>{{FinalData.credit_summary.equifax.TotalAccounts}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>OPEN ACCOUNTS:</b></td>
                              <td>{{FinalData.credit_summary.transunion.OpenAccounts}}</td>
                              <td>{{FinalData.credit_summary.experian.OpenAccounts}}</td>
                              <td>{{FinalData.credit_summary.equifax.OpenAccounts}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>CLOSED ACCOUNTS:</b></td>
                              <td>{{FinalData.credit_summary.transunion.CloseAccounts}}</td>
                              <td>{{FinalData.credit_summary.experian.CloseAccounts}}</td>
                              <td>{{FinalData.credit_summary.equifax.CloseAccounts}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>DELINQUENT:</b></td>
                              <td>{{FinalData.credit_summary.transunion.DelinquentAccounts}}</td>
                              <td>{{FinalData.credit_summary.experian.DelinquentAccounts}}</td>
                              <td>{{FinalData.credit_summary.equifax.DelinquentAccounts}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>DEROGATORY:</b></td>
                              <td>{{FinalData.credit_summary.transunion.DerogatoryAccounts}}</td>
                              <td>{{FinalData.credit_summary.experian.DerogatoryAccounts}}</td>
                              <td>{{FinalData.credit_summary.equifax.DerogatoryAccounts}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>BALANCES:</b></td>
                              <td>${{parseInt(FinalData.credit_summary.transunion.TotalBalances).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}}</td>
                              <td>${{parseInt(FinalData.credit_summary.experian.TotalBalances).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}}</td>
                              <td>${{parseInt(FinalData.credit_summary.equifax.TotalBalances).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>PAYMENTS:</b></td>
                              <td>${{parseInt(FinalData.credit_summary.transunion.TotalMonthlyPayments).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}}</td>
                              <td>${{parseInt(FinalData.credit_summary.experian.TotalMonthlyPayments).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}}</td>
                              <td>${{parseInt(FinalData.credit_summary.equifax.TotalMonthlyPayments).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>PUBLIC RECORDS:</b></td>
                              <td>{{FinalData.credit_summary.transunion.NumberOfRecords}}</td>
                              <td>{{FinalData.credit_summary.experian.NumberOfRecords}}</td>
                              <td>{{FinalData.credit_summary.equifax.NumberOfRecords}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>INQUIRIES (2 years):</b></td>
                              <td>{{FinalData.credit_summary.transunion.NumberInLast2Years}}</td>
                              <td>{{FinalData.credit_summary.experian.NumberInLast2Years}}</td>
                              <td>{{FinalData.credit_summary.equifax.NumberInLast2Years}}</td>
                              
                            </tr>
                          </tbody>
                        </template>
                    </v-simple-table>
                     </v-col>
                      <v-col
                      cols="12"
                      md="12"
                    >
                    <h2 class=" mt-10" style="color:#666666;">Credit Inquiries</h2>
                    <p class=" greycolor">Organizations who have obtained a copy of your Credit Report. Inquiries can remain on a credit file for up to two years.</p>
                    </v-col>
                     <v-col
                      cols="12"
                      md="12"
                    >
                    
                    <v-simple-table id="inquiry_div">
                        <template v-slot:default>
                          <thead style="background: aliceblue;">
                            <tr>
                              <th class="text-left">
                                <v-checkbox
                                v-model="checkAll"
                                :value="'all'"
                                @change="checkAllFunc"
                                color="red"
                                class="float-left checkbox-credit"
                                    ></v-checkbox>
                              </th>
                              <th class="text-left">
                                <img height="35" class="image-logo2" src="@/assets/img/tr.png">
                              </th>
                              <th class="text-left">
                                 <img height="35" class="image-logo2" src="@/assets/img/ex.png">
                              </th>
                              <th class="text-left">
                                <img height="35" class="image-logo2" src="@/assets/img/eq.png">
                              </th>
                              
                            </tr>
                          </thead>
                          <tbody v-if="user" >
                            <tr
                              v-for="data in FinalData.credit_inquiries"
                            >
                              <td>{{data.title}} <br/> {{data.date}}</td>
                              <td style="text-align:center;">
                                
                                <v-icon v-if="data.transunion" left style="font-size:35px;color:green;margin: 15px 0px -15px 0px;">
                                  mdi-check
                                </v-icon>
                                <v-checkbox
                                v-if="data.transunion"
                                :value="data.title+',transunion,'+data.date"
                                label="red"
                                color="red"
                                v-model="userFeedback.inquiry.check"
                                class="align-center justify-center checkbox-credit"
                                      :label="`Click To Dispute This Inquiry`"
                                    ></v-checkbox>
                              </td>
                              <td style="text-align:center;"> 
                                <v-icon v-if="data.experian" left style="font-size:35px;color:green;margin: 15px 0px -15px 0px;">
                                  mdi-check
                                </v-icon>
                                <v-checkbox
                                v-if="data.experian"
                                v-model="userFeedback.inquiry.check"
                                label="red"
                                :value="data.title+',experian,'+data.date"
                                color="red"
                                class="align-center justify-center checkbox-credit"
                                      :label="`Click To Dispute This Inquiry`"
                                    ></v-checkbox>
                              </td>
                              <td style="text-align:center;"> 
                                <v-icon v-if="data.equifax" left style="font-size:35px;color:green;margin: 15px 0px -15px 0px;">
                                  mdi-check
                                </v-icon>
                                <v-checkbox
                                v-if="data.equifax"
                                v-model="userFeedback.inquiry.check"
                                :value="data.title+',equifax,'+data.date"
                                label="red"
                                color="red"
                                class="align-center justify-center checkbox-credit"
                                      :label="`Click To Dispute This Inquiry`"
                                    ></v-checkbox>
                              </td>
                              
                            </tr>
                          </tbody>
                          <!-- Panel for dispute -->
                          <tfoot style="background: aliceblue;" v-show="userFeedback.inquiry.check.length != 0">
                            <tr>
                            <td class="pl-5">Status</td>
                            <td><div class="mr-2 ml-2 mt-5">
                                <v-select
                                v-model="userFeedback.inquiry.status.transunion"
                                :items="StatusOptions"
                                label="Choose status"
                                outlined
                              ></v-select></div>
                            </td>
                            <td><div class="mr-2 ml-2 mt-5">
                                  <v-select
                                  :items="StatusOptions"
                                  v-model="userFeedback.inquiry.status.experian"
                                  label="Choose status"
                                  outlined
                                ></v-select>
                                </div>
                            </td>
                            <td><div class="mr-2 ml-2 mt-5">
                              <v-select
                                  :items="StatusOptions"
                                  v-model="userFeedback.inquiry.status.equifax"
                                  label="Choose status"
                                  outlined
                                ></v-select></div>
                            </td>
                            </tr>
                            <tr>
                              <td style="color:red;" >Add Reason</td>
                              <td colspan="2"><div class="mr-2 ml-2 mt-5">
                              <v-select
                                  :items="reasonOptions"
                                  v-if="!userFeedback.inquiry.new_reason"
                                  v-model="userFeedback.inquiry.reason"
                                  label="Choose Reason"
                                  outlined
                                ></v-select>
                                 <v-text-field
                                  label="Add Reason"
                                  v-if="userFeedback.inquiry.new_reason"
                                  v-model="userFeedback.inquiry.reason"
                                  outlined
                                ></v-text-field>
                                </div> </td>
                                <td><span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.inquiry.new_reason = true, userFeedback.inquiry.reason = ''" v-if="!userFeedback.inquiry.new_reason">Add New Reason</span> <span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.inquiry.new_reason = false, userFeedback.inquiry.reason = ''" v-if="userFeedback.inquiry.new_reason">Choose Reason</span></td>
                            </tr>
                            <tr>
                              <td style="color:red;">Add Instruction</td>
                              <td colspan="2"><div class="mr-2 ml-2 mt-5">
                              <v-select
                                  :items="instructionOptions"
                                  v-model="userFeedback.inquiry.instruction"
                                  v-if="!userFeedback.inquiry.new_instruction"
                                  label="Choose Instruction"
                                  outlined
                                ></v-select>
                                
                                 <v-text-field
                                  label="Add Instruction"
                                  v-if="userFeedback.inquiry.new_instruction"
                                  v-model="userFeedback.inquiry.instruction"
                                  outlined
                                ></v-text-field>
                                </div> </td>
                                <td><span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.inquiry.new_instruction = true, userFeedback.inquiry.instruction = ''" v-if="!userFeedback.inquiry.new_instruction">Add New Instruction</span><span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.inquiry.new_instruction = false, userFeedback.inquiry.instruction = ''" v-if="userFeedback.inquiry.new_instruction">Choose Instruction</span></td>
                            </tr>
                          </tfoot>
                          <!-- End Panel for dispute -->
                        </template>
                    </v-simple-table>
                     </v-col>
                      <v-col
                      cols="12"
                      md="12"
                    >
                    <h2 class=" mt-10" style="color:#666666;">Public Records</h2>
                    <p class=" greycolor">Public records include bankruptcy filings, court records, tax liens and monetary judgments. They remain for 7 -10 years.</p>
                    </v-col>
                     <v-col
                      v-for="(data,key) in FinalData.public_record"
                      v-bind:key="data.type"
                      cols="12"
                      md="12"
                    >
                    
                    <v-simple-table :id="'public_record_div_'+key">
                        <template v-slot:default>
                          <thead style="background: aliceblue;">
                            <tr>
                              <th class="text-left">
                                
                              </th>
                              <th class="text-left">
                                <img height="35" class="image-logo2" src="@/assets/img/tr.png">
                              </th>
                              <th class="text-left">
                                 <img height="35" class="image-logo2" src="@/assets/img/ex.png">
                              </th>
                              <th class="text-left">
                                <img height="35" class="image-logo2" src="@/assets/img/eq.png">
                              </th>
                              
                            </tr>
                          </thead>
                          <tbody v-if="user">
                            <tr
                            >
                              <td><b>Type:</b></td>
                              <td >{{data.type == 'TransUnion' ? data.value.type : ''}}</td>
                              <td >{{data.type == 'Experian' ? data.value.type : ''}}</td>
                              <td >{{data.type == 'Equifax' ? data.value.type : ''}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td ><b>Status:</b></td>
                              <td >{{data.type == 'TransUnion' ? data.value.status : ''}}</td>
                              <td >{{data.type == 'Experian' ? data.value.status : ''}}</td>
                              <td >{{data.type == 'Equifax' ? data.value.status : ''}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>Date Filed/Reported:</b></td>
                              <td >{{data.type == 'TransUnion' ? data.value.date_reported : ''}}</td>
                              <td >{{data.type == 'Experian' ? data.value.date_reported : ''}}</td>
                              <td >{{data.type == 'Equifax' ? data.value.date_reported : ''}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>Reference#:</b></td>
                              <td >{{data.type == 'TransUnion' ? data.value.reference : ''}}</td>
                              <td >{{data.type == 'Experian' ? data.value.reference : ''}}</td>
                              <td >{{data.type == 'Equifax' ? data.value.reference : ''}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>Closing Date:</b></td>
                              <td >{{data.type == 'TransUnion' ? data.value.closing_date : ''}}</td>
                              <td >{{data.type == 'Experian' ? data.value.closing_date : ''}}</td>
                              <td >{{data.type == 'Equifax' ? data.value.closing_date : ''}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>Asset Amount:</b></td>
                              <td >{{data.type == 'TransUnion' ? data.value.asset_amount : ''}}</td>
                              <td >{{data.type == 'Experian' ? data.value.asset_amount : ''}}</td>
                              <td >{{data.type == 'Equifax' ? data.value.asset_amount : ''}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>Court:</b></td>
                              <td >{{data.type == 'TransUnion' ? data.value.court : ''}}</td>
                              <td >{{data.type == 'Experian' ? data.value.court : ''}}</td>
                              <td >{{data.type == 'Equifax' ? data.value.court : ''}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>Liability:</b></td>
                              <td >{{data.type == 'TransUnion' ? data.value.liability : ''}}</td>
                              <td >{{data.type == 'Experian' ? data.value.liability : ''}}</td>
                              <td >{{data.type == 'Equifax' ? data.value.liability : ''}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>Exempt Amount:</b></td>
                              <td >{{data.type == 'TransUnion' ? data.value.exempt_amount : ''}}</td>
                              <td >{{data.type == 'Experian' ? data.value.exempt_amount : ''}}</td>
                              <td >{{data.type == 'Equifax' ? data.value.exempt_amount : ''}}</td>
                              
                            </tr>
                          </tbody>
                          <!-- Panel for dispute -->
                          <tfoot style="background: aliceblue;" >
                            <tr>
                            <td class="pl-5">Status</td>
                            <td><div class="mr-2 ml-2 mt-5">
                                <v-select
                                v-model="userFeedback.public_record[key].status.transunion"
                                :items="StatusOptions"
                                label="Choose status"
                                outlined
                              ></v-select></div>
                            </td>
                            <td><div class="mr-2 ml-2 mt-5">
                                  <v-select
                                  :items="StatusOptions"
                                  v-model="userFeedback.public_record[key].status.experian"
                                  label="Choose status"
                                  outlined
                                ></v-select>
                                </div>
                            </td>
                            <td><div class="mr-2 ml-2 mt-5">
                              <v-select
                                  :items="StatusOptions"
                                  v-model="userFeedback.public_record[key].status.equifax"
                                  label="Choose status"
                                  outlined
                                ></v-select></div>
                            </td>
                            </tr>
                            <tr v-if="userFeedback.public_record[key].status.transunion == 'Negative' || userFeedback.public_record[key].status.experian == 'Negative' || userFeedback.public_record[key].status.equifax == 'Negative'">
                              <td style="color:red;">Add Reason</td>
                              <td colspan="2"><div class="mr-2 ml-2 mt-5">
                              <v-select
                                  :items="reasonOptions"
                                  v-if="!userFeedback.public_record[key].new_reason"
                                  v-model="userFeedback.public_record[key].reason"
                                  label="Choose Reason"
                                  outlined
                                ></v-select>
                                 <v-text-field
                                  label="Add Reason"
                                  v-if="userFeedback.public_record[key].new_reason"
                                  v-model="userFeedback.public_record[key].reason"
                                  outlined
                                ></v-text-field>
                                </div> </td>
                                <td><span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.public_record[key].new_reason = true, userFeedback.public_record[key].reason = ''" v-if="!userFeedback.public_record[key].new_reason">Add New Reason</span><span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.public_record[key].new_reason = false, userFeedback.public_record[key].reason = ''" v-if="userFeedback.public_record[key].new_reason">Choose Reason</span></td>
                            </tr>
                            <tr v-if="userFeedback.public_record[key].status.transunion == 'Negative' || userFeedback.public_record[key].status.experian == 'Negative' || userFeedback.public_record[key].status.equifax == 'Negative'">
                              <td style="color:red;">Add Instruction</td>
                              <td colspan="2"><div class="mr-2 ml-2 mt-5">
                              <v-select
                                  :items="instructionOptions"
                                  v-if="!userFeedback.public_record[key].new_instruction"
                                  v-model="userFeedback.public_record[key].instruction"
                                  label="Choose Instruction"
                                  outlined
                                ></v-select>
                                <v-text-field
                                  label="Add Instruction"
                                  v-if="userFeedback.public_record[key].new_instruction"
                                  v-model="userFeedback.public_record[key].instruction"
                                  outlined
                                ></v-text-field>
                                </div> </td>
                                <td><span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.public_record[key].new_instruction = true, userFeedback.public_record[key].instruction = ''" v-if="!userFeedback.public_record[key].new_instruction">Add New Instruction</span><span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.public_record[key].new_instruction = false, userFeedback.public_record[key].instruction = ''" v-if="userFeedback.public_record[key].new_instruction">Choose Instruction</span></td>
                            </tr>
                          </tfoot>
                          <!-- End Panel for dispute -->
                        </template>
                    </v-simple-table>
                     </v-col>
                     <v-col
                      cols="12"
                      
                      md="12"
                    >
                    <h2 class=" mt-10" style="color:#666666;">Account History</h2>
                    <p class=" greycolor">Accounts "paid as agreed" can remain on a report for 10 years from last activity. Negatives should be removed after 7 years or 10 years for bankruptcies.</p>
                    </v-col>
                     <v-col
                      cols="12"
                      v-for="(data, key) in FinalData.account_history" v-bind:key="key"
                      md="12"
                    >
                    
                    <v-simple-table :id="'account_history_div_'+key">
                        <template v-slot:default>
                          <thead style="background: aliceblue;">
                            <tr>
                              <th class="text-left">
                                
                              </th>
                              <th class="text-left">
                                <img height="35" class="image-logo2" src="@/assets/img/tr.png">
                              </th>
                              <th class="text-left">
                                 <img height="35" class="image-logo2" src="@/assets/img/ex.png">
                              </th>
                              <th class="text-left">
                                <img height="35" class="image-logo2" src="@/assets/img/eq.png">
                              </th>
                              
                            </tr>
                            <!-- <tr>
                              <th class="text-center" colspan="4" style="font-size:18px;">{{data.title}}</th></tr> -->
                          </thead>
                          <tbody v-if="user">
                            <tr
                            >
                              <td><b>Account Name:</b></td>
                              <td >{{data.value.transunion.account_name }}</td>
                              <td >{{data.value.experian.account_name}}</td>
                              <td >{{data.value.equifax.account_name}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>Account #:</b></td>
                              <td >{{data.value.transunion.account ? data.value.transunion.account.slice(0, -4) + '****' : '' }}</td>
                              <td >{{data.value.experian.account ? data.value.experian.account.slice(0, -4) + '****' : ''}}</td>
                              <td >{{data.value.equifax.account ? data.value.equifax.account.slice(0, -4) + '****' : ''}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>High Balance:</b></td>
                              <td >{{data.value.transunion.high_balance ? '$'+parseInt(data.value.transunion.high_balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '' }}</td>
                              <td >{{data.value.experian.high_balance ? '$'+parseInt(data.value.experian.high_balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}}</td>
                              <td >{{data.value.equifax.high_balance ? '$'+parseInt(data.value.equifax.high_balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}}</td>
                              
                            </tr>
                            <tr
                            >
                              <td><b>Last Verified:</b></td>
                              <td >{{data.value.transunion.last_verifed }}</td>
                              <td >{{data.value.experian.last_verifed}}</td>
                              <td >{{data.value.equifax.last_verifed}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Date of Last Activity:</b></td>
                              <td >{{data.value.transunion.last_activity }}</td>
                              <td >{{data.value.experian.last_activity}}</td>
                              <td >{{data.value.equifax.last_activity}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Date Reported:</b></td>
                              <td >{{data.value.transunion.date_reported }}</td>
                              <td >{{data.value.experian.date_reported}}</td>
                              <td >{{data.value.equifax.date_reported}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Date Opened:</b></td>
                              <td >{{data.value.transunion.date_opened }}</td>
                              <td >{{data.value.experian.date_opened}}</td>
                              <td >{{data.value.equifax.date_opened}}</td>
                              
                            </tr>
                           
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Balance Owed:</b></td>
                              <td >{{ data.value.transunion.balance_owed ?  '$'+parseInt(data.value.transunion.balance_owed).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '' }}</td>
                              <td >{{data.value.experian.balance_owed ? '$'+parseInt(data.value.experian.balance_owed).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}}</td>
                              <td >{{data.value.equifax.balance_owed ? '$'+parseInt(data.value.equifax.balance_owed).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Closed Date:</b></td>
                              <td >{{data.value.transunion.closed_date }}</td>
                              <td >{{data.value.experian.closed_date}}</td>
                              <td >{{data.value.equifax.closed_date}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Account Rating:</b></td>
                              <td >{{data.value.transunion.account_rating }}</td>
                              <td >{{data.value.experian.account_rating}}</td>
                              <td >{{data.value.equifax.account_rating}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Account Description:</b></td>
                              <td >{{data.value.transunion.account_description }}</td>
                              <td >{{data.value.experian.account_description}}</td>
                              <td >{{data.value.equifax.account_description}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Dispute Status:</b></td>
                              <td >{{data.value.transunion.dispute_status }}</td>
                              <td >{{data.value.experian.dispute_status}}</td>
                              <td >{{data.value.equifax.dispute_status}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Creditor Type:</b></td>
                              <td >{{data.value.transunion.creditor_type }}</td>
                              <td >{{data.value.experian.creditor_type}}</td>
                              <td >{{data.value.equifax.creditor_type}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Account Status:</b></td>
                              <td >{{data.value.transunion.account_status }}</td>
                              <td >{{data.value.experian.account_status}}</td>
                              <td >{{data.value.equifax.account_status}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Payment Status:</b></td>
                              <td >{{data.value.transunion.payment_status }}</td>
                              <td >{{data.value.experian.payment_status}}</td>
                              <td >{{data.value.equifax.payment_status}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Creditor Remarks:</b></td>
                              <td >{{data.value.transunion.creditor_remark }}</td>
                              <td >{{data.value.experian.creditor_remark}}</td>
                              <td >{{data.value.equifax.creditor_remark}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Payment Amount:</b></td>
                              <td >{{data.value.transunion.payment_amount }}</td>
                              <td >{{data.value.experian.payment_amount}}</td>
                              <td >{{data.value.equifax.payment_amount}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Last Payment:</b></td>
                              <td >{{data.value.transunion.last_payment }}</td>
                              <td >{{data.value.experian.last_payment}}</td>
                              <td >{{data.value.equifax.last_payment}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Term Length:</b></td>
                              <td >{{data.value.transunion.term_length  ? data.value.transunion.term_length : 0 }} Month(s)</td>
                              <td >{{data.value.experian.term_length ? data.value.experian.term_length : 0}} Month(s)</td>
                              <td >{{data.value.equifax.term_length ? data.value.equifax.term_length : 0}} Month(s)</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Past Due Amount:</b></td>
                              <td >{{data.value.transunion.past_due_amount }}</td>
                              <td >{{data.value.experian.past_due_amount}}</td>
                              <td >{{data.value.equifax.past_due_amount}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Account Type:</b></td>
                              <td >{{data.value.transunion.account_type }}</td>
                              <td >{{data.value.experian.account_type}}</td>
                              <td >{{data.value.equifax.account_type}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Payment Frequency:</b></td>
                              <td >{{data.value.transunion.payment_frequency }}</td>
                              <td >{{data.value.experian.payment_frequency}}</td>
                              <td >{{data.value.equifax.payment_frequency}}</td>
                              
                            </tr>
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Credit Limit:</b></td>
                              <td >{{data.value.transunion.credit_limit }}</td>
                              <td >{{data.value.experian.credit_limit}}</td>
                              <td >{{data.value.equifax.credit_limit}}</td>
                              
                            </tr>
                            
                            <tr
                            v-show="displayFlag.includes(key)"
                            >
                              <td><b>Late Payment Status:</b></td>
                              <td >{{data.value.transunion.late_payment_status }}</td>
                              <td >{{data.value.experian.late_payment_status}}</td>
                              <td >{{data.value.equifax.late_payment_status}}</td>
                              
                            </tr>
                            <tr
                            
                            >
                              <td><span v-show="!displayFlag.includes(key)" @click="tableExpand(1,key)" :id="'show-more-button-'+key" style="text-decoration:none;color: #0a69f3;cursor: pointer;"> Show More +</span>
                              <span v-show="displayFlag.includes(key)" @click="tableExpand(2,key)" :id="'show-less-button-'+key" style="text-decoration:none;color: #0a69f3;cursor: pointer;"> Show Less -</span>
                              </td>
                              <td ></td>
                              <td ></td>
                              <td ></td>
                              
                            </tr>
                          </tbody>
                          <!-- Panel for dispute -->
                          <tfoot style="background: aliceblue;" >
                            <tr>
                            <td class="pl-5">Status</td>
                            <td><div class="mr-2 ml-2 mt-5">
                                <v-select
                                v-model="userFeedback.account_history[key].status.transunion"
                                :items="StatusOptions"
                                label="Choose status"
                                outlined
                              ></v-select></div>
                            </td>
                            <td><div class="mr-2 ml-2 mt-5">
                                  <v-select
                                  :items="StatusOptions"
                                  v-model="userFeedback.account_history[key].status.experian"
                                  label="Choose status"
                                  outlined
                                ></v-select>
                                </div>
                            </td>
                            <td><div class="mr-2 ml-2 mt-5">
                              <v-select
                                  :items="StatusOptions"
                                  v-model="userFeedback.account_history[key].status.equifax"
                                  label="Choose status"
                                  outlined
                                ></v-select></div>
                            </td>
                            </tr>
                            <tr v-if="userFeedback.account_history[key].status.transunion == 'Negative' || userFeedback.account_history[key].status.experian == 'Negative' || userFeedback.account_history[key].status.equifax == 'Negative'">
                              <td style="color:red;">Add Reason</td>
                              <td colspan="2"><div class="mr-2 ml-2 mt-5">
                              <v-select
                                  :items="reasonOptions"
                                  v-model="userFeedback.account_history[key].reason"
                                  v-if="!userFeedback.account_history[key].new_reason"
                                  label="Choose Reason"
                                  outlined
                                ></v-select>
                                 <v-text-field
                                  label="Add Reason"
                                  v-if="userFeedback.account_history[key].new_reason"
                                  v-model="userFeedback.account_history[key].reason"
                                  outlined
                                ></v-text-field>
                                </div> </td>
                                <td><span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.account_history[key].new_reason = true, userFeedback.account_history[key].reason = ''" v-if="!userFeedback.account_history[key].new_reason" >Add New Reason</span>
                                <span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.account_history[key].new_reason = false, userFeedback.account_history[key].reason = ''" v-if="userFeedback.account_history[key].new_reason" >Choose Reason</span></td>
                            </tr>
                            <tr v-if="userFeedback.account_history[key].status.transunion == 'Negative' || userFeedback.account_history[key].status.experian == 'Negative' || userFeedback.account_history[key].status.equifax == 'Negative'">
                              <td style="color:red;">Add Instruction</td>
                              <td colspan="2"><div class="mr-2 ml-2 mt-5">
                              <v-select
                                  :items="instructionOptions"
                                  v-if="!userFeedback.account_history[key].new_instruction"
                                  v-model="userFeedback.account_history[key].instruction"
                                  label="Choose Instruction"
                                  outlined
                                ></v-select>
                                
                                <v-text-field
                                  label="Add Reason"
                                  v-if="userFeedback.account_history[key].new_instruction"
                                  v-model="userFeedback.account_history[key].instruction"
                                  outlined
                                ></v-text-field>
                                </div> </td>
                                <td><span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.account_history[key].new_instruction = true, userFeedback.account_history[key].instruction = ''" v-if="!userFeedback.account_history[key].new_instruction">Add New Instruction</span>
                                <span style="color: #0075cc !important;cursor:pointer;" @click="userFeedback.account_history[key].new_instruction = false, userFeedback.account_history[key].instruction = ''" v-if="userFeedback.account_history[key].new_instruction">Choose Instruction</span></td>
                            </tr>
                          </tfoot>
                          <!-- End Panel for dispute -->
                        </template>
                    </v-simple-table>
                     </v-col>
                     <v-col
                      cols="12"
                      
                      md="12"
                    >
                    
                    <v-btn
                    large 
                    class="
                  font-weight-normal
                  bg-gradient-success
                  text-capitalize
                  ms-auto
                  py-3
                  ml-5
                  px-6
                  ms-3"
                    color="#00aeef"
                        @click="gotoWizard()"
                    dark
                >
                    Save my work and continue
                </v-btn>
                    <v-btn
                    outlined
                    large
                    color="#57AF5B"
                    class="
                    ml-5
                  font-weight-normal
                  text-capitalize
                  "
                    @click="$router.push('/')"

                        
                >
                    Do it later
                </v-btn>
                    </v-col>
                  </v-row>
            </v-col>
          </v-row>
          <v-overlay :value="overlay">
     <div class="loadingio-spinner-spinner-2k3nmd4xown"><div class="ldio-7xwhhkt0pnf">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>




    </v-overlay>
    </v-container>
    
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        user: '',
        overlay: false,
        displayFlag :[],
        role : JSON.parse(localStorage.getItem('role')),
        checkAll : '',
        client_id : '',
        StatusOptions : ['Negative', 'Positive', 'Repaired', 'Deleted', 'In Dispute', 'Verified', 'Updated', 'Unspecified', 'Ignore'],
        reasonOptions: ['Please correct/update this inaccurate information on my credit report.','The following personal information is incorrect','The following account is not mine','The following accounts were closed by me and should state that','The following account was a Bankruptcy/Charge-off. Balance should be $0','I never gave written permission for this account to be  on my consumer report','This account is not reporting to Experian or Equifax','This account is in violation of my consumer rights','The status is incorrect for the following account','The inquiry was not authorized'],
        instructionOptions: ['Please correct/update this inaccurate information on my credit report.','Please remove this inaccurate information from my credit report.','Please correct/update this inaccurate information on my credit report.','Please remove it from my credit report.','This is not mine. I am a victim of ID Theft and I have included a police report. Please investigate and remove from my credit report.','Please supply information on how you have verified this item.','Please ensure that all information is accurate'],
        userFeedback : {
          inquiry : {
            check : [],
            status : {
              transunion : 'Negative',
              experian : 'Negative',
              equifax : 'Negative'
            },
            reason :'',
            instruction : '',
            account  : {
              transunion : '',
              experian : '',
              equifax : ''
            },
            inquiry_date : '',
            new_reason: false,
            new_instruction: false
          },
          public_record : [],
          account_history: []
        },
        FinalData: {
          profile : {
                score : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                report_date : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                name : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                known_as : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                birth : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                current_address: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                employer: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                previous_address : {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
          },
          credit_summary : {
                  transunion : {},
                  experian : {},
                  equifax : {},
                
          },
          credit_inquiries: [],
          public_record : [],
          account_history : []
        }
      }
    },

    methods: {
      tableExpand(flag, index){
        

        if (flag === 1) {
          if(!this.displayFlag.includes(index))
            this.displayFlag.push(index);
        } else if(flag === 2) {
         const tmp = this.displayFlag;
         this.displayFlag = [];
         tmp.forEach(val => {
           if(val != index){
             this.displayFlag.push(val);
           }
         })
        }
      },
      assignData(){
        let self = this;
        const data = this.user;
        if(data.BundleComponents.BundleComponent.length == 5){
          data.BundleComponents.BundleComponent.push(data.BundleComponents.BundleComponent[4]);
          data.BundleComponents.BundleComponent.push(data.BundleComponents.BundleComponent[5]);
        }
        console.log('asdasd',data.BundleComponents.BundleComponent);
        // Score
        self.FinalData.profile.score.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].riskScore
        self.FinalData.profile.score.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].riskScore
        self.FinalData.profile.score.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].riskScore
        // report date
        self.FinalData.profile.report_date.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].Source.InquiryDate
        self.FinalData.profile.report_date.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].Source.InquiryDate
        self.FinalData.profile.report_date.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].Source.InquiryDate
        // name and known as
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.BorrowerName.forEach(element => {
          if(element.Source.Bureau.abbreviation == 'TransUnion'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.transunion = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.transunion = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
          if(element.Source.Bureau.abbreviation == 'Experian'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.experian = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.experian = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
          if(element.Source.Bureau.abbreviation == 'Equifax'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.equifax = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.equifax = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
        });
        //Birth
        self.FinalData.profile.birth.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[0].date
        self.FinalData.profile.birth.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[1].date
        self.FinalData.profile.birth.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[2].date
        //Address
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.BorrowerAddress.forEach(element =>{
          if(element.Source.Bureau.abbreviation == 'TransUnion'){
            if(!element.CreditAddress.unparsedStreet)
              self.FinalData.profile.current_address.transunion.push(element.CreditAddress.houseNumber + ' '+element.CreditAddress.streetName + ' '+element.CreditAddress.streetType+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            else
               self.FinalData.profile.current_address.transunion.push(element.CreditAddress.unparsedStreet+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            if(element.dateUpdated)
               self.FinalData.profile.current_address.transunion.push(element.dateUpdated);
          }
          if(element.Source.Bureau.abbreviation == 'Experian'){
             if(!element.CreditAddress.unparsedStreet)
              self.FinalData.profile.current_address.experian.push(element.CreditAddress.houseNumber + ' '+element.CreditAddress.streetName + ' '+element.CreditAddress.streetType+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            else
               self.FinalData.profile.current_address.experian.push(element.CreditAddress.unparsedStreet+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            if(element.dateUpdated)
               self.FinalData.profile.current_address.experian.push(element.dateUpdated);
          }
          if(element.Source.Bureau.abbreviation == 'Equifax'){
               if(!element.CreditAddress.unparsedStreet)
              self.FinalData.profile.current_address.equifax.push(element.CreditAddress.houseNumber + ' '+element.CreditAddress.streetName + ' '+element.CreditAddress.streetType+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            else
               self.FinalData.profile.current_address.equifax.push(element.CreditAddress.unparsedStreet+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            if(element.dateUpdated)
               self.FinalData.profile.current_address.equifax.push(element.dateUpdated);
          }
        })
        //Empoyer
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Employer.forEach(element =>{
          if(element.Source.Bureau.abbreviation == 'TransUnion'){
            if(element.name)
              self.FinalData.profile.employer.transunion.push(element.name);
            if(element.dateUpdated)
               self.FinalData.profile.employer.transunion.push(element.dateUpdated);
          }
          if(element.Source.Bureau.abbreviation == 'Experian'){
            if(element.name)
              self.FinalData.profile.employer.experian.push(element.name);
            if(element.dateUpdated)
               self.FinalData.profile.employer.experian.push(element.dateUpdated);
          }
          if(element.Source.Bureau.abbreviation == 'Equifax'){
            if(element.name)
              self.FinalData.profile.employer.equifax.push(element.name);
            if(element.dateUpdated)
               self.FinalData.profile.employer.equifax.push(element.dateUpdated);
          }
        })
        //Previous Address
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.PreviousAddress.forEach(element =>{
          if(element.Source.Bureau.abbreviation == 'TransUnion'){
            if(!element.CreditAddress.unparsedStreet)
              self.FinalData.profile.previous_address.transunion.push(element.CreditAddress.houseNumber + ' '+element.CreditAddress.streetName + ' '+element.CreditAddress.streetType+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            else
               self.FinalData.profile.previous_address.transunion.push(element.CreditAddress.unparsedStreet+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            if(element.dateUpdated)
               self.FinalData.profile.previous_address.transunion.push(element.dateUpdated);
          }
          if(element.Source.Bureau.abbreviation == 'Experian'){
             if(!element.CreditAddress.unparsedStreet)
              self.FinalData.profile.previous_address.experian.push(element.CreditAddress.houseNumber + ' '+element.CreditAddress.streetName + ' '+element.CreditAddress.streetType+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            else
               self.FinalData.profile.previous_address.experian.push(element.CreditAddress.unparsedStreet+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            if(element.dateUpdated)
               self.FinalData.profile.previous_address.experian.push(element.dateUpdated);
          }
          if(element.Source.Bureau.abbreviation == 'Equifax'){
               if(!element.CreditAddress.unparsedStreet)
              self.FinalData.profile.previous_address.equifax.push(element.CreditAddress.houseNumber + ' '+element.CreditAddress.streetName + ' '+element.CreditAddress.streetType+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            else
               self.FinalData.profile.previous_address.equifax.push(element.CreditAddress.unparsedStreet+' '+element.CreditAddress.city+' '+element.CreditAddress.stateCode+' '+element.CreditAddress.postalCode);
            if(element.dateUpdated)
               self.FinalData.profile.previous_address.equifax.push(element.dateUpdated);
          }
        })
        //Summary
        self.FinalData.credit_summary.transunion = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.TransUnion, ...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.TransUnion,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.TransUnion };
        self.FinalData.credit_summary.experian = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.Experian, ...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.Experian,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.Experian}
        self.FinalData.credit_summary.equifax = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.Equifax,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.Equifax,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.Equifax}
        //Inquiries
        
        if(!Array.isArray(data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition)){
          data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition =[data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition]
        }
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition.forEach(element =>{
          if(element.Inquiry.Source.Bureau.abbreviation == 'TransUnion'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : true, experian : false,equifax : false})
          }
          if(element.Inquiry.Source.Bureau.abbreviation == 'Experian'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : false, experian : true,equifax : false})
          }
          if(element.Inquiry.Source.Bureau.abbreviation == 'Equifax'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : false, experian : false,equifax : true})
          } 
        })
        //Public record
        if(data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.PulblicRecordPartition){
          data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.PulblicRecordPartition.PublicRecord.forEach(element =>{
          if(element.Source.Bureau.abbreviation == 'TransUnion'){
            self.FinalData.public_record.push({type : 'TransUnion',value: {
              type : element.Type.abbreviation,
              status :  element.Status.abbreviation,
              date_reported: element.dateFiled,
              reference: element.referenceNumber,
              closing_date: element.Bankruptcy.dateResolved,
              asset_amount: element.Bankruptcy.assetAmount,
              court: element.courtName,
              liability: element.Bankruptcy.liabilityAmount,
              exempt_amount: element.Bankruptcy.exemptAmount
            }})
            self.userFeedback.public_record.push({
              status : {
                transunion : 'Negative',
                experian : '',
                equifax : ''
              },
              reason : '',
              instruction : '',
              new_reason: false,
              new_instruction: false,
              creditor : element.Type.abbreviation,
              account  : {
                transunion : element.referenceNumber,
                experian : '',
                equifax : ''
            },
            });
          }
          if(element.Source.Bureau.abbreviation == 'Experian'){
            self.FinalData.public_record.push({type : 'Experian',value: {
              type : element.Type.abbreviation,
              status :  element.Status.abbreviation,
              date_reported: element.dateFiled,
              reference: element.referenceNumber,
              closing_date: element.Bankruptcy.dateResolved,
              asset_amount: element.Bankruptcy.assetAmount,
              court: element.courtName,
              liability: element.Bankruptcy.liabilityAmount,
              exempt_amount: element.Bankruptcy.exemptAmount
            }})
            self.userFeedback.public_record.push({
              status : {
                transunion : '',
                experian : 'Negative',
                equifax : ''
              },
              reason : '',
              instruction : '',
              new_reason: false,
              new_instruction: false,
              creditor : element.Type.abbreviation,
              account  : {
                transunion : '',
                experian : element.referenceNumber,
                equifax : ''
            },
            });
          }
          if(element.Source.Bureau.abbreviation == 'Equifax'){
            self.FinalData.public_record.push({type : 'Equifax',value: {
              type : element.Type.abbreviation,
              status :  element.Status.abbreviation,
              date_reported: element.dateFiled,
              reference: element.referenceNumber,
              closing_date: element.Bankruptcy.dateResolved,
              asset_amount: element.Bankruptcy.assetAmount,
              court: element.courtName,
              liability: element.Bankruptcy.liabilityAmount,
              exempt_amount: element.Bankruptcy.exemptAmount
            }})
            self.userFeedback.public_record.push({
              status : {
                transunion : '',
                experian : '',
                equifax : 'Negative'
              },
              reason : '',
              instruction : '',
              new_reason: false,
              new_instruction: false,
              creditor : element.Type.abbreviation,
              account  : {
                transunion : '',
                experian : '',
                equifax : element.referenceNumber
            },
            });
          } 
        })
        
        }
        //account history
        
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.TradeLinePartition.forEach(element =>{
          
          let tmp = {
            title : '',
            value : 
              {
                transunion : {
                    account_name: '',
                    account: '',
                    high_balance: '',
                    last_verifed: '',
                    last_activity: '',
                    date_reported: '',
                    date_opened: '',
                    balance_owed: '',
                    closed_date:'',
                    account_rating: '',
                    account_description: '',
                    dispute_status: '',
                    creditor_type: '',
                    account_status: '',
                    payment_status: '',
                    creditor_remark: '',
                    payment_amount: '',
                    last_payment: '',
                    term_length: '',
                    past_due_amount: '',
                    account_type: '',
                    payment_frequency: '',
                    credit_limit: '',
                    late_payment_status: ''      
                },
                experian : {
                    account_name: '',
                    account: '',
                    high_balance: '',
                    last_verifed: '',
                    last_activity: '',
                    date_reported: '',
                    date_opened: '',
                    balance_owed: '',
                    closed_date:'',
                    account_rating: '',
                    account_description: '',
                    dispute_status: '',
                    creditor_type: '',
                    account_status: '',
                    payment_status: '',
                    creditor_remark: '',
                    payment_amount: '',
                    last_payment: '',
                    term_length: '',
                    past_due_amount: '',
                    account_type: '',
                    payment_frequency: '',
                    credit_limit: '',
                    late_payment_status: ''      
                },
                equifax : {
                    account_name: '',
                    account: '',
                    high_balance: '',
                    last_verifed: '',
                    last_activity: '',
                    date_reported: '',
                    date_opened: '',
                    balance_owed: '',
                    closed_date:'',
                    account_rating: '',
                    account_description: '',
                    dispute_status: '',
                    creditor_type: '',
                    account_status: '',
                    payment_status: '',
                    creditor_remark: '',
                    payment_amount: '',
                    last_payment: '',
                    term_length: '',
                    past_due_amount: '',
                    account_type: '',
                    payment_frequency: '',
                    credit_limit: '',
                    late_payment_status: ''      
                }
              },
              
            
          }
          let name = '';
          if(Array.isArray(element.Tradeline)){
            element.Tradeline.forEach(account => {
              name = account.creditorName;
              if(account.Source.Bureau.abbreviation == 'TransUnion'){
                    tmp.value.transunion.account_name = account.creditorName
                    tmp.value.transunion.account = account.accountNumber
                    tmp.value.transunion.high_balance = account.highBalance
                    tmp.value.transunion.last_verifed = account.dateVerified ? account.dateVerified  : ''
                    tmp.value.transunion.last_activity = account.dateAccountStatus
                    tmp.value.transunion.date_reported = account.dateReported
                    tmp.value.transunion.date_opened = account.dateOpened
                    tmp.value.transunion.balance_owed = account.currentBalance
                    tmp.value.transunion.closed_date = account.dateClosed ? account.dateClosed : ''
                    tmp.value.transunion.account_rating = account.AccountCondition.abbreviation
                    tmp.value.transunion.account_description = account.AccountDesignator.abbreviation
                    tmp.value.transunion.dispute_status = account.DisputeFlag.abbreviation
                    tmp.value.transunion.creditor_type = account.IndustryCode.abbreviation
                    tmp.value.transunion.account_status = account.OpenClosed.abbreviation
                    tmp.value.transunion.payment_status = account.PayStatus.abbreviation
                    if(account.Remark){
                      if(Array.isArray(account.Remark)){
                        let text = ''
                        account.Remark.forEach(v => {
                          text = text+v.RemarkCode.abbreviation;
                        });
                        tmp.value.transunion.creditor_remark = text
                      }else{
                        tmp.value.transunion.creditor_remark = account.Remark.RemarkCode.abbreviation
                      }
                    }
                    else
                      tmp.value.transunion.creditor_remark = ''
                    tmp.value.transunion.payment_amount = account.GrantedTrade ? account.GrantedTrade.monthlyPayment :''
                    tmp.value.transunion.last_payment = account.GrantedTrade ? account.GrantedTrade.dateLastPayment : ''
                    tmp.value.transunion.term_length = account.GrantedTrade ? account.GrantedTrade.termMonths :''
                    tmp.value.transunion.past_due_amount = account.GrantedTrade ? account.GrantedTrade.amountPastDue :''
                    tmp.value.transunion.account_type = account.GrantedTrade ? account.GrantedTrade.AccountType.abbreviation:""
                    tmp.value.transunion.payment_frequency = account.GrantedTrade ? account.GrantedTrade.PaymentFrequency.abbreviation :''
                    
                    
              }
              if(account.Source.Bureau.abbreviation == 'Experian'){
                    tmp.value.experian.account_name = account.creditorName
                    tmp.value.experian.account = account.accountNumber
                    tmp.value.experian.high_balance = account.highBalance
                    tmp.value.experian.last_verifed = account.dateVerified ? account.dateVerified  : ''
                    tmp.value.experian.last_activity = account.dateAccountStatus
                    tmp.value.experian.date_reported = account.dateReported
                    tmp.value.experian.date_opened = account.dateOpened
                    tmp.value.experian.balance_owed = account.currentBalance
                    tmp.value.experian.closed_date = account.dateClosed ? account.dateClosed : ''
                    tmp.value.experian.account_rating = account.AccountCondition.abbreviation
                    tmp.value.experian.account_description = account.AccountDesignator.abbreviation
                    tmp.value.experian.dispute_status = account.DisputeFlag.abbreviation
                    tmp.value.experian.creditor_type = account.IndustryCode.abbreviation
                    tmp.value.experian.account_status = account.OpenClosed.abbreviation
                    tmp.value.experian.payment_status = account.PayStatus.abbreviation
                     if(account.Remark){
                      if(Array.isArray(account.Remark)){
                        let text = ''
                        account.Remark.forEach(v => {
                          text = text+v.RemarkCode.abbreviation;
                        });
                        tmp.value.experian.creditor_remark = text
                      }else{
                        tmp.value.experian.creditor_remark = account.Remark.RemarkCode.abbreviation
                      }
                    }
                    else
                      tmp.value.transunion.creditor_remark = ''
                    tmp.value.experian.payment_amount = account.GrantedTrade ? account.GrantedTrade.monthlyPayment :''
                    tmp.value.experian.last_payment = account.GrantedTrade ? account.GrantedTrade.dateLastPayment:''
                    tmp.value.experian.term_length = account.GrantedTrade ? account.GrantedTrade.termMonths:''
                    tmp.value.experian.past_due_amount = account.GrantedTrade ? account.GrantedTrade.amountPastDue:''
                    tmp.value.experian.account_type = account.GrantedTrade ? account.GrantedTrade.AccountType.abbreviation:''
                    tmp.value.experian.payment_frequency = account.GrantedTrade ? account.GrantedTrade.PaymentFrequency.abbreviation:''
                    
              }
              if(account.Source.Bureau.abbreviation == 'Equifax'){
                    tmp.value.equifax.account_name = account.creditorName
                    tmp.value.equifax.account = account.accountNumber
                    tmp.value.equifax.high_balance = account.highBalance
                    tmp.value.equifax.last_verifed = account.dateVerified ? account.dateVerified  : ''
                    tmp.value.equifax.last_activity = account.dateAccountStatus
                    tmp.value.equifax.date_reported = account.dateReported
                    tmp.value.equifax.date_opened = account.dateOpened
                    tmp.value.equifax.balance_owed = account.currentBalance
                    tmp.value.equifax.closed_date = account.dateClosed ? account.dateClosed : ''
                    tmp.value.equifax.account_rating = account.AccountCondition.abbreviation
                    tmp.value.equifax.account_description = account.AccountDesignator.abbreviation
                    tmp.value.equifax.dispute_status = account.DisputeFlag.abbreviation
                    tmp.value.equifax.creditor_type = account.IndustryCode.abbreviation
                    tmp.value.equifax.account_status = account.OpenClosed.abbreviation
                    tmp.value.equifax.payment_status = account.PayStatus.abbreviation
                     if(account.Remark){
                      if(Array.isArray(account.Remark)){
                        let text = ''
                        account.Remark.forEach(v => {
                          text = text+v.RemarkCode.abbreviation;
                        });
                        tmp.value.equifax.creditor_remark = text
                      }else{
                        tmp.value.equifax.creditor_remark = account.Remark.RemarkCode.abbreviation
                      }
                    }
                    else
                      tmp.value.transunion.creditor_remark = ''
                    tmp.value.equifax.payment_amount = account.GrantedTrade ? account.GrantedTrade.monthlyPayment :''
                    tmp.value.equifax.last_payment = account.GrantedTrade ? account.GrantedTrade.dateLastPayment:''
                    tmp.value.equifax.term_length = account.GrantedTrade ? account.GrantedTrade.termMonths:''
                    tmp.value.equifax.past_due_amount = account.GrantedTrade ? account.GrantedTrade.amountPastDue:''
                    tmp.value.equifax.account_type = account.GrantedTrade ? account.GrantedTrade.AccountType.abbreviation:''
                    tmp.value.equifax.payment_frequency = account.GrantedTrade ? account.GrantedTrade.PaymentFrequency.abbreviation:''
                    
              }
            })
          }else{
            const account = element.Tradeline;
              name = account.creditorName;
              if(account.Source.Bureau.abbreviation == 'TransUnion'){
                    tmp.value.transunion.account_name = account.creditorName
                    tmp.value.transunion.account = account.accountNumber
                    tmp.value.transunion.high_balance = account.highBalance
                    tmp.value.transunion.last_verifed = account.dateVerified ? account.dateVerified  : ''
                    tmp.value.transunion.last_activity = account.dateAccountStatus
                    tmp.value.transunion.date_reported = account.dateReported
                    tmp.value.transunion.date_opened = account.dateOpened
                    tmp.value.transunion.balance_owed = account.currentBalance
                    tmp.value.transunion.closed_date = account.dateClosed ? account.dateClosed : ''
                    tmp.value.transunion.account_rating = account.AccountCondition.abbreviation
                    tmp.value.transunion.account_description = account.AccountDesignator.abbreviation
                    tmp.value.transunion.dispute_status = account.DisputeFlag.abbreviation
                    tmp.value.transunion.creditor_type = account.IndustryCode.abbreviation
                    tmp.value.transunion.account_status = account.OpenClosed.abbreviation
                    tmp.value.transunion.payment_status = account.PayStatus.abbreviation
                     if(account.Remark){
                      if(Array.isArray(account.Remark)){
                        let text = ''
                        account.Remark.forEach(v => {
                          text = text+v.RemarkCode.abbreviation;
                        });
                        tmp.value.transunion.creditor_remark = text
                      }else{
                        tmp.value.transunion.creditor_remark = account.Remark.RemarkCode.abbreviation
                      }
                    }
                    else
                      tmp.value.transunion.creditor_remark = ''
                    tmp.value.transunion.payment_amount = account.GrantedTrade ? account.GrantedTrade.monthlyPayment:''
                    tmp.value.transunion.last_payment = account.GrantedTrade ? account.GrantedTrade.dateLastPayment :''
                    tmp.value.transunion.term_length = account.GrantedTrade ? account.GrantedTrade.termMonths:''
                    tmp.value.transunion.past_due_amount = account.GrantedTrade ? account.GrantedTrade.amountPastDue:''
                    tmp.value.transunion.account_type = account.GrantedTrade ? account.GrantedTrade.AccountType.abbreviation:''
                    tmp.value.transunion.payment_frequency = account.GrantedTrade ? account.GrantedTrade.PaymentFrequency.abbreviation:''
                    
              }
              if(account.Source.Bureau.abbreviation == 'Experian'){
                    tmp.value.experian.account_name = account.creditorName
                    tmp.value.experian.account = account.accountNumber
                    tmp.value.experian.high_balance = account.highBalance
                    tmp.value.experian.last_verifed = account.dateVerified ? account.dateVerified  : ''
                    tmp.value.experian.last_activity = account.dateAccountStatus
                    tmp.value.experian.date_reported = account.dateReported
                    tmp.value.experian.date_opened = account.dateOpened
                    tmp.value.experian.balance_owed = account.currentBalance
                    tmp.value.experian.closed_date = account.dateClosed ? account.dateClosed : ''
                    tmp.value.experian.account_rating = account.AccountCondition.abbreviation
                    tmp.value.experian.account_description = account.AccountDesignator.abbreviation
                    tmp.value.experian.dispute_status = account.DisputeFlag.abbreviation
                    tmp.value.experian.creditor_type = account.IndustryCode.abbreviation
                    tmp.value.experian.account_status = account.OpenClosed.abbreviation
                    tmp.value.experian.payment_status = account.PayStatus.abbreviation
                     if(account.Remark){
                      if(Array.isArray(account.Remark)){
                        let text = ''
                        account.Remark.forEach(v => {
                          text = text+v.RemarkCode.abbreviation;
                        });
                        tmp.value.experian.creditor_remark = text
                      }else{
                        tmp.value.experian.creditor_remark = account.Remark.RemarkCode.abbreviation
                      }
                    }
                    else
                      tmp.value.transunion.creditor_remark = ''
                    tmp.value.experian.payment_amount = account.GrantedTrade ? account.GrantedTrade.monthlyPayment:''
                    tmp.value.experian.last_payment = account.GrantedTrade ? account.GrantedTrade.dateLastPayment:''
                    tmp.value.experian.term_length = account.GrantedTrade ? account.GrantedTrade.termMonths:''
                    tmp.value.experian.past_due_amount = account.GrantedTrade ? account.GrantedTrade.amountPastDue:''
                    tmp.value.experian.account_type = account.GrantedTrade ? account.GrantedTrade.AccountType.abbreviation:''
                    tmp.value.experian.payment_frequency = account.GrantedTrade ? account.GrantedTrade.PaymentFrequency.abbreviation:''
                    
              }
              if(account.Source.Bureau.abbreviation == 'Equifax'){
                    tmp.value.equifax.account_name = account.creditorName
                    tmp.value.equifax.account = account.accountNumber
                    tmp.value.equifax.high_balance = account.highBalance
                    tmp.value.equifax.last_verifed = account.dateVerified ? account.dateVerified  : ''
                    tmp.value.equifax.last_activity = account.dateAccountStatus
                    tmp.value.equifax.date_reported = account.dateReported
                    tmp.value.equifax.date_opened = account.dateOpened
                    tmp.value.equifax.balance_owed = account.currentBalance
                    tmp.value.equifax.closed_date = account.dateClosed ? account.dateClosed : ''
                    tmp.value.equifax.account_rating = account.AccountCondition.abbreviation
                    tmp.value.equifax.account_description = account.AccountDesignator.abbreviation
                    tmp.value.equifax.dispute_status = account.DisputeFlag.abbreviation
                    tmp.value.equifax.creditor_type = account.IndustryCode.abbreviation
                    tmp.value.equifax.account_status = account.OpenClosed.abbreviation
                    tmp.value.equifax.payment_status = account.PayStatus.abbreviation
                     if(account.Remark){
                      if(Array.isArray(account.Remark)){
                        let text = ''
                        account.Remark.forEach(v => {
                          text = text+v.RemarkCode.abbreviation;
                        });
                        tmp.value.equifax.creditor_remark = text
                      }else{
                        tmp.value.equifax.creditor_remark = account.Remark.RemarkCode.abbreviation
                      }
                    }
                    else
                      tmp.value.transunion.creditor_remark = ''
                    tmp.value.equifax.payment_amount = account.GrantedTrade ? account.GrantedTrade.monthlyPayment:''
                    tmp.value.equifax.last_payment = account.GrantedTrade ? account.GrantedTrade.dateLastPayment:''
                    tmp.value.equifax.term_length = account.GrantedTrade ? account.GrantedTrade.termMonths:''
                    tmp.value.equifax.past_due_amount = account.GrantedTrade ? account.GrantedTrade.amountPastDue:''
                    tmp.value.equifax.account_type = account.GrantedTrade ? account.GrantedTrade.AccountType.abbreviation:''
                    tmp.value.equifax.payment_frequency = account.GrantedTrade ? account.GrantedTrade.PaymentFrequency.abbreviation:''
                    
              }
            
          }
          
          tmp.title =  name;
          console.log(tmp);
          self.FinalData.account_history.push(tmp);
          self.userFeedback.account_history.push({
                      status : {
                        transunion : 'Positive',
                        experian : 'Positive',
                        equifax : 'Positive'
                      },
                      reason : '',
                      creditor : tmp.title,
                      instruction : '',
                      new_reason: false,
                      new_instruction: false,
                      account  : {
                        transunion : tmp.value.transunion.account,
                        experian : tmp.value.experian.account,
                        equifax : tmp.value.equifax.account
                      },
                    });
          
        })
      //  self.FinalData.account_history.forEach((element,key) => {
      //    console.log(key);
      //    self.tableExpand(3,key);
      //  })
      
      },
      checkAllFunc(){
        if(this.checkAll == 'all'){
          this.FinalData.credit_inquiries.forEach(val => {
            if(val.transunion)
              this.userFeedback.inquiry.check.push(val.title+',transunion,'+val.date)
            if(val.experian)
              this.userFeedback.inquiry.check.push(val.title+',experian,'+val.date)
            if(val.equifax)
              this.userFeedback.inquiry.check.push(val.title+',equifax,'+val.date)
          })
        }else{
          this.userFeedback.inquiry.check= []
        }
      },
    gotoWizard(){
      let flag = 0;
      let id = '';
      if(this.userFeedback.inquiry.check.length != 0){
        if(this.userFeedback.inquiry.instruction == '' || this.userFeedback.inquiry.reason == ''){
          id = 'inquiry_div';
          flag = 1;
        }
      }

      this.userFeedback.public_record.forEach((element,key) => {
        if((element.status.equifax == 'Negative' || element.status.experian == 'Negative' || element.status.transunion == 'Negative') && (element.reason == '' || element.instruction == '')){
          id = 'public_record_div_'+key;
          flag = 1;
        }
      });
      this.userFeedback.account_history.forEach((element,key) => {
        if((element.status.equifax == 'Negative' || element.status.experian == 'Negative' || element.status.transunion == 'Negative') && (element.reason == '' || element.instruction == '')){
          id = 'account_history_div_'+key;
          flag = 1;
        }
      });



      if(flag == 1){
        Vue.$toast.error('Add Reason/Instruction to continue', {
            position: 'top-right'
            });
          this.$vuetify.goTo('#'+id, {
              duration: 1000,
              offset: 0,
              easing: 'easeInOutCubic',
            });
      }else{
        localStorage.setItem('credit-report-saved-items',JSON.stringify(this.userFeedback))
        localStorage.setItem('selected_client',this.client_id)
        if(this.role.name == 'client')
          this.$router.push('/credit-report-wizard/'+this.$route.params.id);
        else
          this.$router.push('/admin/credit-report-wizard/'+this.$route.params.id);
      }
    },
    getUserProfile() {
    let flag = 1;
    let self = this;
    self.overlay = true;
    let id  = this.$route.params.id;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-credit-report/"+id,header)
        .then(response => {

              this.user = JSON.parse(response.data.value.report);
              this.client_id = response.data.value.user_id
              this.assignData();
              this.overlay = false;
         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },




    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.user.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.user.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }

        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/updateprofile",
          data: self.user
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Profile updated', {
                    position: 'top-right'
                  })
                  //this.$router.push('company-profiles')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },

created() {
      console.log('created called.');
      this.getUserProfile();
    },

}
</script>
<style >
.heading-h2 {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 38px;
  letter-spacing: normal;
  color: #4a4a4a;
}
.v-input__slot {
  align-items: center;
  justify-content: center;
}
.checkbox-credit .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  left: 0px;
  right: auto;
  position: relative;
  text-align: center!important;
  display: contents!important;
}
.red--text .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  color: red;
}

</style>

<style type="text/css">
@keyframes ldio-7xwhhkt0pnf {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-7xwhhkt0pnf div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-7xwhhkt0pnf linear 1s infinite;
  background: #ffffff;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}.ldio-7xwhhkt0pnf div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #ffffff;
}
.loadingio-spinner-spinner-2k3nmd4xown {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-7xwhhkt0pnf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-7xwhhkt0pnf div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>