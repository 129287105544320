<template>
<v-app id="inspire">
<vue-header-admin></vue-header-admin>
<v-main class="mt-5">
<iframe
id="JotFormIFrame-90142788412154"
title="Trainee Registration Form"
onload="window.parent.scrollTo(0,0)"
allowtransparency="true"
allowfullscreen="true"
allow="geolocation; microphone; camera"
src="https://phplaravel-258962-3000075.cloudwaysapps.com/miniProdigy"
frameborder="0"
style="width: 1px;
min-width: 100%;
height:100%;
border:none;"
scrolling="yes"
></iframe>
</v-main>
</v-app>

</template>

<script>

export default {
components: {

  },
  data () {

      return {
          src: '',
      }
    },
    
    methods: {
      

    getLink() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/custom-link/"+this.$route.params.id,header)
        .then(response => {
            let data = response.data;
            this.src = data.data[0].url
           console.log(this.src);

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },




    

 },

created() {
      console.log('created called.');
    },


}
</script>

