<template>
 <v-app id="inspire" >

    <div style=" justify-content: space-around" class="mt-3 mb-1">
        <v-row class="pl-5 pr-5" style="background:#F7F7F7;">
        <v-col cols="12" align="center" justify="center" >
                <img :height="this.$vuetify.breakpoint.xs ? 100 : 150" class="image-logo2" src="/assets/images/logo.png">
        </v-col>
        </v-row>
        <v-row align="center" justify="center" class="mt-10">

        <v-col cols="11"  sm="10" md="10" lg="8" xs="10">
            <div class="mt-10"  >
            <h3 class=".font-weight-black mt-10" style="color:#5D6167;" >General information</h3>
          </div>
          <v-row align="center" justify="center" class="mt-10">
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;">
                <span style="color:#5D6167;">First Name<span class="required_span">*</span></span>
                <v-text-field
                v-model="partner.first_name"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Last Name<span class="required_span">*</span></span>
                <v-text-field
                v-model="partner.last_name"
                outlined
          ></v-text-field>
            </v-col>
             <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Email<span class="required_span">*</span></span>
                <v-text-field
                :disabled="partner.is_email"
                v-model="partner.email"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Phone (Mobile)<span class="required_span">*</span></span>
                <v-text-field
                v-model="partner.phone_m"
                v-mask="'(###) ###-####'"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="12" md="12" lg="12" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Address<span class="required_span">*</span></span>
                <vuetify-google-autocomplete
                        id="map"
                        v-model="address"
                        placeholder="Enter a location"
                        outlined
                        v-on:placechanged="getAddressData"
                        country="us"
                    ></vuetify-google-autocomplete>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">City</span>
                <v-text-field
                v-model="partner.city"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">State</span>
                <v-select
                :items="stateOptions"
                item-text="name"
                item-value="abbreviation"
                placeholder="Select State"
                v-model="partner.state"
                outlined
          ></v-select>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Zip Code</span>
                <v-text-field
                v-model="partner.zip"
                outlined
          ></v-text-field>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Process</span>
                 <v-select
                            outlined
                            v-model="partner.process"
                            :items="itemOptions"
                            ></v-select>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Round</span>
                 <v-select
                            outlined
                            v-model="partner.round"
                            :items="[1,2,3,4]"
                            ></v-select>
            </v-col>
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" ></v-col>
            <!-- <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                <span style="color:#5D6167;">Attachment</span>
                <v-file-input
                outlined
                    v-model="partner.document"
                    accept="image/*"
                    :prepend-icon="false"
                    label="File input"
                ></v-file-input>
            </v-col> -->
            
            <v-col cols="12"  sm="12" md="6" lg="6" style="margin-top:-25px;" >
                <vue-recaptcha
                    :sitekey="sitekey"
                    :loadRecaptchaScript="true"
                    ref="recaptcha"
                    type="invisible"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                >
            </vue-recaptcha>
            </v-col>
            <v-col cols="12"  sm="12" md="6" lg="6" style="margin-top:-25px;" ></v-col>
            <v-col cols="12"  sm="12" md="4" lg="4" style="margin-top:-35px;" ></v-col>
            <v-col cols="12"  sm="12" md="4" lg="4" >
                <v-btn
                    :disabled="isDisable"
                    color="#00aeef"
                    block
                    style="height: 53px;
                        padding: 0 40px;
                        margin-bottom: 50px;" 
                        @click="checkForm"
                        :loading="loading"
                    :dark="isDark"
                >
                    SUBMIT
                </v-btn>
            </v-col>
            <v-col cols="12"  sm="12" md="4" lg="4" ></v-col>
          </v-row>
        </v-col>
        </v-row>
        <v-row class="pl-5 pr-5 " style="background:#3F3F3F;">
        <v-col cols="12" align="center" justify="center" >
                <p style="font-size: 18px;
            color: white;
            margin-top:10px;
            line-height: 150%;" ><v-icon style="font-size: 18px;color: white;margin-top:-5px">mdi-lock</v-icon> <span>Secure Area</span> | This website is protected by 256-bit SSL security.<br>
            @ 2021 iDealergy. All rights reserved. Patent Pending.
          </p>
        </v-col>
        </v-row>
    </div>
 </v-app>
</template>

<script>
import DateDropdown from 'vue-date-dropdown'
import VueRecaptcha from 'vue-recaptcha'
import moment from 'moment'
export default {
    components: {
        DateDropdown,
        'vue-recaptcha' : VueRecaptcha
    },
    data()  {
        return {
            address: '',
            partner : {
                first_name : "",
                last_name : '',
                middle_name: '',
                email : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                dob: '01-01-1950',
                ssn : '',
                phone_m : '',
                fax : '',
                address :'',
                address1: '',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: "",
                confirm_password: "",
                crc_id : "",
                process: "609",
                round: 1,

            },
            isDisable :false,
            itemOptions: ['609','Inquires','609 and Inquires','Bankrupcy','Identity Thieft'],
            loading: false,
            isDark: true,
            stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            year : new Date().getFullYear(),
            form: {
            // Some form values...
                recaptcha: '',
            },
            sitekey: process.env.MIX_RECAPTCHA_SITE_KEY
        }
    },
    created () {
    },
    beforeCreate: function () {
        if (localStorage.getItem('token')) {
            if(localStorage.getItem('is_admin'))
                this.$router.push('/admin/dashboard')
            else
                this.$router.push('/dashboard')
        }
    },
    methods : {
        onCaptchaVerified: function (recaptchaToken) {
        this.partner.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.partner.address = addressData.name;
            this.partner.city = addressData.locality;
            this.partner.state = addressData.administrative_area_level_1;
            this.partner.zip = addressData.postal_code
            console.log(this.partner);
        },
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        checkForm(){
            let self = this;
            let flag = 1;
            if (!this.partner.first_name) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.email && this.partner.is_email == false && flag == 1) {
                Vue.$toast.error('Email is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(this.partner.email) == false && this.partner.is_email == false  && flag == 1) {
                Vue.$toast.error('Invalid email format', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            if (!this.partner.phone_m && this.partner.phone_m == false && flag == 1) {
                Vue.$toast.error('Phone (Mobile) is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            if (!this.partner.address && this.partner.address == false  && !this.partner.city && this.partner.city == false   && !this.partner.state && this.partner.state == false  && !this.partner.zip && this.partner.zip == false  &&  flag == 1) {
                Vue.$toast.error('Address is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (flag == 1) {
                
                let old_date = this.partner.dob.split(".")
                this.partner.dob = old_date[1] + '/' + old_date[0] + '/' + old_date[2];
                this.isDark = false;
                this.isDisable = true;
                axios({
                method: "POST",
                 
                url: "/api/auth/register-test",
                data:self.partner,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.success != "False"){
                        // self.$router.push('/thanks');
                        Vue.$toast.success('Request submitted', {
                            position: 'top-right'
                            }); 
                            this.isDark = true;
                            this.isDisable = false;
                            self.onCaptchaExpired()
                        // location.reload();
                    }else{
                        this.isDark = true;
                        this.isDisable = false;
                        Vue.$toast.error(response.data.result.errors.error_message, {
                            position: 'top-right'
                            }); 
                    }
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                    if(data.email){
                        Vue.$toast.error(data.email[0], {
                        position: 'top-right'
                        });
                    }else if(data.password){
                        Vue.$toast.error(data.password[0], {
                        position: 'top-right'
                        });
                    }
                    else if(data.user_name){
                        Vue.$toast.error(data.user_name[0], {
                        position: 'top-right'
                        });
                    }
                    else{
                        Vue.$toast.error(data, {
                        position: 'top-right'
                        });
                    }
                    self.onCaptchaExpired()

                });

            }
        }
    }
}
</script>

<style>
/* .image-logo2{
position: absolute;
margin: -78px -5px -4px 170px;
} */
.date-dropdown-container{
    height: 85px;
}
.date-dropdown-select{
    border: 1px solid #9E9E9E!important;
    padding: 15px!important;
}
.required_span{
    color:red;
}
</style>