<template>
 <v-app id="inspire" style="background-color: white;">

    <div style=" justify-content: space-around" class="mt-5 mb-1">
        <v-row class="pl-5 pr-5 mt-5" >
        <v-col v-if="loadingFlag" cols="12" align="center" justify="center" style="margin-bottom:-10px;">
                <img v-if="!company" @click="$router.push('/')" :height="this.$vuetify.breakpoint.xs ? 100 : 200" class="image-logo2" src="@/assets/img/logo.png">
                <img v-if="company && company.logo" @click="$router.push('/')" :height="this.$vuetify.breakpoint.xs ? 80 : 300" class="image-logo2" :src="base_url_img+company.logo">
        </v-col>
        </v-row>
        
<v-row class="" v-if="!formSubmitFlag">
      <v-col lg="8" cols="12" class="mx-auto my-auto ">
          <p class="text-h6 text-center" style="color: gray;padding:10px;">
            Our Mission To bring business cards into the 21st century by building direct connections for brands and consumers. Helping people grow is an attitude that runs deep through our company culture. We’re not just a digital marketing and branding company, We're a partner that helps you focus on what you do best: building, producing, creating, designing, selling and succeeding.
          </p>
            <v-container>
            <div class="p-5">
              <v-form ref="form1">
                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        
                        <v-text-field
                          label="First Name*"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          :rules="rules"
                          v-model="partner.first_name"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Last Name*"
                          color="#e91e63"
                          outlined
                          dense
                          required
                          :rules="rules"
                          v-model="partner.last_name"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Email Address*"
                          color="#e91e63"
                          outlined
                          dense
                          required
                          :rules="emailRules"
                          v-model="partner.email"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        label="Phone*"
                        color="#e91e63"
                        :rules="rules"
                        outlined
                          dense
                                v-mask="'(###) ###-####'"
                                class="font-size-input input-style"
                                v-model="partner.phone"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                     <v-row class="mt-2">
                      
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Business Name"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          v-model="partner.business_name"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <vuetify-google-autocomplete
                                  id="map"
                                  outlined
                                  dense
                                  v-model="partner.business_address"
                                  placeholder="Enter a location"
                                  class="font-size-input input-style"
                                  label="Address"
                                  v-on:placechanged="getAddressData"
                                  country="us"
                              ></vuetify-google-autocomplete>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Instagram"
                          color="#e91e63"
                          required
                          outlined
                          prefix="http://instagram.com/"
                          dense
                          v-model="partner.instagram"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Facebook"
                          prefix=" http://www.facebook.com/profile.php?id="
                          color="#e91e63"
                          required
                          outlined
                          dense
                          v-model="partner.facebook"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Youtube Channel"
                          prefix="http://www.youtube.com/user/"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          v-model="partner.youtube"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="LinkedIn"
                          prefix="http://www.linkedin.com/in/"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          v-model="partner.linkedin"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                     

                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Twitter"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix="http://twitter.com/"
                          v-model="partner.twitter"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Pinterest"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix="http://pinterest.com/"
                          v-model="partner.pinterest"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="TikTok"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix="http://www.tiktok.com/@"
                          v-model="partner.tiktok"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Reddit"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix="http://www.reddit.com/user/"
                          v-model="partner.reddit"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Tumblr"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix=" http://tumblr.com/"
                          v-model="partner.tumblr"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="flickr"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix="http://www.flickr.com/photos/"
                          v-model="partner.flickr"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Vine"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix="http://vine.co/"
                          v-model="partner.vine"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Medium"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix=" http://medium.com/@"
                          v-model="partner.medium"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="SoundCloud"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix="http://soundcloud.com/"
                          v-model="partner.soundcloud"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Twitch"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix=" http://www.twitch.tv/"
                          v-model="partner.twitch"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Clubhouse"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          placeholder="Username"
                          prefix="https://www.joinclubhouse.com/@"
                          v-model="partner.clubhouse"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Website"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          v-model="partner.website"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>

                    </v-row>
                  <div class="text-center">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      :loading="loadingSaveButton"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-success
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                      "
                      color="primary"
                      @click="$refs.form1.validate() ? checkForm() : e1 = 1"
                    >
                      Submit your details
                    </v-btn>
                  </div>   
                  </v-form>
                  </div>    
                  </v-container>       
      </v-col>
    </v-row>
    <v-row class="" v-else>
      <v-col lg="8" cols="12" class="mx-auto my-auto ">
        <div class="text-center">
          <v-icon>mdi-arrow-left</v-icon>
        <i class="fa fa-check-circle text-center mb-10 mt-5" id="checkmark" style="color:#24b663;font-size: 70px;"></i>
        </div>
        <header class="site-header" id="header">
            <h1 class="text-h1 text-center bold" data-lead-id="site-header-title">Thank you!</h1>
          </header>

          <div class="main-content text-center">
            
            <p class="main-content__body text-h5 text-center text-grey mt-5" data-lead-id="main-content-body" style="color:grey;">Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you.</p>
          </div>

        </v-col>
        </v-row>
    <v-dialog
      v-model="showDialog"
      width="700"
    >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
          Info
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <p>
            It's quite a daunting task answering the those grants by yourself and all the other agencies back and forth. So we make it easier for you.
            </p><p>
            While you're out taking care of business we're helping you take care of business.
            </p><p>

            To do this for you we need a process email for the task that we are going to do on your behalf.
            </p><p>

            All you have to do is make a process email so that we can check it and respond on your behalf.
            </p><p>

            To make a process email here is an Example. My last name and random numbers @gmail.com  like this —>(doe74564@gmail.com)
            </p><p>
            <a href="https://accounts.google.com/signup/v2/webcreateaccount?hl=en&amp;flowName=GlifWebSignIn&amp;flowEntry=SignUp" target="_blank" rel="nofollow">(CLICK HERE)</a> Once you get signed up enter login in information below.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="green"
            outlined
            :disabled="loadingAssign"
            @click="showDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    </div>
 </v-app>
</template>

<script>
import DateDropdown from 'vue-date-dropdown'
import VueRecaptcha from 'vue-recaptcha'
import moment from 'moment'
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
// import Vue from 'vue'
export default {
    components: {
        DateDropdown,
        'vue-recaptcha' : VueRecaptcha,
        'RotateLoader': ClipLoader
    },
    data()  {
        return {
            address: '',
            dob:'',
            age: 0,
            e1 : 1,
            loadingSaveButton : false,
            verifyLoad: false,
            verfiedFlag : false,
            formSubmitFlag : false,
            verifyUser: '',
            showDialog :false,
            showconfpassword : false,
            agree : false,
            base_url_img : process.env.VUE_APP_WASBI_URL,
            showpassword : false,
            rules: [
                value => !!value || 'Required.',
            ],
            temp_email  : localStorage.getItem('temp_email') ? true : false,
            validGeneral : true,
            validAddress : true,
            validSocial : true,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            loadingFlag : false,
            grant : {},
            partner : {
                first_name : "",
                last_name : '',
                middle_name: '',
                email : localStorage.getItem('temp_email') ? localStorage.getItem('temp_email') : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                dob: '',
                is_exist : "",
                ssn : '',
                phone : '',
                fax : '',
                business_address :'',
                address1: '',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                is_verified:  0,
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: '',
                confirm_password: "",
                crc_id : "",
                driving_license_number : '',
                driving_license_state : "",
                process_email : "",
                process_email_password : "",
                is_grant :'no',
                business_name : '',
                instagram : "",
                facebook : "",
                youtube :"",
                linkedin : "",
                website : "",
                twitter : "",
                pinterest: "",
                tiktok: "",
                snapchat: "",
                reddit: "",
                tumblr: "",
                flickr: "",
                vine: "",
                medium: "",
                soundcloud: "",
                twitch: "",
                clubhouse: ""


            },
                url : '',
                company : '',
            isDisable :false,
            loading: false,
            isDark: true,
            existOption: ["Yes","No"],
            stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            year : new Date().getFullYear(),
            form: {
            // Some form values...
                recaptcha: '',
            },
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
        }
    },
    created () {
        this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';
        if(this.url)
            this.getCompanyInfo(this.url)
        else
            this.loadingFlag = true;
    },
    watch: {
      dob (val) {
        this.age = val ?  moment().diff(this.dob, 'years') :0
        console.log(this.age);
        if(isNaN(this.age))
          this.age = 0;
      },
    },
    beforeCreate: function () {
        
    },
    methods : {
      gotoPrivacy(){
        window.open('/privacy/'+this.$route.params.url, "_blank");
      },
        getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            console.log(response.data.company);
            this.company = response.data.company;
            this.loadingFlag = true;
          })

},
        onCaptchaVerified: function (recaptchaToken) {
        this.partner.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        },
         getAddressData3: function (addressData3, placeResultData, id) {
            this.grant.grant_address = addressData3.name;
        },
        getAddressData2: function (addressData2, placeResultData, id) {
            this.grant.grant_business_address = addressData2.name;
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.partner.address = addressData.name;
            this.partner.city = addressData.locality;
            this.partner.state = addressData.administrative_area_level_1;
            this.partner.zip = addressData.postal_code
            console.log(this.partner);
        },
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        verifyCrc(){
            let self = this;
            let flag = 1;
            if (!this.partner.crc_id) {
                Vue.$toast.error('Customer ID is required', {
                    position: 'top-right'
                });
                flag = 0;
            }else{
                self.verifyLoad = true;
                axios({
                method: "POST",
                 
                url: "/api/auth/verify-crc",
                data:{crc_id : self.partner.crc_id},
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.data.success == 'True'){
                        self.verifyUser = response.data.data.result;
                        self.verfiedFlag = true;
                    }else{
                        Vue.$toast.error('Invalid customer id', {
                            position: 'top-right'
                        });
                    }
                    self.verifyLoad = false
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    self.verifyLoad = flase;

                });
            }
        },
        autofill(){
            let self = this;
            self.partner.first_name = self.verifyUser.firstname
            self.partner.last_name = self.verifyUser.lastname
            if(!self.temp_email)
                self.partner.email = self.verifyUser.email
            self.partner.middle_name = self.verifyUser.middlename
            self.partner.phone_m = self.verifyUser.phone_mobile
            self.partner.phone_h = self.verifyUser.phone_home
            self.partner.phone_w = self.verifyUser.phone_work
            self.partner.ssn = self.verifyUser.ssno
            self.address = self.verifyUser.street_address
            if(self.verifyUser.state){
                self.stateOptions.forEach(element => {
                    if(self.verifyUser.state.toUpperCase()  == element.name)
                        self.partner.state = element.abbreviation
                });
            }
            self.partner.zip = self.verifyUser.zip
            self.partner.city = self.verifyUser.city
            if(self.verifyUser.birth_date){
                var temp_dob = self.verifyUser.birth_date.split("/");
                self.partner.dob = temp_dob[1]+'.'+temp_dob[0]+'.'+temp_dob[2]
                console.log(self.partner.dob);
            }
            
        },
        checkForm(){
            let self = this;
            let flag = 1;       
            console.log(this.partner);     
            if (!this.partner.first_name) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.email && this.partner.is_email == false && flag == 1) {
                Vue.$toast.error('Email is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.phone && flag == 1) {
                Vue.$toast.error('Phone is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
           
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(this.partner.email) == false && this.partner.is_email == false  && flag == 1) {
                Vue.$toast.error('Invalid email format', {
                    position: 'top-right'
                });
                flag = 0;
            }

            
            this.partner.user_name = this.partner.email;
            
            if (flag == 1) {
                this.loadingSaveButton = true;
                let is_verified = localStorage.getItem('email_verified') ? localStorage.getItem('email_verified') : 0
                this.partner.is_verified = is_verified;
                this.partner.company_id = this.company ? this.company.id : '';
                this.isDark = false;
                this.isDisable = true;
                let req = this.partner;

                // req.instagram = "http://instagram.com/"+ req.instagram
                // req.facebook = "http://www.facebook.com/profile.php?id=" + req.facebook
                // req.youtube = "http://www.youtube.com/user/"+ req.youtube
                // req.linkedin = "http://www.linkedin.com/in/"+ req.linkedin
                // req.website = "http://instagram.com/"+ req.instagram
                // req.twitter = "http://twitter.com/"+ req.twitter
                // req.pinterest= "http://pinterest.com/"+ req.pinterest
                // req.tiktok= "http://www.tiktok.com/@"+ req.tiktok
                // req.snapchat=  "http://snapchat.com/add/"+ req.snapchat
                // req.reddit= "http://www.reddit.com/user/"+ req.reddit
                // req.tumblr= "http://{username}.tumblr.com/"+ req.tumblr
                // req.flickr= "http://www.flickr.com/photos/"+ req.flickr
                // req.vine= "http://vine.co/"+ req.vine
                // req.medium= "http://medium.com/@"+ req.medium
                // req.soundcloud= "http://soundcloud.com/"+ req.soundcloud
                // req.twitch= "http://www.twitch.tv/"+ req.twitch
                // req.clubhouse= " https://www.joinclubhouse.com/@"+ req.clubhouse
                axios({
                method: "POST",
                url: "/api/auth/add-contact-outsource",
                data: req,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                        let result = response.data.data;
                        this.formSubmitFlag = true;
                        this.isDark = true;
                        this.loadingSaveButton = false;
                        this.isDisable = false;                    
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    this.loadingSaveButton = false;
                    if(err.response.data.flag){
                        if(err.response.data.flag == 2){
                            Vue.$toast.error('Company exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                        if(err.response.data.flag == 3){
                          Vue.$toast.error('Client exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                    }else{
                      let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                      if(data.email){
                          Vue.$toast.error(data.email[0], {
                          position: 'top-right'
                          });
                      }else if(data.password){
                          Vue.$toast.error(data.password[0], {
                          position: 'top-right'
                          });
                      }
                      else if(data.user_name){
                          Vue.$toast.error(data.user_name[0], {
                          position: 'top-right'
                          });
                      }
                      else{
                          Vue.$toast.error(data, {
                          position: 'top-right'
                          });
                      }
                  }
                    self.onCaptchaExpired()

                });

            }
        }
    }
}
</script>

<style>
/* .image-logo2{
position: absolute;
margin: -78px -5px -4px 170px;
} */
.date-dropdown-container{
    height: 85px;
}
.date-dropdown-select{
    border: 1px solid #9E9E9E!important;
    padding: 15px!important;
}
.required_span{
    color:red;
}

</style>