<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6" >
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;">
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Video Detail</h5>
                    </v-col>
                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        <p>First Name: <strong>{{ company.first_name }}</strong></p>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <p>Last Name: <strong>{{ company.last_name }}</strong></p>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <p>Email: <strong>{{ company.email }}</strong></p>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <p>Phone: <strong>{{ company.phone }}</strong></p>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <p>10k Card Link: <strong>{{ company.card_link }}</strong></p>
                      </v-col>
                      <v-col sm="12" cols="12" class="py-0 mb-5">
                        <v-divider ></v-divider>
                      </v-col>
                    </v-row>
                     <v-row class="mt-2 mb-2" v-for="(item,key) in JSON.parse(company.video)" :key="item">
                      <v-col sm="3" cols="12" class="py-0">
                        <p style="color:black;" class="text-h5">{{key + 1}}. {{ item.title }} ({{ item.type }})</p>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <a class="text-h5" style="color:#1b66c9" v-if="item.type=='URL'" :href="item.url" target="_blank">{{ item.url }}</a>
                        <a class="text-h5" style="color:#1b66c9" v-else :href="video_url+'videos/'+item.file" target="_blank">{{ item.file }}</a>
                      </v-col>
                      <v-col sm="3" cols="12" class="py-0">
                        <span >Vimeo source code <span v-if="!item.vimeo" style="color:red">(N/A)</span><v-btn
                            class="mx-2"
                            text
                            v-if="item.vimeo"
                            dark
                            @click="copyVimeo(item.vimeo,item.title)"
                            x-small
                            color="#26B9F1"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              play_circle
                            </v-icon>
                          </v-btn></span>
                      </v-col>
                      </v-row>
                      <v-row>
                    <v-col
                    cols="12"
                    md="12"
                    >

                 <v-card-actions class="justify-center ">
                  <v-row>
                    <v-col>
                      <v-btn
                        color="blue"
                        large
                        :loading="loadingVimeo"
                        @click="generateVimeoLinks()"
                        class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          ms-auto
                        "
                        
                      >
                        generate Vimeo Links
                      </v-btn>
                      <v-btn
                        color="grey"
                        large
                        @click="$router.push('/admin/video-links')"
                        class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          mr-5
                          ms-auto
                        "
                        
                      >
                        Back
                      </v-btn>
                      </v-col>
                    </v-row>
                </v-card-actions>
                    </v-col>

                    
                  </v-row>
                </v-card-text>
                <!-- <v-card-actions class="justify-center ">
                  <v-btn
                    tile  dark
                    style="height: 49px;width:95px;"
                    color="primary"
                    class="mb-10"
                    @click="saveProfile"
                  >Save</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
    </v-container>
    
     <vue-footer-admin></vue-footer-admin>
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        loadingVimeo : false,
        name:'',
        phone:'',
        rules: [
                value => !!value || 'Required.',
            ],
        loading : false,
        password : '',
        is_admin:0,
        confpassword : '',
        role : '',
        video_url: process.env.VUE_APP_WASBI_URL,
        companyname : '',
        sector : '',
        no_of_employee : '',
        showPassword : false,
        phoneFlag:0,
        emailFlag:0,
        country :'',
        otp_email:'',
        otp_phone:'',
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
        company : {
                id: '',
                first_name : "",
                last_name : '',
                email :'',
                phone : '',
                business_address :'',
                business_name : '',
                instagram : "",
                facebook : "",
                youtube :"",
                linkedin : "",
                website : "",
                twitter : "",
                pinterest: "",
                tiktok: "",
                snapchat: "",
                reddit: "",
                tumblr: "",
                flickr: "",
                vine: "",
                medium: "",
                soundcloud: "",
                twitch: "",
                clubhouse: ""


        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },
  watch: {
            company: {
                handler () {
                    this.company.company_url = this.company.name.replace(/\s/g, '-').toLowerCase()
                },
                deep: true,
            },
        },
    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
    async copyVimeo(id,title){
      let link = id.split("/");
      let mytext = '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/'+link[0]+'?h='+link[1]+'&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;  height:100%;" title="'+title+'"></iframe></div><scri'+'pt src="https://player.vimeo.com/api/player.js"></scri'+'pt>' 
      
      try {
          await navigator.clipboard.writeText(mytext);
          alert('Copied');
        } catch($e) {
          alert('Cannot copy');
        }
    },
    verify(){
    let self = this;
    let flag = 1;
    if (!self.email && self.emailFlag ==1) {
    Vue.$toast.error('Email '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['email'].focus()
    flag = 0;
    }
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (reg.test(self.email) == false && flag == 1 && self.emailFlag == 1) {
        Vue.$toast.error('Invalid Email format', {
        position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
    }
    if (!self.phone && flag == 1 && self.phoneFlag == 1) {
    Vue.$toast.error('Phone Number '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['phone'].focus()
    flag = 0;
    }

    if (flag == 1) {
    var email = this.email;
    axios({
        method: "POST",
         
        url: "/api/auth/send_otp",
        data: {
        flag : self.phoneFlag == 1 ? 'phone' : 'email',
        email: self.email,
        phone:  self.phone,
        code: self.country,
        logged_flag : 1
        }
    })
        .then(response => {
        let data = response.data;
        if (data.data.flag == 1) {
            if(self.phoneFlag == 1 ){
                self.otp_phone = data.data.otp;
                self.phoneFlag = 2;
            }
            else{
                self.otp_email = data.data.otp;
                self.emailFlag = 2;
            }
            Vue.$toast.success(data.message, {
                position: 'top-right'
            });

        } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
        })
        .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
        });
      }
    },
    verifyOTP(){
        if(this.phoneFlag ==2){
            if(! this.otp_phone_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_phone == this.otp_phone_input){
                    axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'phone',
                            phone: this.phone,
                            code: this.country
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                this.getUserProfile()
                                this.phoneFlag = 3;
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });


                }else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_phone_input ="";
                    this.$refs['phone_otp'].focus()
                }
            }

        }else{
            if(! this.otp_email_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_email == this.otp_email_input){
                     axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'email',
                            email: this.email,
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                //this.$router.push("/home");
                                this.emailFlag = 3;
                                this.getUserProfile()
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });

                } else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_email_input ="";
                    this.$refs['email_otp'].focus()
                }
            }
        }
    },
    getAddressData: function (addressData, placeResultData, id) {
            this.company.address = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['password'].focus()
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['confpassword'].focus()
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success(this.$t('my_profile.profile_alert_password_change_successfull'), {
                position: 'top-right'
                });

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },

    add: function () {
         // for setting tab
    },

    getUserProfile() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-video-details/"+this.$route.params.id,header)
        .then(response => {
            let data = response.data;

           this.company = data.data;
            

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     generateVimeoLinks() {
    let flag = 1;
    if(confirm('Are you sure, You want to generate vimeo links?')){
    this.loadingVimeo = true
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/generate-vimeo-links/"+this.$route.params.id,header)
        .then(response => {
            let data = response.data;
            Vue.$toast.success('Vimeo links generated', {
                    position: 'top-right'
                  })
           this.getUserProfile();
           this.loadingVimeo = false

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
            this.loadingVimeo = false

           });
        }

     },




    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.company.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.phone && flag == 1) {
            Vue.$toast.error('Phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        
     
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.company.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
       
        if (flag == 1) {
          self.loading = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-contact-save",
          data: self.company
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Contact added', {
                    position: 'top-right'
                  })
                  self.loading = false;
                  this.$router.push('/admin/contacts')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
                self.loading = false;
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });     
              if(err.response.data.errors.company_url)
                Vue.$toast.error(err.response.data.errors.company_url[0], {
                position: 'top-right'
                });             
          });
        }
    },

 },

created() {
      console.log('created called.');
      if(this.$route.params.id)
        this.getUserProfile();
    },

}
</script>

