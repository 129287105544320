<template>
  <v-app id="inspire" style="background:#F9F9F9;">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid>
        <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12" md="12">
                <v-card-text style="margin-bottom: -30px;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h1 class=" mb-5" style="color: rgba(0,0,0,.6);">My Cart</h1>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Item Description
                                </th>
                                <th class="text-left">
                                    Unit Price
                                </th>
                                <th class="text-left">
                                    Quantity
                                </th>
                                <th class="text-left">
                                    Subtotal
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in cartList"
                                :key="item.id"
                                >
                                <td>
                                    <v-list-item-title class="headline mb-1" style="color: rgba(0,0,0,.6);font-size:20px !important;">
                                    Job ID: {{ item.id }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle><span style="color: rgba(0,0,0,.6);">Mailing List -</span> {{item.addressList}}</v-list-item-subtitle>
                                    <v-list-item-subtitle><span style="color: rgba(0,0,0,.6);">Document Name -</span> {{item.document}}</v-list-item-subtitle>
                                    <v-list-item-subtitle><span style="color: rgba(0,0,0,.6);">Date of Mailing -</span> <b>{{formatDate(item.mailingDate)}}</b></v-list-item-subtitle> 
                                    <v-list-item-subtitle class="mt-3"><h3>{{item.envelope}}</h3></v-list-item-subtitle>
                                    <v-list-item-subtitle class="mt-3 mb-3"><h3>Certified Mail Unsorted letter</h3></v-list-item-subtitle>
                                    
                                    
                                </td>
                                <td><v-list-item-title class="headline mb-1" style="color: rgba(0,0,0,.6);font-size:20px !important;">&nbsp;
                                    </v-list-item-title>
                                    <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
                                    <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
                                    <v-list-item-subtitle>&nbsp;</v-list-item-subtitle> 
                                    <v-list-item-subtitle class="mt-3"><h3>$1.760</h3></v-list-item-subtitle>
                                    <v-list-item-subtitle class="mt-3 mb-3"><h3>$4.150</h3></v-list-item-subtitle>
                                </td>
                                <td><v-list-item-title class="headline mb-1" style="color: rgba(0,0,0,.6);font-size:20px !important;">&nbsp;
                                    </v-list-item-title>
                                    <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
                                    <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
                                    <v-list-item-subtitle>&nbsp;</v-list-item-subtitle> 
                                    <v-list-item-subtitle class="mt-3"><h3>1</h3></v-list-item-subtitle>
                                    <v-list-item-subtitle class="mt-3 mb-3"><h3>1</h3></v-list-item-subtitle>
                                </td>
                                <td><v-list-item-title class="headline mb-1" style="color: rgba(0,0,0,.6);font-size:20px !important;">&nbsp;
                                    </v-list-item-title>
                                    <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
                                    <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
                                    <v-list-item-subtitle>&nbsp;</v-list-item-subtitle> 
                                    <v-list-item-subtitle class="mt-3"><h3>$1.76</h3></v-list-item-subtitle>
                                    <v-list-item-subtitle class="mt-3 mb-3"><h3>$4.15</h3></v-list-item-subtitle>
                                </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                        <div style="float:right;">
                        <v-list-item-subtitle class="mt-3">Subtotal: ${{subtotal}}</v-list-item-subtitle>
                        <v-list-item-subtitle class="mt-3"><b>Grand Total: ${{grandtotal}}</b></v-list-item-subtitle>
                        </div>
                    </v-col>
                    
                    <v-col cols="12" class="mb-10" >
                        <v-btn style="float:right;" dark class="mb-10" width="300px" color="blue" tile :to="{path:'/admin/checkout'}">PROCEED TO CHECKOUT</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
            </v-col>
        </v-row>
    </v-container>
</v-main>
<v-overlay :value="loading">
    <rotate-loader :loading="true" color="#fff"></rotate-loader>
  </v-overlay>
  </v-app>
</template>

<script>
import moment from 'moment';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
    components: {'RotateLoader': ClipLoader},
    data: () => ({
        credit: localStorage.getItem('credit'),
        job_count: localStorage.getItem('job_count'),
        doc_count: localStorage.getItem('doc_count'),
        mail_list_count: localStorage.getItem('mail_list_count'),
        cartList: [],
        subtotal:'',
        grandtotal:'',
        loading:false
    }),
    created () { 
        this.getCartItems();
        this.getDashoardCounts();
    },
    methods:{
        formatDate(value) {
            return moment(value).format("MM/DD/YYYY")
        },
        getCartItems(){
        this.loading = true;
            let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        axios
            .get("/api/getcartitems",header)
            .then(response => {
                let data = response.data;
            if (data.flag == 1) {
                this.cartList = data.data;
                this.subtotal = (this.cartList.length * (1.76 + 4.15) );
                this.grandtotal = (this.cartList.length * (1.76 + 4.15) );
                localStorage.setItem('subtotal',this.subtotal);
                localStorage.setItem('grandtotal',this.grandtotal);
                localStorage.setItem('cart_items',JSON.stringify(this.cartList));
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        getDashoardCounts(){
        let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        axios.get('/api/getdashboard', header ).then(function (result) {
                    console.log(result);
                    localStorage.setItem('credit', result.data.data.credit)
                    localStorage.setItem('doc_count', result.data.data.doc_count)
                    localStorage.setItem('job_count', result.data.data.job_count)
                    localStorage.setItem('mail_list_count', result.data.data.mail_list_count)
                }).catch(function (error) {
                    console.log("session expired");
            })
        }
    }
}
</script>

<style>

</style>