<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6" >
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-form ref="form1">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;">
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Add/Edit Video Link</h5>
                    </v-col>
                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        
                        <v-text-field
                          label="First Name*"
                          color="#e91e63"
                          required
                          outlined
                          dense
                          :rules="rules"
                          v-model="partner.first_name"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Last Name*"
                          color="#e91e63"
                          outlined
                          dense
                          required
                          :rules="rules"
                          v-model="partner.last_name"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Email Address*"
                          color="#e91e63"
                          outlined
                          dense
                          required
                          :rules="emailRules"
                          v-model="partner.email"
                          class="font-size-input input-style"
                        ></v-text-field>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        label="Phone*"
                        color="#e91e63"
                        :rules="rules"
                        outlined
                          dense
                                v-mask="'(###) ###-####'"
                                class="font-size-input input-style"
                                v-model="partner.phone"
                        ></v-text-field>
                      </v-col> -->
                      <!-- <v-col sm="12" cols="12" class="py-0">
                        <v-text-field
                        label="10K Card Link*"
                        color="#e91e63"
                        :rules="rules"
                        outlined
                          dense
                                class="font-size-input input-style"
                                v-model="partner.card_link"
                        ></v-text-field>
                        <p><a href="https://staging1-api.p2dcards.com/ref/6346d922448847c52f96a8ddcb563ab4" target="_blank" style="color:#1B66C9">If you don't have 10K Card link Click here to Create a new one.</a></p>
                      </v-col> -->
                      
                    </v-row>
                     <v-row class="mt-2">
                      <v-col sm="12" cols="12" class="py-0">
                        <v-row class="mt-2" v-for="(link, i) in links" :key="i">
                          <v-col sm="4" cols="12" class="py-0">
                            <v-select
                              :items="['URL','File']"
                              label="Video Type"
                              outlined
                              v-model="link.type"
                              class="font-size-input input-style"
                              dense
                              density="compact"
                            >
                            <template v-slot:prepend>
                              <p>{{i + 1}}.</p>
                            </template>
                          </v-select>
                          </v-col>
                          <v-col sm="4" cols="12" class="py-0">
                            <v-text-field
                            label="URL"
                            color="#e91e63"
                            outlined
                            dense
                            v-if="link.type == 'URL'"
                            required
                            :rules="rules"
                            v-model="link.url"
                            class="font-size-input input-style"
                          ></v-text-field>
                          <v-file-input
                            label="File"
                            color="#e91e63"
                            outlined
                            v-if="link.type == 'File'"
                            dense
                            required
                            accept="video/*"
                            :rules="rules"
                            v-model="link.file"
                            class="font-size-input input-style"
                          ></v-file-input>
                          </v-col>
                          <v-col sm="4" cols="12" class="py-0">
                            <v-text-field
                            label="Video Title"
                            color="#e91e63"
                            outlined
                            dense
                            required
                            :rules="rules"
                            v-model="link.title"
                            class="font-size-input input-style"
                          >
                          <template v-slot:append-outer>
                          <v-btn icon="fa-close" @click="remove(i)" v-if="i != 0">
                           
                            <v-icon  style="color:red!important;margin-bottom: 15px;" class="text-typo">fas fa-times-circle</v-icon>
                          </v-btn>
                          </template>
                        </v-text-field>
                          
                          </v-col>
                          <v-col sm="12" cols="12" class="py-0">
                            <v-divider class=" mb-5"></v-divider>
                          </v-col>
                        </v-row>



                      </v-col>
                      <v-col sm="12" cols="12" class="py-0">
                        <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-secondary
                        py-5
                        px-6
                        mt-6
                        mb-5
                        me-2
                        float-right
                      "
                      color="primary"
                      @click="add()"
                    >
                      Add more
                    </v-btn>
                    
                      </v-col>
                      <v-col sm="12" cols="12" class="py-0">
                        <v-divider ></v-divider>
                      </v-col>
                      

                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-center ">
                 
                  <v-btn
                      :ripple="false"
                      :elevation="0"
                      :loading="loadingSaveButton"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-success
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                      "
                      color="primary"
                      @click="$refs.form1.validate() ? checkForm() : e1 = 1"
                    >
                      Save Links
                    </v-btn>
                </v-card-actions>
              </v-card>
              </v-form>
            </v-col>
          </v-row>
    </v-container>
    
     <vue-footer-admin></vue-footer-admin>
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        fname : '',
        e1 : 1,
        lname : '',
        email : '',
        name:'',
        phone:'',
        rules: [
                value => !!value || 'Required.',
            ],
        loading : false,
        password : '',
        is_admin:0,
        confpassword : '',
        role : '',
        partner : {
                first_name : "",
                last_name : '',
                email : '',
                phone :'',
                card_link:''
                


            },
            links: [{
              type : 'URL',
              title : '',
              link : '',
              file : ''
            }],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            loadingSaveButton: false,
            loadingAssign : false,
            
        companyname : '',
        sector : '',
        no_of_employee : '',
        showPassword : false,
        phoneFlag:0,
        emailFlag:0,
        country :'',
        otp_email:'',
        otp_phone:'',
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
        company : {
                id: '',
                first_name : "",
                last_name : '',
                email :'',
                phone : '',
                business_address :'',
                business_name : '',
                instagram : "",
                facebook : "",
                youtube :"",
                linkedin : "",
                website : "",
                twitter : "",
                pinterest: "",
                tiktok: "",
                snapchat: "",
                reddit: "",
                tumblr: "",
                flickr: "",
                vine: "",
                medium: "",
                soundcloud: "",
                twitch: "",
                clubhouse: ""


        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },
  watch: {
            company: {
                handler () {
                    this.company.company_url = this.company.name.replace(/\s/g, '-').toLowerCase()
                },
                deep: true,
            },
        },
    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
    verify(){
    let self = this;
    let flag = 1;
    if (!self.email && self.emailFlag ==1) {
    Vue.$toast.error('Email '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['email'].focus()
    flag = 0;
    }
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (reg.test(self.email) == false && flag == 1 && self.emailFlag == 1) {
        Vue.$toast.error('Invalid Email format', {
        position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
    }
    if (!self.phone && flag == 1 && self.phoneFlag == 1) {
    Vue.$toast.error('Phone Number '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['phone'].focus()
    flag = 0;
    }

    if (flag == 1) {
    var email = this.email;
    axios({
        method: "POST",
         
        url: "/api/auth/send_otp",
        data: {
        flag : self.phoneFlag == 1 ? 'phone' : 'email',
        email: self.email,
        phone:  self.phone,
        code: self.country,
        logged_flag : 1
        }
    })
        .then(response => {
        let data = response.data;
        if (data.data.flag == 1) {
            if(self.phoneFlag == 1 ){
                self.otp_phone = data.data.otp;
                self.phoneFlag = 2;
            }
            else{
                self.otp_email = data.data.otp;
                self.emailFlag = 2;
            }
            Vue.$toast.success(data.message, {
                position: 'top-right'
            });

        } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
        })
        .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
        });
      }
    },
    add () {
        this.links.push({
              type : 'URL',
              title : '',
              link : '',
              file : []
            })
      },
      
      remove (index) {
          this.links.splice(index, 1)
      },
        checkForm(){
            let self = this;
            let flag = 1;       
            if (!this.partner.first_name) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            // if (!this.partner.email && this.partner.is_email == false && flag == 1) {
            //     Vue.$toast.error('Email is required', {
            //         position: 'top-right'
            //     });
            //     flag = 0;
            // }
            // if (!this.partner.phone && flag == 1) {
            //     Vue.$toast.error('Phone is required', {
            //         position: 'top-right'
            //     });
            //     flag = 0;
            // }
           
            // let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // if (reg.test(this.partner.email) == false && this.partner.is_email == false  && flag == 1) {
            //     Vue.$toast.error('Invalid email format', {
            //         position: 'top-right'
            //     });
            //     flag = 0;
            // }

            
            this.partner.user_name = this.partner.email;
            
            if (flag == 1) {
                this.loadingSaveButton = true;
                this.isDark = false;
                // this.isDisable = true;
                let form  = new FormData();
                for (var key in self.partner) {
                  form.append(key,self.partner[key]);
                }
                for (let index = 0; index < this.links.length; index++) { console.log(self.links[index])
                  if(self.links[index].type == 'File')
                    form.append("files["+index+"]",self.links[index].file)
                  
                }
                form.append('links',JSON.stringify(this.links));
                axios({
                method: "POST",
                url: "/api/auth/add-video-link",
                data: form,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                        let result = response.data.data;
                        this.isDark = true;
                        this.loadingSaveButton = false;
                        this.isDisable = false;     
                        this.$router.push('/admin/video-links')              
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    this.loadingSaveButton = false;
                    if(err.response.data.flag){
                        if(err.response.data.flag == 2){
                            Vue.$toast.error('Company exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                        if(err.response.data.flag == 3){
                          Vue.$toast.error('Client exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                    }else{
                      let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                      if(data.email){
                          Vue.$toast.error(data.email[0], {
                          position: 'top-right'
                          });
                      }else if(data.password){
                          Vue.$toast.error(data.password[0], {
                          position: 'top-right'
                          });
                      }
                      else if(data.user_name){
                          Vue.$toast.error(data.user_name[0], {
                          position: 'top-right'
                          });
                      }
                      else{
                          Vue.$toast.error(data, {
                          position: 'top-right'
                          });
                      }
                  }
                    self.onCaptchaExpired()

                });

            }
        },
    verifyOTP(){
        if(this.phoneFlag ==2){
            if(! this.otp_phone_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_phone == this.otp_phone_input){
                    axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'phone',
                            phone: this.phone,
                            code: this.country
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                this.getUserProfile()
                                this.phoneFlag = 3;
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });


                }else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_phone_input ="";
                    this.$refs['phone_otp'].focus()
                }
            }

        }else{
            if(! this.otp_email_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_email == this.otp_email_input){
                     axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'email',
                            email: this.email,
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                //this.$router.push("/home");
                                this.emailFlag = 3;
                                this.getUserProfile()
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });

                } else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_email_input ="";
                    this.$refs['email_otp'].focus()
                }
            }
        }
    },
    getAddressData: function (addressData, placeResultData, id) {
            this.company.address = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['password'].focus()
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['confpassword'].focus()
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success(this.$t('my_profile.profile_alert_password_change_successfull'), {
                position: 'top-right'
                });

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },


    getUserProfile() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-contact-details/"+this.$route.params.id,header)
        .then(response => {
            let data = response.data;

           this.company = data.data;
            

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },




    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.company.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.phone && flag == 1) {
            Vue.$toast.error('Phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        
     
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.company.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
       
        if (flag == 1) {
          self.loading = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-contact-save",
          data: self.company
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Contact added', {
                    position: 'top-right'
                  })
                  self.loading = false;
                  this.$router.push('/admin/contacts')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
                self.loading = false;
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });     
              if(err.response.data.errors.company_url)
                Vue.$toast.error(err.response.data.errors.company_url[0], {
                position: 'top-right'
                });             
          });
        }
    },

 },

created() {
      console.log('created called.');
      if(this.$route.params.id)
        this.getUserProfile();
    },

}
</script>

