<template>
<v-app id="inspire" >
<vue-header-admin></vue-header-admin>
    <v-main>
    <v-container fluid class="px-6 py-6" >
        <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;">
                  <v-row>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Users({{totalCount}})</h5>
                    </v-col>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                    <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                    </v-col>
                     <v-col cols="12"
                      md="6" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="$router.push('add-company-user')"
                                    >
                                        Add User
                                    </v-btn>
                                   
                      </v-col>
                    <v-col cols="12"
                      md="6" >
                        <v-spacer></v-spacer>
                            <v-text-field
                               v-model="search"
                                label="Search"
                                single-line
                                clearable
                                hide-details
                            >
                                <template v-slot:append>
                                
                                </template>
                                <template v-slot:append-outer>
                                    <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="getUsers"
                                    >
                                        Submit
                                    </v-btn>
                                </template>
                            </v-text-field>
                    </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_jobs"
                            :options.sync="datatable_options"
                            :items="users"
                            loading-text="Loading... Please wait"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            class="table"
                            :footer-props="{
                                    firstIcon: '',
                                    lastIcon: '',
                                    prevIcon: '',
                                    nextIcon: '',
                                    'page-text': false,
                                    'items-per-page-options': [10, 50, 100,-1]
                                  }"
                        >
                     
                        <template v-slot:item.name="{ item }">
                            {{item.first_name + ' '+ item.last_name }}
                        </template>
                        <template v-slot:item.contact_email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.contact_phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                            x-small
                            color="blue"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              key
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="$router.push('edit-company-user/'+item.user_id)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.credit_new="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                           @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                            x-small
                            color="green"
                          >
                            <v-icon dark>
                              mdi-credit-card-plus
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.credit_remove="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                           @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                            x-small
                            color="red"
                          >
                            <v-icon dark>
                              mdi-credit-card-remove
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                        <template v-slot:item.process1="{ item }">
                          <v-btn
                            class="mx-2"
                            dark
                            @click="dialogDoc = true, selected_id = item.user_id"
                            x-small
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          Start
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            disabled

                            @click="dialogDoc = true"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'green'"
                          >
                          Finished
                          </v-btn>
                        </template>
                        <template v-slot:item.process_1_key="{ item }">
                          <v-btn
                            class="mx-2"
                            
                            @click="dialogDoc = true, selected_id = item.user_id"
                            x-small
                            disabled
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          View
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            dark
                            @click="getProcessStatus(item.process_1_key)"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'blue'"
                          >
                          View
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.user_id)"
                            x-small
                            color="red"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                             circle
                            color="#4CAF50"
                                v-model="page"
                                :length="pageCount"
                                @input="getUsers"
                                class="mb-5"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
        </v-row>
        <v-dialog
          v-model="dialogDoc"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Process 1
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(1)"
                            :color="'green'"
                          >
                          Server 1
                          </v-btn>
              </span>
            </v-card-text>
            <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(2)"
                            :color="'green'"
                          >
                          Server 2
                          </v-btn>
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogDoc = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
        persistent
      v-model="dialogPassword"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Reset Password
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
           <v-radio-group   v-model="selectedPasswordRadio">
                 <v-radio
                    :label="`Automatically generate a password`"
                    :value="1"
                   
                  ></v-radio>
                    <v-text-field
                        id="name"
                        name="name"
                        outlined
                        class="mt-3"
                        style="password"
                        v-if="selectedPasswordRadio == 1"
                        label="Password"
                        width="300px"
                        ref="name"
                        v-model="selectedPassword"
                        />
                   <v-radio
                    :label="`Create password`"
                    :value="0"
                  ></v-radio>
                    <v-text-field
                        id="name"
                        name="name"
                        class="mt-3"
                        style="password"
                        v-if="selectedPasswordRadio == 0"
                        outlined
                        label="Password"
                        width="300px"
                        ref="name"
                        v-model="selectedPassword"
                        />
           </v-radio-group>
         

        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="resetPassword(selectedPasswordId)"
          >
            Reset
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="dialogPassword = false,selectedPasswordRadio = 1"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-dialog
          v-model="creditDialog"
          width="700"
        >
         <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{creditFlag == 'remove' ? 'Remove credit' : 'Add credit'}}
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <span style="color:#5D6167;">Credit</span>
          <v-text-field
                v-model="new_credit"
                v-mask="'###########################'"
                outlined
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="addCredit()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="creditDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
        </v-dialog>
         <v-dialog
          v-model="process_status_dialog"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Transaction {{transaction.Key}}
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <b>Status: </b>
              </span>
              <span class="text-h5" v-if="!transaction.ProcessingException" >
                {{transaction.Status}}
              </span>
              <span class="text-h5" v-else >
                {{transaction.Status}} ({{transaction.ProcessingException.Reason}})
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="process_status_dialog = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogEdit"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Process 1
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
             
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogEdit = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
    <vue-footer-admin></vue-footer-admin>
</v-main>

</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import {Circle8} from 'vue-loading-spinner'
import moment from 'moment';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
import VueJsonToCsv from 'vue-json-to-csv'
export default {
    components : {
      'RotateLoader': ClipLoader,
      VueJsonToCsv
    },
    data () {
      return {
        header : { headers : {
            Authorization:"Bearer " + localStorage.getItem('token')
            },
        },
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 3 || 'Min 3 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
        transaction : [],
        loading:true,
        dialog:false,
        initials:'',
        valid:true,
        loadingUpload2:false,
        new_credit: '',
        selectedCompany: '',
        loadingAssign: false,
        creditDialog:false,
        pageCount:1,
        dialogPassword: false,
        dialogEdit: false,
        pageSort: {},
        documentList:[],
        totalCount:0,
        creditFlag: '',
        selectedPasswordId:'',
        selectedPasswordRadio: 1,
        selectedPassword:'',
        page:1,
        itemsPerPage:10,
        selected_job:'',
        search : "",
        dialogDoc:false,
        datatable_options:{},
        offset: 0,
        selected_id : '',
        limit: 10,
        selected_project:'',
        checkbox: '',
        process_status_dialog : false,
        headers_jobs: [
          { text: 'Name', value: 'name',
            sortable: true },
          { text: 'Contact Email', value: 'contact_email',
            sortable: true },
          { text: 'Contact Phone', value: 'contact_phone',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
             { text: 'Edit', value: 'edit',
            sortable: false,width: '150' },
             { text: 'Reset Password', value: 'password',
            sortable: false,width: '150' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        projects: [
          
        ],
        users: []
      }
    },
    created () { 
        if(!localStorage.getItem('token'))
            this.$router.push('/login')
        // this.getUsers();
    },
    methods:{
      dateFormat(date){
        return moment(date).format("MM/DD/YYYY")+', Age '+moment().diff(date, 'years');
      },
        formatDate(value) {
            return moment(value).format("DD MMM YYYY")
        },
        addCredit(){
          let id = this.selectedCompany;
           var r = this.creditFlag == 'remove' ? confirm("Are you sure, You went to remove credit?") : confirm("Are you sure, You went to add credit?");
          if (r == true) {
            this.loadingAssign = true;
            axios
              .post("/api/add-credit-admin/"+id,{
                credit : this.new_credit,
                flag : this.creditFlag
              },this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                if(this.creditFlag == 'remove'){
                  Vue.$toast.success('Credit removed successfully', {
                      position: 'top-right'
                      });
                }else{
                  Vue.$toast.success('Credit added successfully', {
                      position: 'top-right'
                      });
                }
                      this.creditDialog = false;
                      this.new_credit = 0;
                      this.loadingAssign = false;
                    this.getUsers();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                      this.loadingAssign = false;
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        StartProcess1(flag){
          let id = this.selected_id;
          var r = confirm("Are you sure, You went to start process 1?");
            if (r == true) {
              this.loading = true;
              this.dialogDoc = false;
                    axios
                .get("/api/process1/"+id+"/"+flag,this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Process started successfully', {
                            position: 'top-right'
                            });
                            this.loading = false;
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                            this.loading = false;
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        openDocModal(id){
          
          this.dialogDoc = true;
          axios
            .get("/api/get-documents/"+id,this.header)
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.documentList = data.data;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
           });
        },
        editItem(id){
          this.$router.push('/add-parking/'+id)
        },
        generatePassword() {
            var length = 8,
                charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                retVal = "";
            for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n));
            }
            return retVal;
        },
        deleteItem(id){
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-profile/"+id,{multiple_flag : false},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getUsers();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        changeStatus(id,value){
            this.loading = true
             var r = confirm("Are you sure, You went to change status?");
            if (r == true) {
                    axios
                .get("/api/change-status/"+id+"/"+value,this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Profile status changed successfully', {
                            position: 'top-right'
                            });
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        getUsers(){
            this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search,
              company_id : localStorage.getItem('company_id')
            }
            axios({
          method: "POST",
           
          url: "/api/get-company-users",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        resetPassword(id){
          this.loading = true
             var r = confirm("Are you sure, You went to reset password?");
            if (r == true) {
              this.loadingUpload2 = true;
                    axios
                .post("/api/reset-password-company/"+id,{password: this.selectedPassword},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        this.loading = false
                        Vue.$toast.success('Password reset successfully', {
                            position: 'top-right'
                            });
                            this.selectedPasswordRadio = 1;
                            this.dialogPassword = false;
                             this.loadingUpload2 = false;
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                this.loading = false
                return false;
            }
        },
        getProcessStatus(id){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            axios({
              method: "GET",
               
              url: "/api/get-process-status/"+id,
              header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.transaction = data.data.value[0];
                console.log(this.transaction);
                this.process_status_dialog = true;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        
        

    },
    watch: {
            datatable_options: {
                handler () {
                    console.log(this.datatable_options);
                this.getUsers()
                },
                deep: true,
            },
        },
    
}
</script>

