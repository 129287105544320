var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticStyle:{"background":"#F9F9F9"},attrs:{"id":"inspire"}},[_c('vue-header-admin'),_c('v-main',[_c('v-container',{staticClass:"px-6 py-6 mb-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"card-shadow px-4 py-4 overflow-hidden border-radius-xl"},[_c('v-card-text',{staticStyle:{"margin-bottom":"30px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h1',{staticClass:" mb-5",staticStyle:{"color":"rgba(0,0,0,.6)"}},[_vm._v("Faxes Sent")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"\n                         ont-weight-bolder\n                          btn-default\n                          mr-5\n                          py-4\n                          px-8\n                          ms-auto\n                          mt-sm-auto mt-4\n                                        \n                                      ",attrs:{"color":"red","small":"","dark":""},on:{"click":function($event){return _vm.deleteProfile('all')}}},[_vm._v(" Delete Selected ")])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.projects,"show-select":"","item-key":"FileName","items-per-page":10},scopedSlots:_vm._u([{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated))+" ")]}},{key:"item.ToFaxNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNumber(item.ToFaxNumber))+" / "+_vm._s(item.Subject)+" ")]}},{key:"item.Size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatBytes(item.Size))+" ")]}},{key:"item.SentStatus",fn:function(ref){
var item = ref.item;
return [(item.SentStatus == 'Failed')?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.SentStatus)+" ")]):_c('span',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(item.SentStatus)+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.projects.indexOf(item)+1)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"text":"","dark":"","x-small":"","title":"View","color":"orange"},on:{"click":function($event){return _vm.viewFax(item)}}},[_c('v-icon',{staticClass:"material-icons-round opacity-10",attrs:{"size":"20","dark":""}},[_vm._v(" visibility ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"text":"","dark":"","x-small":"","title":"Resend","color":"green"},on:{"click":function($event){return _vm.resendFax(item)}}},[_c('v-icon',{staticClass:"material-icons-round opacity-10",attrs:{"size":"20","dark":""}},[_vm._v(" send ")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"text":"","dark":"","title":"Delete item","x-small":"","color":"red"},on:{"click":function($event){return _vm.deleteProfile(item.FileName)}}},[_c('v-icon',{staticClass:"material-icons-round opacity-10",attrs:{"size":"20","dark":""}},[_vm._v(" delete ")])],1)]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1)],1)],1)],1)],1),_c('vue-footer-admin')],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('rotate-loader',{attrs:{"loading":true,"color":"#fff"}})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Accept Proof ")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{ref:"initials",attrs:{"id":"password","label":"Initials","name":"initials","rules":[_vm.rules.required, _vm.rules.min],"type":'text'},model:{value:(_vm.initials),callback:function ($$v) {_vm.initials=$$v},expression:"initials"}})],1),_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"cols":"12","md":"12"}},[_c('v-checkbox',{attrs:{"rules":[_vm.rules.required],"label":"Yes I have viewed the proof and approve them. "},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.valid},on:{"click":_vm.addToCartAPI}},[_vm._v(" ADD TO CART ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }