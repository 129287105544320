<template>
  <v-app >
    <vue-header></vue-header>
    <v-main style="padding:0px!important;margin-top: 150px;" >
    <v-container fluid style="margin-bottom:150px;" class="px-6 py-6">
      <v-row >
        <v-col cols="12">
       
            <div class="d-flex float-right">
                
                
            </div>
            </v-col>


            <v-col cols="12">
           
          </v-col>
          <v-col cols="12">
            
        <div
        v-if="panel2 != 0 && panel != 0"
        class="position-relative min-vh-100 mt-5"
        :style="company && loadingLogo &&  company.logo ? `background-image: url(${base_url_img+company.logo_background}); background-size: cover;` : `background-image: url(${backgroundLogo}); background-size: cover;` "
      ></div>
        </v-col>


      </v-row>
      <v-dialog
      v-model="importDialog"
      width="700"
    >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
          Import Credit Report
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <rotate-loader class="mt-3 mb-3" :loading="loadingImport" color="#1D75F4"></rotate-loader>
          <!-- <span style="color:#5D6167;">Source code</span> -->
          <!-- <v-textarea
                v-model="source_code"
                outlined
          ></v-textarea> -->
          <span style="color:#5D6167;">Import From</span>
          <v-select
                          v-model="import_from"
                          @change="renderImport"
                          outlined
                            :items="['Smart Credit','My Free Score Now']"
                            
                          ></v-select>
          <span style="color:#5D6167;">Username</span>
          <v-text-field
                v-model="username"
                outlined
          ></v-text-field>
          <span style="color:#5D6167;">Password</span>
          <v-text-field
             type="password"
                v-model="password"
                outlined
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            :dark ="isDarkImport"
            :disabled="isDisableImport"
            @click="getReportFromSmartCredit()"
          >
            Import
          </v-btn>
           <v-btn
            color="green"
            outlined
            :disabled="loadingAssign"
            @click="importDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogAssign2"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!product.id">
          Add new Grant
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Product
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-text-field
                label="Grant Name"
                v-model="product.name"
          ></v-text-field>
          <v-text-field
                v-model="product.url"
                label="Grant URL"
          ></v-text-field>
             <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="product.deadline"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="product.deadline"
                      label="Deadline"
                      prepend-icon="fa-calendar"
                      readonly
                      v-bind="attrs"
                      clearable
                      v-on="on"
                      @click:clear="product.deadline = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="product.deadline"
                    scrollable
                    color="green lighten-1"
                    :min="minDate"
                    header-color="green lighten-1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(product.deadline)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-select
                            item-text="name"
                            label="Focus Area"
                            item-value="id"
                            :disabled="loadingAssign"
                            v-model="product.focus_area"
                            :items="focusarea"
                            ></v-select>
                             <v-select
                            item-text="name"
                            label="Location"
                            item-value="id"
                            :disabled="loadingAssign"
                            v-model="product.location"
                            :items="locations"
                            ></v-select>
                            <v-text-field
                            label="Value"
                v-model="product.value"
                v-mask="'###########################'"
                
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!product.id"
            color="#4caf50"
            text
            :disabled="loadingAssign2"
            @click="addGrant()"
          >
            Submit
          </v-btn><v-btn
          v-else
            color="primary"
            text
            :disabled="loadingAssign"
            @click="editPromo()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssign2 = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="sendLetterDialog"
    >

     <v-card style="background: #18214D;">
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="sendLetterDialog = false"
          >
            <v-icon class="ml-5">fa-times</v-icon>
          </v-btn>
          <v-toolbar-title>Send Letter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

          </v-toolbar-items>
        </v-toolbar>
        <div >
          <v-form
                    ref="form"
                    v-model="valid_letter"
                    lazy-validation
                  >
               <v-row  style="padding: 50px;">
               
                <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5">
                        <div><v-radio-group v-model="radioGroup">
                                <v-radio
                                color="info"
                                  :label="`Upload a document`"
                                  :value="1"
                                ></v-radio>
                              </v-radio-group>
                        </div>
                        <div>
                          <v-btn
                          x-large
                              color="#5E72E4"
                              class="ma-2 white--text"
                              @click="onButtonClick('file_letter')"
                            >
                            <v-icon
                                left
                                dark
                                
                              >
                                fa-upload
                              </v-icon>
                                 Click to upload PDF
                              
                            </v-btn>
                            <input
                              ref="file_letter"
                              class="d-none"
                              type="file"
                              accept="application/pdf"
                              @change="onFileChangedLetter"
                            >
                            Must be PDF format,
10mb max
                        </div>
                        <div class="mt-2">
                          {{docupost.file ? docupost.file.name : ''}} 
                            <v-icon class="ml-2" v-if="docupost.file.length != 0" color="red" @click="docupost.file = []">
                              fa-times
                            </v-icon>
                        </div>
                      </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                        <v-row>
                           <v-col cols="12" md="6">
                        <div style="margin:15px 0 7px 0;">
                          <h2>Mail service</h2>
                        </div>
                        <div>
                          <v-select
                          v-model="docupost.mail_service"
                            :items="['USPS First Class (3-10 days)','USPS Standard (5-20 days)']"
                            
                          ></v-select>
                          
                        </div>
                        </v-col>
                         <v-col cols="12" md="6">
                          <div style="margin:15px 0 7px 0;" v-if="docupost.mail_service =='USPS First Class (3-10 days)'">
                          <h2>Certified options</h2>
                        </div>
                        <div v-if="docupost.mail_service =='USPS First Class (3-10 days)'">
                          <v-select
                          v-model="docupost.certified_option"
                            :items="['','Certified mail','Certified w/electronic return receipt (proof of receipt)']"
                            
                          ></v-select>
                          
                        </div>
                         </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                          <h2>To Recipient</h2>
                        </div>
                        <div>
                          <v-row>
                            <v-col
                                cols="12"
                                md="12"
                              >
                              <v-select
                              style="margin-bottom:-25px;"
                              label="Select Address"
                              item-text="name"
                              item-value="name"
                              @change="renderAddress('res')"
                                v-model="address_select_res"
                                  :items="address_list_res"
                                  
                                ></v-select>
                            </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                style="margin-bottom:-25px;margin-top:15px;"
                                  v-model="recipient.name"
                                  :rules="nameRules"
                                  label="Recipient name"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.address1"
                                  :rules="nameRules"
                                  label="Address line 1"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.address2"
                                  :rules="nameRules"
                                  label="Address line 2"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.city"
                                  :rules="nameRules"
                                  label="City"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-select
                                 style="margin-bottom:-25px;"
                                item-text="name"
                                  item-value="abbreviation"
                                  :items="stateOptions"
                                  v-model="recipient.state"
                                  :rules="nameRules"
                                  label="State"
                                  
                                  required
                                ></v-select>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="recipient.zip"
                                  :rules="nameRules"

                                  label="Zip"
                                  v-mask="'#####'"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                          </v-row>
                        </div>
                        
                      </v-card-text>
                    </v-card>
                </v-col>
                 <v-col cols="12" md="6">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                          <h2>From Sender</h2>
                        </div>
                        <div>
                          <v-row>
                            <v-col
                                cols="12"
                                md="12"
                              >
                              <v-select
                              style="margin-bottom:-25px;"
                              label="Select Address"
                              item-text="name"
                              item-value="name"
                              @change="renderAddress('sen')"
                                v-model="address_select_sen"
                                  :items="address_list_sen"
                                  
                                ></v-select>
                            </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                style="margin-bottom:-25px;margin-top:15px;"
                                  v-model="sender.name"
                                  :rules="nameRules"
                                  label="Sender name"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.address1"
                                  :rules="nameRules"
                                  label="Address line 1"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.address2"
                                  :rules="nameRules"
                                  label="Address line 2"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.city"
                                  :rules="nameRules"
                                  label="City"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-select
                                 style="margin-bottom:-25px;"
                                item-text="name"
                                  item-value="abbreviation"
                                  :items="stateOptions"
                                  v-model="sender.state"
                                  :rules="nameRules"
                                  label="State"
                                  
                                  required
                                ></v-select>
                              </v-col>
                               <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                 style="margin-bottom:-25px;"
                                  v-model="sender.zip"
                                  :rules="nameRules"

                                  label="Zip"
                                  v-mask="'#####'"
                                  
                                  required
                                ></v-text-field>
                              </v-col>
                          </v-row>
                        </div>
                        
                      </v-card-text>
                    </v-card>
                </v-col>
                 <v-col cols="12" md="12">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                          <h2>Document options</h2>
                        </div>
                        <div>
                          <v-row>
                            <v-col cols="12" md="3">
                           <v-checkbox
                                v-model="docupost.document_options.color"
                                :label="`Print in color `"
                              ></v-checkbox>
                              </v-col>
                              <v-col cols="12" md="3">
                              <v-checkbox
                                v-model="docupost.document_options.double_side"
                                :label="`Double-sided `"
                              ></v-checkbox>
                              </v-col>
                              </v-row>
                        </div>
                        </v-card-text>
                  </v-card>
                 </v-col>
                   <v-col cols="12" md="12">
                  <v-card
                      class="mx-auto"
                      
                    >
                      <v-card-text class="pl-5 pr-5">
                         <div style="margin:15px 0 7px 0;">
                           <v-btn
                          x-large
                              :disabled="!valid_letter"
                              color="#5E72E4"
                              class="ma-2 white--text"
                              @click="sendLetter"
                            >
                            <v-icon
                                left
                                dark
                                
                              >
                                fa-paper-plane
                              </v-icon>
                                 Send Letter
                              <rotate-loader style="" :loading="loadingSendLetter" color="white"></rotate-loader>
                            </v-btn>
                         </div>
                         </v-card-text>
                         </v-card>
                   </v-col>
              
               </v-row>
                </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogImport2"
      width="500"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Submit new request
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
                <span style="color:#5D6167;">Process</span>
                 <v-select
                            outlined
                            :disabled="loadingUpload2"
                            v-model="request.process"
                            :items="itemOptions"
                            ></v-select>
                <span style="color:#5D6167;">Round</span>
                 <v-select
                            outlined
                            :disabled="loadingUpload2"
                            v-model="request.round"
                            :items="[1,2,3,4]"
                            ></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="submitRequest()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingUpload2"
            @click="dialogImport2 = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        <v-overlay
          :absolute="true"
          :value="creditFlag"
          :opacity=".5"
        >
          <v-btn
            color="blue"
            v-if="!client.company_id"
            class="mr-5"
            @click="addCreditCheck()"
          >
            Buy Credit
          </v-btn>
          <v-btn
            color="black"
            @click="creditFlag = false,dialogImport2 = false"
          >
            Close
          </v-btn>
        </v-overlay>
      </v-card>
    </v-dialog>
     <v-dialog
          v-model="dialogDoc"
          width="700"
        >
          <v-card>
            <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
            <v-card-title class="text-h5 grey lighten-2">
              Process 1
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(1)"
                            :color="'green'"
                          >
                          Server 1
                          </v-btn>
              </span>
            </v-card-text>
            <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(2)"
                            :color="'green'"
                          >
                          Server 2
                          </v-btn>
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogDoc = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="creditDialog"
          width="700"
        >
         <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{creditFlag == 'remove' ? 'Remove credit' : 'Add credit'}}
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <span style="color:#5D6167;">Credit</span>
          <v-text-field
                v-model="new_credit"
                v-mask="'###########################'"
                
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="addCredit()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="creditDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        <v-overlay
          :absolute="true"
          :value="creditFlag2"
          :opacity=".5"
        >
          <v-btn
            color="primary"
            v-if="!client.company_id"
            @click="addCreditCheck()"
          >
            Buy Credit
          </v-btn>
          <v-btn
            color="black"
            @click="creditFlag2 = false,creditDialog = false"
          >
            Close
          </v-btn>
        </v-overlay>
      </v-card>
        </v-dialog>
        <v-dialog
        transition="dialog-bottom-transition"
      v-model="dialogTiptext"
      width="700"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
         
        </v-card-title>

        <v-card-text style="margin-top:30px;">
          <!-- <vimeo-player v-if="dialogTiptext && welcome_video != 'false'" ref="player" :video-id="videoID"/> -->
          <video class="img-fluid" id="video-preview" controls v-show="checkVideoPermission()" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <!-- <v-btn
                text
                color="blue"
                @click="gotoLink()"
              >Let's Start your Monitoring service(CLick Here)</v-btn> -->
              <v-btn
                text
                @click="dialogTiptext = false"
              >Close</v-btn>
              <v-btn
                text
                color="red"
                @click="noTiptext()"
              >Don't show me again</v-btn>
            </v-card-actions>
        </v-card>
        
        </v-dialog>
        <v-dialog
        scrollable
        transition="dialog-bottom-transition"
      v-model="dialogSignContract"
      width="1000"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Sign Contract({{selectedContract.title}})
        </v-card-title>

        <v-card-text style="margin-top:30px;">
          


          <div v-html="renderHtml(selectedContract.letter)"></div>
          <v-checkbox v-model="checkbox">
          <template v-slot:label>
            <div>
              I, {{client.first_name}} {{client.last_name}} am giving my legal consent for my Electronic Signature to be used as proof that I did in fact sign this Electronic Document. I agree to all terms set forth herein.
              </div>
          </template>
        </v-checkbox>
          <div class="buttons-sig" v-if="checkbox">Signature</div>
          <div class="buttons-sig" v-if="checkbox">
            
          <VueSignaturePad
          id="signature"
          scaleToDevicePixelRatio
          width="500px"
          v-if="loadSignature"
          height="300px"
          ref="signaturePad"
          :options="options"
        />
          </div>
        <div class="buttons-sig" v-if="checkbox">
        <button @click="undo">Undo</button>
      </div>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
                text
                @click="submitContract()"
              >Agree to contract and Submit</v-btn>
              <v-btn
                text
                @click="dialogSignContract = false"
              >Close</v-btn>
           
            </v-card-actions>
        </v-card>
        
        </v-dialog>
    </v-container>
    <vue-footer></vue-footer>
    </v-main>
    
    </v-app>
</template>

<script>
function labelText(series) {
        var value = series.points(0).options('y'),
          fgg = value >= 750 ? 'Great!' : value >= 630 ? 'Good' : 'Fair';
        return '%sum<br/><span style="fontSize: 32px">' + fgg + '</span>';
      }
import HVStateStepper from './HVStateStepper';
import JSCharting, { JSC } from 'jscharting-vue';
import Chart from "chart.js/auto";

import RoundSlider from "vue-round-slider";
import { vueVimeoPlayer } from 'vue-vimeo-player'
import HtmlEditor from "./HtmlEditor.vue";
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
import moment from 'moment'
export default {
  components : {
   HVStateStepper,
   HtmlEditor,
   JSCharting,
    'RotateLoader': ClipLoader,vueVimeoPlayer
    },
   data () {
      return {
        model: 'tab-2',
        switch1 : true,
        panel: 0,
        panel2: 1,
        width : 100,
        pageCountLetter : 0,
        page_letter : 1,
        itemsPerPage_letter: 10,
        datatable_options_letter: {},
        phone_parent : localStorage.getItem('phone'),
        email_parent : localStorage.getItem('email'),
        imit_letter: 10,
        headers_letter : [
           { text: 'Title', value: 'title',
            sortable: true },
          { text: 'Type', value: 'type',
            sortable: true },
            { text: 'Round', value: 'round',
            sortable: true },
          { text: 'Print Staus', value: 'print_status',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            // { text: 'Edit', value: 'edit',
            // sortable: true,width: '200' },
            { text: 'View', value: 'download',
            sortable: true,width: '200' },
          // { text: 'Delete', value: 'id',
          //   sortable: false,width: '50' },
        ],
        welcome_video : localStorage.getItem('welcome_video') ? localStorage.getItem('welcome_video') : 'None',
        welcome_video_url : localStorage.getItem('welcome_video_url') ? localStorage.getItem('welcome_video_url') : '',
        options: {
      penColor: "#00f",
    },
        videoID: "750457220",
        currentState : "",
        doc_count : 4,
        addFileFlag: false,
        certifiedLetters : [],
        contract : [],
        applyGrantFlag : false,
        strokeOptions : {
          size: 16,
          thinning: 0.75,
          smoothing: 0.5,
          streamline: 0.5,
        },
        pageCount: 1,
        page :1,
        dialogSignContract: false,
        itemsPerPage : 10,
        avatar : localStorage.getItem('avatar') ?localStorage.getItem('avatar') : '',
        valid: true,
        model_grant : false,
        loading : false,
        search : '',
        search2  :'',
        focusarea : [],
        panel3 : 0,
        panel4 : 0,
        locations: [],
        pageCountIn : 1,
        search3 : '',
        pageCountCom : 1,

        memoFlag : false,
        sendLetterDialog : false,
        valid_letter : true,
        radioGroup : 1,
        importDialog : false,
        checkbox : false,
        conversation : {
          sender : '',
          receiver_user_id : '',
          receiver : '',
          subject : '',
          body : '',
          type : ''
        },
        conversations: [],
        loadingChat : false,
        panel5 : 0,
        tab: 1,
        newFilesUpload : [],
        newFileName : '',
        loadingCustomeFile : false,
        selectedFileProof :'',
        
        totalCountAllGrants : 0,
        totalCountInprogressGrants : 0,
        docs: [],
        totalCountCompletedGrants : 0,
        dialogAssign2 : false,
        userContracts : [],
        datatable_options_grants : {},
        datatable_options_grants_in : {},
        datatable_options_grants_com : {},
        product : {
          id : '',
          name : '',
          url : '',
          deadline : ''
        },
        minDate: moment().format('YYYY-MM-DD'),
        settings : JSON.parse(localStorage.getItem('settings')),
        grant_count : 0,
        headers_grants : [
        { text: 'Grant Name', value: 'name',
            sortable: true },
          { text: 'Grant URL', value: 'url',
            sortable: true },
            {
               text: 'Deadline', value: 'deadline',
            sortable: true
            },
            {
               text: 'Focus Area', value: 'focus_area_name',
            sortable: true
            },{
               text: 'Location', value: 'location_name',
            sortable: true
            },
            {
               text: 'Value', value: 'value',
            sortable: true
            },
          { text: 'Timestamp', value: 'created_at',
            sortable: true,width: '200' },
            { text: 'Action', value: 'apply',
            sortable: true,width: '200' },
         ],
         headers_grants_in : [
          { text: 'Grant Name', value: 'name',
            sortable: true },
          { text: 'Grant URL', value: 'url',
            sortable: true },
            {
               text: 'Status', value: 'status',
            sortable: false
            },
          { text: 'Submitted At', value: 'submitted_date',
            sortable: true,width: '200' },
          
         ],
         headers_grants_com : [
          { text: 'Grant Name', value: 'name',
            sortable: true },
          { text: 'Grant URL', value: 'url',
            sortable: true },
            {
               text: 'Status', value: 'status',
            sortable: false
            },
          { text: 'Submitted At', value: 'submitted_date',
            sortable: true,width: '200' },
            {
               text: 'Proof', value: 'proof',
            sortable: false
            },
            { text: 'Completed At', value: 'completed_date',
            sortable: true,width: '200' },
         ],
        datatable_options_grants : [],
        allGrants : [],
        allGrantsIn : [],
        allGrantsCom : [],
        dialogTiptext : false,
        selectedContract:'',
        loadSignature : false,
        page_in : 1,
        letters : [],
        import_from: 'Smart Credit',
        page_com : 1,
        grant : {
                first_name : "",
                last_name : '',
                middle_name: '',
                email : localStorage.getItem('temp_email') ? localStorage.getItem('temp_email') : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                dob: '',
                is_exist : "",
                ssn : '',
                phone_m : '',
                fax : '',
                address :'',
                address1: '',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                is_verified:  0,
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: '',
                confirm_password: "",
                crc_id : "",
                driving_license_number : '',
                driving_license_state : "",
                process_email : "",
                process_email_password : "",
                is_grant :'no'

            },
        nameRules: [
        v => !!v || '',
      ],
        moreDocuments : [],
        dashboard  : {
          score_eq : 0,
          score_ex : 0,
          score_tu : 0,

        },
        recipient : {
          name : '',
          address1 : '',
          address2: '',
          city : '',
          state : '',
          zip : ''
        },
        sender : {
          name : '',
          address1 : '',
          address2: '',
          city : '',
          state : '',
          zip : ''
        },
        documentCheck : [],
        isDisableImport : false,
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            loadingSendLetter : false,
            creditFlag2: false,
         FinalData: {
          profile : {
                score : {
                  transunion : 0,
                  experian : 0,
                  equifax : 0,
                },
                report_date : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                name : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                known_as : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                birth : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                current_address: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                employer: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                previous_address : {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
          },
          credit_summary : {
                  transunion : {},
                  experian : {},
                  equifax : {},
                
          },
          credit_inquiries: [],
          public_record : [],
          account_history : []
        },
        isDarkImport : true,
        rules: [
                value => !!value || 'Required.',
            ],
            loadingGrantApplication : false,
            grantStates : [],
        client : {progress  : 1},
        chartConsumption: "chart-consumption",
        chartOptionseq: {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, 0]] 
                } 
              ] 

        
         },
         chartOptionsex: {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, 0]] 
                } 
              ] 

        
         },
         chartOptionstu: {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, 0]] 
                } 
              ] 

        
         },
      
        testStates: [
      { name: 'Customer Details',current: false,done : false },
      { name: 'Agreement Signed',current: false,done : false },
      { name: 'Required Documents',current: false,done : false },
      { name: 'Credit Import',current: false,done : false},
      { name: 'Resolution Round 1',current: false,done : false },
      { name: 'Resolution Round 2',current: false,done : false },
      { name: 'Resolution Round 3',current: false,done : false },
      { name: 'Resolution Round 4',current: false,done : false },
    ],
    getAddressData: function (addressData, placeResultData, id) {
            this.grant.grant_address = addressData.name;
        },
        getAddressData2: function (addressData2, placeResultData, id) {
            this.grant.grant_business_address = addressData2.name;
        },
    getUsers(){
        this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_grants.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_grants.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_grants.sortBy,
              sortDesc : this.datatable_options_grants.sortDesc && this.datatable_options_grants.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-all-grant",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.allGrants = data.data;
                this.pageCount = data.total_page
                this.totalCountAllGrants = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        
      },
      getInGrants(){
        this.loading = true;
            this.offset = (this.page_in - 1) * this.datatable_options_grants_in.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_grants_in.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_grants_in.sortBy,
              sortDesc : this.datatable_options_grants_in.sortDesc && this.datatable_options_grants_in.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search2,
              user_id : this.id
            }
            axios({
          method: "POST",
           
          url: "/api/get-submitted-grant",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.allGrantsIn = data.data;
                this.pageCountIn = data.total_page
                this.totalCountInprogressGrants = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        
      },
      renderHtml(html){
      if(html){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        html = html.replace("{client_suffix}",this.client.suffix);
        html = html.replace("{client_first_name}",this.client.first_name);
        html = html.replace("{client_middle_name}",(this.client.middle_name ? this.client.middle_name : ''));
        html = html.replace("{client_last_name}",this.client.last_name);
        html = html.replace("{client_address}",this.client.address_1+'<br>'+this.client.city+', '+this.client.state+' '+this.client.zip);
        html = html.replace("{client_previous_address}",this.client.address_1+'<br>'+this.client.city+', '+this.client.state+' '+this.client.zip);
        html = html.replace("{bdate}",this.client.dob);
        html = html.replace("{ss_number}",this.client.ssn);
        html = html.replace("{t_no}",this.client.phone_m);
        html = html.replace("{curr_date}",today);
        html = html.replace("{client_signature}",'');
      }

      return html;
    },
      getScore(item,flag){
      
      let data = JSON.parse(item);
      if(flag == 'tu')
        return data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].riskScore
      if(flag == 'ex')
        return data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].riskScore
      if(flag == 'eq')
        return data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].riskScore
    },
      markCompeted(){
      if (confirm('Are you sure, You want to mark as completed?')) {
                    axios
                .post("/api/marked-completed-grant",{ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Grant status changed successfully', {
                            position: 'top-right'
                            });
                            this.getInGrants();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }
    },
      applyGrant(item){
      if(confirm('Are you sure, You want to apply for this grant?')){
        this.loading = true;
        axios
              .post("/api/apply-grant",{user_id : this.id,grant_id : item.id},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {

                if(response.data.flag == 1){
                  Vue.$toast.success('You have applied successfully', {
                      position: 'top-right'
                      });
                      this.loading = false;
                    this.getUsers();
                  }else{
                      Vue.$toast.error(response.data.message, {
                      position: 'top-right'
                      });
                  }
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
      }
    },
    loadSig(){
      let self = this
      setTimeout(function(){
        self.loadSignature = true
      },1000)
      
    },
    viewContract(id){
      let url = process.env.VUE_APP_WASBI_URL
      window.open(url+this.id+'_'+id+'.pdf', '_blank');
    },
    onButtonClickProof(id) {
      this.$refs['file_proof_'+id].click()
      this.selectedFileProof = id;
    },
    noTiptext(){
      localStorage.setItem('no_tiptext_flag',1);
      this.dialogTiptext = false
    },
      getComGrants(){
        this.loading = true;
            this.offset = (this.page_com - 1) * this.datatable_options_grants_com.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_grants_com.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_grants_com.sortBy,
              sortDesc : this.datatable_options_grants_com.sortDesc && this.datatable_options_grants_com.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search3,
              user_id : this.id
            }
            axios({
          method: "POST",
           
          url: "/api/get-completed-grant",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.allGrantsCom = data.data;
                this.pageCountCom = data.total_page
                this.totalCountCompletedGrants = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        
      },
    docupost : {
          file : [],
          document_options : {
            color : 0,
            double_side : 0
          },
          mail_service: 'USPS First Class (3-10 days)',
          certified_option:'',
        },
    dialogDoc : false,
        role: JSON.parse(localStorage.getItem('role')),
        tasks : [],
        doc_id_flag : false,
        src: "https://app.idealergy.com/v2/preview/t3AjaHbWdNQMrU5pWaxi?notrack=true",
        doc_id_value : '',
        doc_bill_flag : false,
        dialog:false,
        dialogImport2 : false,
        dialogImport : false,
        loadingUpload : false,
        address_select_sen : 'Custom',
        address_select_res : 'Custom',
        address_list_res : [
      {
          name : "Custom",
          address_1 : "",
          address_2 : "",
          city : "",
          state : "",
          zip : ""

        },
        {
          name : "TransUnion Consumer Solutions",
          address_1 : "P.O. Box 2000",
          address_2 : "",
          city : "Chester",
          state : "PA",
          zip : "19016"

        },
        {
          name : "Equifax Information Services LLC",
          address_1 : "P.O. Box 740256",
          address_2 : "",
          city : "Atlanta",
          state : "GA",
          zip : "30374"

        },
        {
          name : "Experian",
          address_1 : "P.O. Box 4500",
          address_2 : "",
          city : "Allen",
          state : "TX",
          zip : "75013"

        },
      ],
      address_list_sen : [
      {
          name : "Custom",
          address_1 : "",
          address_2 : "",
          city : "",
          state : "",
          zip : ""

        },
        {
          name : "TransUnion Consumer Solutions",
          address_1 : "P.O. Box 2000",
          address_2 : "",
          city : "Chester",
          state : "PA",
          zip : "19016"

        },
        {
          name : "Equifax Information Services LLC",
          address_1 : "P.O. Box 740256",
          address_2 : "",
          city : "Atlanta",
          state : "GA",
          zip : "30374"

        },
        {
          name : "Experian",
          address_1 : "P.O. Box 4500",
          address_2 : "",
          city : "Allen",
          state : "TX",
          zip : "75013"

        },
      ],
        loadingUpload2 :false,
        loadingImport : false,
        isDarkImport : true,
        selected_id:'',
        selected_bot : '',
        limit_letter: 10,
        selectedItems:[],
        sitekey: process.env.MIX_RECAPTCHA_SITE_KEY,
        id: localStorage.getItem('user_id'),
        selectedFile : '',
        credit_available : 0,
        creditDialog:false,
        source_code:'',
        creditFlag: 'add',
        new_credit: 0,
        reports: [],
        username : '',
        password : '',
        loadingAssign: false,
        credentials : '',
        credit_used: 0,
        importDialog: false,
        itemOptions: ['609','Inquires','609 and Inquires','Bankrupcy','Identity Thieft','Mailing Letters'],
        request: {
            process: "609",
            round: 1,
            recaptcha: ""
        },
        creditFlag :false,
        
        doc_bill_value : '',
        doc_letter_a_flag : false,
        doc_letter_a_value : '',
        doc_letter_b_flag : false,
        doc_letter_b_value : '',
        doc_letter_c_flag : false,
        doc_letter_c_value : '',
      }
    },
    computed: {
    documentCheckAll : {
      set(val) {
        this.documentCheck  = []
        if (val) {
           this.documentCheck.push('id');
           this.documentCheck.push('bill');
           this.documentCheck.push('letter_1');
           this.documentCheck.push('letter_2');
           this.documentCheck.push('letter_3');
          for(let i = 1; i <= this.moreDocuments.length; i++) {
            let id = 3 + i;
            this.documentCheck.push('letter_'+id)
          }
        }
      },
      get() {
        console.log(this.documentCheck.length);
        console.log(this.moreDocuments.length + 5);
        // if(this.documentCheck.length !== (this.moreDocuments.length + 5))
        //   this.documentCheckAll = [];
        // return this.documentCheck.length != 0 && this.documentCheck.length === (this.moreDocuments.length + 5)
      }
    },
    
  },
    methods : {
      gotoLink(){
        window.open('https://quantumdirect.net/smartcredit-sponsor-code-8012');
      },
      addNewFile(){
      let id = 4;
      this.addFileFlag = true;
    },
    onFileChangedProof(e) {
      let self = this;
      let selectedFile = e.target.files[0]
      const data = new FormData();
        data.append('file_proof',selectedFile);
        data.append('id',this.selectedFileProof);
          axios({
          method: "POST",
          url: "/api/upload-file-proof",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getComGrants();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      // }
      
      // do something
    },
    addGrant(){
        
        if(!this.product.name){
        Vue.$toast.error("Name is required! ", {
          position: 'top-right'
          });
      }else if(!this.product.url){
        Vue.$toast.error("URL is required! ", {
          position: 'top-right'
          });
      }else{
        this.loadingAssign2 = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-new-grant",
          data: this.product
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Grant added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign2 = false;
                      this.loadingAssign2 = false;
                      this.getUsers();
                      this.product.name = '';
                      this.product.url = '';
                      this.product.deadline = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          }
        },
        renderImport(){
          if(this.import_from=="Smart Credit"){
        this.username = this.client.smart_credit_user_name
        this.password = atob(this.client.smart_credit_password)
      }else{
        this.username = this.client.my_free_score_user_name
        this.password = atob(this.client.my_free_score_password)
      }
    },
    viewLetter(item){
      window.open(process.env.VUE_APP_WASBI_URL+'letters/'+item.id+'_'+item.type+'.pdf', '_blank');
    },
    getLetters(){
      this.loading = true;
            var offset = (this.page_letter - 1) * 10
            let req = {
              limit : this.limit_letter,
              offset : offset,
              sort : this.datatable_options_letter.sortBy,
              sortDesc : this.datatable_options_letter.sortDesc && this.datatable_options_letter.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search_letter
            }
            axios({
          method: "POST",
           
          url: "/api/get-client-letters/"+this.id,
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.letters = data.data;
                this.pageCountLetter = data.total_page
                this.countLetter = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    uploadMultiple(){
        let id = 4;
        let self = this;
        
        if(!this.newFileName){
           Vue.$toast.error("File name is required", {
              position: 'top-right'
              });
        }else{
        if(this.moreDocuments.length != 0){
          this.moreDocuments.forEach(val => {
            id = val.id;
          })
          id = parseInt(id)+1;
        }
        self.loadingCustomeFile = true;
        const data = new FormData();
        let invalid_flag = false;
         let k = id;
          this.newFilesUpload.forEach(val => {
            if(!val.name.includes(self.client.email)){
              invalid_flag = true;
            }
            data.append('file_letter_'+k,val);
            k = k+1;
          })
          // if(invalid_flag == true){
          //   Vue.$toast.error("Document should contain user email", {
          //           position: 'top-right'
          //           });
          //   self.loadingCustomeFile = false;
          // }else{
          data.append('last_id',id);
          data.append('total_file',this.newFilesUpload.length);
          data.append('custom_file_name',this.newFileName);
          data.append('flag',self.selectedFile.replace('file_', ''));
          data.append('user_id',this.id);
          this.loadingUpload = true;
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          self.loadingCustomeFile = false;
          self.newFileName = '';
          self.newFilesUpload = [];
          self.addFileFlag = false;
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          this.loadingUpload = false;
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
          // }
        }
      },
      submitContract(){
        let data = this.$refs.signaturePad.saveSignature();
        if(!data.data){
          Vue.$toast.error('Please sign to submit the contract', {
                    position: 'top-right'
                    });
        }else{
          axios({
          method: "POST",
          url: "/api/submit-user-contract/"+this.id,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {
            data : data,
            template : this.selectedContract.id
          }
          })
          .then(response => { 
            if(response.data.flag == 1){
               Vue.$toast.success('Your Signed Contract has been submitted', {
                    position: 'top-right'
                    });
                    this.getContracts();
                    this.dialogSignContract = false;
                  }

                })
                
        }
      },
      generateChart(){
        new Chart(document.getElementById(this.chartConsumption).getContext("2d"), {
             
  debug: true, 
  type: 'gauge ', 
  legend_visible: false, 
  chartArea_boxVisible: false, 
  xAxis: { 
    /*Used to position marker on top of axis line.*/
    scale: { range: [0, 1], invert: true } 
  }, 
  palette: { 
    pointValue: '%yValue', 
    ranges: [ 
      { value: 350, color: '#FF5353' }, 
      { value: 600, color: '#FFD221' }, 
      { value: 700, color: '#77E6B4' }, 
      { value: [800, 850], color: '#21D683' } 
    ] 
  }, 
  yAxis: { 
    defaultTick: { padding: 13, enabled: false }, 
    customTicks: [600, 700, 800], 
    line: { 
      width: 15, 
      breaks_gap: 0.03, 
      color: 'smartPalette'
    }, 
    scale: { range: [350, 850] } 
  }, 
  defaultSeries: { 
    opacity: 1, 
    shape: { 
      label: { 
        align: 'center', 
        verticalAlign: 'middle'
      } 
    } 
  }, 
  series: [ 
    { 
      type: 'marker', 
      name: 'Score', 
      shape_label: { 
        text: 
          "720<br/> <span style='fontSize: 35'>Great!</span>", 
        style: { fontSize: 48 } 
      }, 
      defaultPoint: { 
        tooltip: '%yValue', 
        marker: { 
          outline: { 
            width: 10, 
            color: 'currentColor'
          }, 
          fill: 'white', 
          type: 'circle', 
          visible: true, 
          size: 30 
        } 
      }, 
      points: [[1, 720]] 
    } 
  ] 

          });
      },
      creditCheck(){
        this.getUserProfileParent();
        

      },
      onFileChangedLetter(e) {
       let selectedFile = e.target.files[0];
       this.docupost.file =  selectedFile;
    },
      sendLetter(){
      let self = this;
      
      if(this.docupost.file.length == 0){
        Vue.$toast.error("Document is required! ", {
          position: 'top-right'
          });
      }else if(this.$refs.form.validate()){
        self.loadingSendLetter = true;
        const data = new FormData();
        data.append('file_letter', this.docupost.file);
        data.append('docupost', JSON.stringify(this.docupost));
        data.append('sender', JSON.stringify(this.sender));
        data.append('recipient', JSON.stringify(this.recipient));
        data.append('user_id', this.id);
        axios({
          method: "POST",
          url: "/api/send-letter-client",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => { 
            self.loadingSendLetter = false;
            if(response.data.flag == 1){
               Vue.$toast.success('Your letter has been submitted', {
                    position: 'top-right'
                    });
                    this.$refs.form.reset()
                    this.docupost = {
                        file : [],
                        document_options : {
                          color : 0,
                          double_side : 0
                        },
                        mail_service: 'USPS First Class (3-10 days)',
                        certified_option:'',
                      }
            }else{
                    Vue.$toast.error(response.data.status, {
                    position: 'top-right'
                    });
            }
          })
           .catch(err => {
            self.loadingSendLetter = false;
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
          })
      }
    },
    viewFile(file){
            window.open(process.env.VUE_APP_WASBI_URL+file, '_blank');
        },
        getFormattedDate(date){
          return moment(date).format('MM/DD/YYYY');
        },
        getDateTime(date){
          return moment(date).format('LLLL');
        },
      onCaptchaVerified: function (recaptchaToken) {
        this.request.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        this.request.recaptcha = ''
        },
    onButtonClick(id) {
      this.$refs[id].click()
      this.selectedFile = id;
    },
    getUserProfileParent() {
        let flag = 1;
        let self = this;
        self.loading = true;
        let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
          axios
            .get("/api/user",header)
            .then(response => {
                let data = response.data;

              if (flag == 1) {
                  this.user = data.user;
                  let settings = localStorage.getItem('settings');
                  settings = JSON.parse(settings);
                  let item = 1;
                  
                  if(parseInt(this.user.credit) < (parseInt(settings.request_charge) * item)){
                    this.creditFlag = true;
                    this.dialogImport = true;
                  }else{
                    this.dialogImport = true;
                    this.creditFlag = false;
                  }
                  self.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                    self.loading = false;
                }

            })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });

              });

        },
        addCreditCheck(){
          if(this.credentials.type == 'Jotform')
            this.dialogAssign = true
          else
            this.$router.push('/pricing');
        },
        undo() {
      this.$refs.signaturePad.undoSignature();
    },
    StartProcess1(flag){
          let id = this.selected_id;
          let multiple_flag = 0;
          if(id == 'all')
            multiple_flag = 1
          var r = confirm("Are you sure, You went to start process 1?");
            if (r == true) {

              this.loadingUpload2 = true;
              this.dialogDoc = false;
                    axios
                .post("/api/process1/"+id+"/"+flag,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Process started successfully', {
                            position: 'top-right'
                            });
                            this.loadingUpload2 = false;
                            this.getUserProfile();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                            this.loadingUpload2 = false;
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        importReport(){
          axios({
          method: "POST",
          url: "/api/import-report",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {source: this.source_code}
          })
          .then(response => {
          console.log(response);
         
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              
          });
        },
        submitGrantApplication(){
      const data = new FormData();
      data.append('user_id', this.id);
      data.append('file_1', this.grant.file_1);
      data.append('file_2', this.grant.file_2);
      data.append('grant',JSON.stringify(this.grant));
      this.loadingGrantApplication = true
      axios({
      method: "POST",
      url: "/api/submit-grant-application",
      data:data,
      headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
      })
      .then(response => {
        this.loadingGrantApplication = false
          if (response.data) {
                        Vue.$toast.success(' Application submitted successfully', {
                            position: 'top-right'
                            });
          }
      })
      .catch(err => {
        this.loadingGrantApplication = false
          Vue.$toast.error(err, {
          position: 'top-right'
          });
      })
    },
    getFocusArea(){
       axios({
          method: "POST",
          url: "/api/get-all-focus-area",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.focusarea = data;
            }
            })
    },
    getLocation(){
       axios({
          method: "POST",
          url: "/api/get-all-location",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.locations = data;
            }
            })
    },
    getContracts(){
       axios({
          method: "GET",
          url: "/api/get-contract-template/"+this.id,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.value;
            console.log('suman',data);
            if(data){
              this.contract = data;
            }
            })
    },
    onFileChanged(e) {
      let self = this;
      let selectedFile = e.target.files[0]
      // if(!selectedFile.name.includes(self.client.email)){
      //   Vue.$toast.error("Document should contain user email", {
      //         position: 'top-right'
      //         });
      // }else{
      const data = new FormData();
          if(self.selectedFile == 'file_id')
            data.append('file_id', selectedFile);
          if(self.selectedFile == 'file_bill')
            data.append('file_bill', selectedFile);
          if(self.selectedFile == 'file_letter_a')
            data.append('file_letter_a', selectedFile);
          if(self.selectedFile == 'file_letter_b')
            data.append('file_letter_b',selectedFile);
          if(self.selectedFile == 'file_letter_c')
            data.append('file_letter_c',selectedFile);
          data.append('flag',self.selectedFile.replace('file_', ''));
          data.append('user_id',localStorage.getItem('user_id'));
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      // }
      
      // do something
    },
    getReportFromSmartCredit(){
      if(this.import_from == 'Smart Credit'){
          if(!this.username){
            Vue.$toast.error("Username is required! ", {
              position: 'top-right'
              });
          }else if(!this.password){
            Vue.$toast.error("Password is required! ", {
              position: 'top-right'
              });
          }else{
            this.loadingImport = true;
          this.isDisableImport = true;
          this.isDarkImport = false;
          axios({
          method: "POST",
          url: "/api/import-smart-credit",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {username: this.username,password : this.password, user_id: this.id}
          })
          .then(response => {
          console.log(response);
          if(response.data.flag == 1){
            let id = response.data.value.id;
            this.loadingImport = false;
            this.$router.push('/credit-report/'+id)
            
          }else{
            Vue.$toast.error("Can't import as invalid credentials ", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
          }
         
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              
          });
          }
        }else{
          this.getReportFromMyfreescorenow();
        }
        },
        getReportFromMyfreescorenow(){
          
          if(!this.username){
            Vue.$toast.error("Username is required! ", {
              position: 'top-right'
              });
          }else if(!this.password){
            Vue.$toast.error("Password is required! ", {
              position: 'top-right'
              });
          }else{
            this.loadingImport = true;
          this.isDisableImport = true;
          this.isDarkImport = false;
          axios({
          method: "POST",
          url: "/api/import-report-myfreescorenow",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {username: this.username,password : this.password, user_id: this.id}
          })
          .then(response => {
          console.log(response);
          if(response.data.flag == 1){
            let id = response.data.value.id;
            this.loadingImport = false;
            this.$router.push('/credit-report/'+id)
            
          }else{
            Vue.$toast.error("Can't import as invalid credentials ", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
          }
         
          })
          .catch(err => {
              Vue.$toast.error("Can't import as invalid credentials", {
              position: 'top-right'
              });
              this.loadingImport = false;
              this.isDisableImport = false;
              this.isDarkImport = true;
              
          });
          }
        },
    getCompanySettings(){
       axios({
          method: "POST",
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.credentials = data.credentials;
            }
            })
    },
      getUserProfileParent() {
    let flag = 1;
    let self = this;
    self.loading = true;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.user = data.user;
              let settings = localStorage.getItem('settings');
              settings = JSON.parse(settings);
              let item = 1;
              
              if(parseInt(this.user.credit) < (parseInt(settings.request_charge) * item) && this.role.name !='super_admin'){
                this.creditFlag = true;
                this.dialogImport = true;
              }else{
                this.dialogImport = true;
                this.creditFlag = false;
              }
              self.loading = false;
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
                self.loading = false;
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     getUserCredit() {
    let flag = 1;
    let self = this;

    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              localStorage.setItem('credit',data.user.credit);
              
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
                self.loading = false;
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     getGHLChat(){
       axios({
          method: "POST",
          url: "/api/get-ghl-conversation",
          data : {client_id : this.id},
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.conversations = data;
            }
            })
    },
     sendConversation(flag){
      this.conversations.forEach(val => {
        this.conversation.receiver_user_id = val.user_id
        this.conversation.receiver = flag == 'sms' ? val.phone_m : val.email;
      })
      this.conversation.type = flag;
      this.conversation.sender = flag == 'sms' ? this.phone_parent : this.email_parent
      
      let self = this;
      if(!this.conversation.body){
            Vue.$toast.error("Message is required! ", {
              position: 'top-right'
              });
          }else{
      self.loadingChat = true;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        } 
      axios
        .post("/api/send-conversation",this.conversation,header)
        .then(response => {
          self.loadingChat = false;
          Vue.$toast.success('Message Sent', {
                position: 'top-right'
                });
                self.getGHLChat();
                this. conversation =  {
                      sender : '',
                      receiver_user_id : '',
                      receiver : '',
                      subject : '',
                      body : '',
                      type : ''
                    }
        })
        .catch(err => {
          self.loadingChat = false;
            Vue.$toast.error('Unable to send', {
            position: 'top-right'
            });
          })
      }
    },
     renderAddress(flag){
      if(flag == 'res'){
        this.address_list_res.forEach(val=>{
          if(val.name == this.address_select_res){
            this.recipient.name = val.address_1;
            this.recipient.address1 = val.address_1;
            this.recipient.address2 = val.address_2;
            this.recipient.city = val.city;
            this.recipient.state = val.state;
            this.recipient.zip = val.zip;
          }
        })
      }else{
        this.address_list_sen.forEach(val=>{
          if(val.name == this.address_select_sen){
            this.sender.name = val.address_1;
            this.sender.address1 = val.address_1;
            this.sender.address2 = val.address_2;
            this.sender.city = val.city;
            this.sender.state = val.state;
            this.sender.zip = val.zip;
          }
        })
      }
    },
     removeDocuments(){
          if(confirm('Are you sure, You want to remove all documents?')){
            this.loadingImport = true;
            axios({
            method: "POST",
            url: "/api/remove-all-documents",
            headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
            data: {user_id: localStorage.getItem('user_id')}
            })
            .then(response => {
            if(response.data.flag == 1){
              this.loadingImport = false;
              this.doc_id_flag = false;
              this.doc_id_value = ''
              this.doc_bill_flag = false;
              this.doc_bill_value = ''
              this.doc_letter_a_flag = false;
              this.doc_letter_a_value = ''
              this.doc_letter_b_flag = false;
              this.doc_letter_b_value = ''
              this.moreDocuments = [];
              this.getUserProfile();
              Vue.$toast.success("All documents has been removed", {
              position: 'top-right'
              });
            }
            });
          }
        },
      saveMemo(){
        let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          let id  = localStorage.getItem('user_id');
        axios
          .post("/api/save-client-memo",{
            memo : this.client.memo,
            user_id : id
          },header)
          .then(response => {
            this.memoFlag = false
          })
          .catch(err => {
                        Vue.$toast.error(err, {
                        position: 'top-right'
                        });
                    });
      },
      downloadFile(key){
        let self = this;
      let url = process.env.VUE_APP_WASBI_URL.replace('/logo','')
      console.log(this.docs);
      this.docs.forEach(val =>{
        console.log(val);
        if(val.file_name == key){
          window.open(url+val.folder_id+'/'+val.file_name, '_blank');
        }
      })
      // let self = this;

      //           axios({
      //               method: "POST",
      //               url: "/api/download-file",
      //               responseType: 'blob',
      //               headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
      //               data: {key : key,user_id : localStorage.getItem('user_id')}
      //               })
      //               .then(response => {
      //               var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      //              var fileLink = document.createElement('a');

      //              fileLink.href = fileURL;
      //              fileLink.setAttribute('download', response.headers['file-name']);
      //              document.body.appendChild(fileLink);

      //              fileLink.click();
                    
      //               self.isDisableDownload = false;
      //               self.isDarkDownload = true;
      //               })
      //               .catch(err => {
      //                   Vue.$toast.error(err, {
      //                   position: 'top-right'
      //                   });
      //               });
      },
      checkVideoPermission(){
        if(this.welcome_video == 'None'){
          return false;
        }else if(this.welcome_video == 'All'){
          return true;

        }else{
          let welcome_vido_list_client = localStorage.getItem('welcome_vido_list_client');
          if(welcome_vido_list_client.includes(localStorage.getItem('user_id'))){
            return true
          }else{
            return false
          }
        }
      },
      submitRequest(){
           let self = this;
            let flag = 1;
            if (!this.request.recaptcha) {
                Vue.$toast.error('Captcha is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            if (flag == 1) {
                

                this.loadingUpload = true;
                axios({
                method: "POST",
                withCredentials: true,
                url: "/api/user-add-request",
                data:self.request,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data != "False"){
                        // self.$router.push('/thanks');
                        Vue.$toast.success('Request submitted', {
                            position: 'top-right'
                            }); 
                         
                            self.onCaptchaExpired()
                            self.loadingUpload = false;
                            self.dialogImport = false;
                            localStorage.setItem('credit', response.data.new_credit)
                            self.credit = response.data.new_credit;
                            self.getUserProfile();
                        // location.reload();
                    }else{
                        this.isDark = true;
                        this.isDisable = false;
                        
                        Vue.$toast.error(response.data.result.errors.error_message, {
                            position: 'top-right'
                            }); 
                    }
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                    if(data.email){
                        Vue.$toast.error(data.email[0], {
                        position: 'top-right'
                        });
                    }else if(data.password){
                        Vue.$toast.error(data.password[0], {
                        position: 'top-right'
                        });
                    }
                    else if(data.user_name){
                        Vue.$toast.error(data.user_name[0], {
                        position: 'top-right'
                        });
                    }
                    else{
                        Vue.$toast.error(data, {
                        position: 'top-right'
                        });
                    }
                    self.onCaptchaExpired()

                });

            }

        },
        addCredit(){
          let id = localStorage.getItem('user_id');
           var r = this.creditFlag == 'remove' ? confirm("Are you sure, You went to remove credit?") : confirm("Are you sure, You went to add credit?");
          if (r == true) {
            this.loadingAssign = true;
            axios
              .post("/api/add-credit-admin/"+id,{
                credit : this.new_credit,
                flag : this.creditFlag,
                client_flag : true
              },this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                if(this.creditFlag == 'remove'){
                  Vue.$toast.success('Credit removed successfully', {
                      position: 'top-right'
                      });
                }else{
                  Vue.$toast.success('Credit added successfully', {
                      position: 'top-right'
                      });
                }
                      this.creditDialog = false;
                      this.new_credit = 0;
                      this.loadingAssign = false;
                    this.getUserProfile();
                    this.getUserCredit()
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                      this.loadingAssign = false;
                  }

              })
            .catch(err => {
              Vue.$toast.error(err.response.data.message, {
              position: 'top-right'
              });
              this.loadingAssign = false;
            })
          }
        },
      getUserProfile() {
      let flag = 1;
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
          let id  = localStorage.getItem('user_id');
          let wasbi_url = process.env.VUE_APP_WASBI_URL
        axios
          .get("/api/get-client/" + id,header)
          .then(response => {
              let data = response.data.data;
            if (flag == 1) {
              this.client = data;
              localStorage.setItem('company_logo',wasbi_url+data.logo)
              localStorage.setItem('company_id',data.company_id)
              this.tasks = response.data.task;
              this.credit_available = response.data.data.credit
              //this.userContracts =   response.data.data.contracts
              if(response.data.contracts.length != 0){
                response.data.contracts.forEach(val => {
                  this.userContracts.push(val.contract_id);
                })
              }
              this.credit_used = response.data.credit_used
              if(this.client.smart_credit_password)
                this.password = atob(this.client.smart_credit_password);
              if(this.client.smart_credit_user_name)
                this.username = this.client.smart_credit_user_name;
              this.reports = response.data.reports;
              this.grant_count = response.data.grant_count;
              if(data.is_grant)
                this.grant = JSON.parse(data.grant_details);
              if(this.reports.length != 0){
                this.assignData(this.reports[0]);
              }
              this.docs = response.data.doc;
               if(response.data.doc.length != 0){
                response.data.doc.forEach(element => {
                  if(element.file_name.includes("ID")){
                    this.doc_id_flag = true;
                    this.doc_id_value = element.file_name
                  }
                  else if(element.file_name.includes("Bill")){
                    this.doc_bill_flag = true;
                    this.doc_bill_value = element.file_name
                  }
                  else if(element.file_name.includes("Letter_A")){
                    this.doc_letter_a_flag = true;
                    this.doc_letter_a_value = element.file_name
                  }
                  else if(element.file_name.includes("Letter_B")){
                    this.doc_letter_b_flag = true;
                    this.doc_letter_b_value = element.file_name
                  }
                  else if(element.file_name.includes("Letter_C")){
                    this.doc_letter_c_flag = true;
                    this.doc_letter_c_value = element.file_name
                  }else{
                    if(this.moreDocuments.length != 0){
                      let item_exist = false;
                      let index = 0;
                      this.moreDocuments.forEach((val,key) => {
                        if(val.id == element.file_name.match(/\d+/)[0]){
                          item_exist = true;
                          index = key;
                        }
                      })
                      if(item_exist){
                          this.moreDocuments[index].file_content = element.file_name;
                        }else{
                           this.moreDocuments.push({
                            id : element.file_name.match(/\d+/)[0],
                            file_name : element.file_name,
                            file_content : element.file_name,
                            file_full_name : element.document_name
                          })
                        }
                    }else{
                      this.moreDocuments.push({
                        id : element.file_name.match(/\d+/)[0],
                        file_name : element.file_name,
                        file_content : element.file_name,
                        file_full_name : element.document_name
                      })
                    }
                  }

                });
                
              }
              this.client.progress = this.client.progress +1;
              let process = this.client.progress;
                let flag =1;
                let done_flag = 1;
                this.testStates.forEach((val,key) => {
                  if(flag == (process-1)){
                    this.testStates[key].current = true;
                    done_flag = 0;
                    this.currentState = this.testStates[key].name;
                  }
                  flag = flag +1;
                  if(done_flag == 1)
                    this.testStates[key].done = true;
                })
                let count = this.settings.grant_count;
                  for (let index = 1; index <= parseInt(count); index++) {
                    if(this.grant_count == 0){
                      this.grantStates.push( { name: 'Grant '+index,current: false,done : false });
                    }else{
                      if(this.grant_count == index)
                        this.grantStates.push( { name: 'Grant '+index,current: true,done : false });
                      else if(this.grant_count > index)
                        this.grantStates.push( { name: 'Grant '+index,current: false,done : true });
                      else
                        this.grantStates.push( { name: 'Grant '+index,current: false,done : false });
                    }
                    
                  }
              }else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }

          })
            .catch(err => {
              // Vue.$toast.error(err, {
              // position: 'top-right'
              // });

            });

      },
      loadVideo(){
        // let video = document.getElementById('video-preview');
        // video.setAttribute("src", this.welcome_video_url);
        // video.load();
      },
      assignData(data){
        let self = this;
        data = JSON.parse(data.report)
        // Score
        self.FinalData.profile.score.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].riskScore
        self.FinalData.profile.score.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].riskScore
        self.FinalData.profile.score.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].riskScore
        // report date
        self.FinalData.profile.report_date.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0].Source.InquiryDate
        self.FinalData.profile.report_date.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[1].Source.InquiryDate
        self.FinalData.profile.report_date.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[2].Source.InquiryDate
        // name and known as
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.BorrowerName.forEach(element => {
          if(element.Source.Bureau.abbreviation == 'TransUnion'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.transunion = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.transunion = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
          if(element.Source.Bureau.abbreviation == 'Experian'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.experian = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.experian = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
          if(element.Source.Bureau.abbreviation == 'Equifax'){
            if(element.NameType.abbreviation == 'Primary'){
              self.FinalData.profile.name.equifax = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
            if(element.NameType.abbreviation == 'Also Known As'){
              self.FinalData.profile.known_as.equifax = element.Name.first+' '+element.Name.middle+" "+element.Name.last
            }
          }
        });
        //Birth
        self.FinalData.profile.birth.transunion = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[0].date
        self.FinalData.profile.birth.experian = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[1].date
        self.FinalData.profile.birth.equifax = data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.Birth[2].date
        
        self.FinalData.credit_summary.transunion = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.TransUnion, ...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.TransUnion,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.TransUnion };
        self.FinalData.credit_summary.experian = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.Experian, ...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.Experian,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.Experian}
        self.FinalData.credit_summary.equifax = {...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.TradelineSummary.Equifax,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.InquirySummary.Equifax,...data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Summary.PublicRecordSummary.Equifax}
        //Inquiries
        if(!Array.isArray(data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition)){
          data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition =[data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition]
        }
        data.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.InquiryPartition.forEach(element =>{
          if(element.Inquiry.Source.Bureau.abbreviation == 'TransUnion'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : true, experian : false,equifax : false})
          }
          if(element.Inquiry.Source.Bureau.abbreviation == 'Experian'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : false, experian : true,equifax : false})
          }
          if(element.Inquiry.Source.Bureau.abbreviation == 'Equifax'){
            self.FinalData.credit_inquiries.push({title: element.Inquiry.subscriberName,date: element.Inquiry.inquiryDate,transunion : false, experian : false,equifax : true})
          } 
        })
      this.chartOptionseq = {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.equifax)]] 
                } 
              ] 

        
         }
         this.chartOptionsex = {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.experian)]] 
                } 
              ] 

        
         }
         this.chartOptionstu = {
           
              debug: false, 
              type: 'gauge ', 
              legend_visible: false, 
              chartArea_boxVisible: false, 
              xAxis: { 
                /*Used to position marker on top of axis line.*/
                scale: { range: [0, 1], invert: true } 
              }, 
              palette: { 
                pointValue: '%yValue', 
                ranges: [ 
                  { value: 350, color: '#FF5353' }, 
                  { value: 600, color: '#FFD221' }, 
                  { value: 700, color: '#77E6B4' }, 
                  { value: [800, 850], color: '#21D683' } 
                ] 
              }, 
              yAxis: { 
                defaultTick: { padding: 13, enabled: false }, 
                customTicks: [600, 700, 800], 
                line: { 
                  width: 10, 
                  breaks_gap: 0.03, 
                  color: 'smartPalette'
                }, 
                scale: { range: [350, 850] } 
              }, 
              defaultSeries: { 
                opacity: 1, 
                shape: { 
                  label: { 
                    align: 'center', 
                    verticalAlign: 'middle'
                  } 
                } 
              }, 
              series: [ 
                { 
                  type: 'marker', 
                  name: 'Score', 
                  shape_label: { 
                    text: labelText, 
                    style: { fontSize: 25 } 
                  }, 
                  defaultPoint: { 
                    tooltip: '%yValue', 
                    marker: { 
                      outline: { 
                        width: 5, 
                        color: 'currentColor'
                      }, 
                      fill: 'white', 
                      type: 'circle', 
                      visible: true, 
                      size: 20 
                    } 
                  }, 
                  points: [[1, parseInt(self.FinalData.profile.score.transunion)]] 
                } 
              ] 

        
         }
      
      },
        
        

    
    },
     mounted() {
      let self = this;
      // if(!localStorage.getItem('no_tiptext_flag'))
      //   this.dialogTiptext = true;
      if(!localStorage.getItem('token'))
            this.$router.push('/login')
        // this.getUsers();
        this.getUserProfile();
        this.getContracts();
        this.getLocation();
        this.getFocusArea();
        this.getCompanySettings();
        this.getGHLChat();
      if(this.welcome_video != 'false'){
        setTimeout(this.loadVideo, 1000);
        
      }

    
  },
  watch: {
    datatable_options_letter : {
              handler () {
                          this.getLetters()
                          },
                          deep: true,
            },
            datatable_options_grants: {
                handler () {
                    console.log(this.datatable_options_grants);
                this.getUsers()
                },
                deep: true,
            },
            datatable_options_grants_in: {
                handler () {
                    console.log(this.datatable_options_grants_in);
                this.getInGrants()
                },
                deep: true,
            },
            datatable_options_grants_com: {
                handler () {
                    console.log(this.datatable_options_grants_com);
                this.getComGrants()
                },
                deep: true,
            },
        },
}
</script>

<style>
.v-tabs-slider-wrapper{
  height: 5px!important;
}
.card-border{
      color: white;
    -webkit-transform: rotate(-90deg);
    position: absolute;
    justify-content: center;
    text-align: center;
    display: block;
    height: 100%;
    margin-left: 175px; 
}
.checkbox-doc .v-messages{
  display : none;
} 
.only-next{
  display:none!important;
}
.white-text{
  text-align: center;
  color :#FFFFFF;
}
.customer-overview-score-2 {
  margin-bottom: 0px;
  font-size: 70px;
  line-height: 60px;
  text-align: center;
  font-family: Lato, sans-serif;
  font-weight: 700;
  letter-spacing: -4px;
}
.columnChart {
    height: 400px;
}
.text-black{
   text-align: center;
}
.table-one tbody tr:nth-of-type(even) {
   background-color: #EAF1F5;
 }
 .client_card_info_address{
  margin-bottom: 12px;
color: #272727;
font-weight: 700;
 }
 .customer-side-panel-ssn-left{
    display: inline-block;
    font-size: 21px;
    color: #272727;
    font-weight: 700;

 }
 #signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.buttons-sig {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
</style>