<template>
 <v-app id="inspire" >
    <v-main class="auth-pages">
    <div
        class="position-relative min-vh-100"
        :style="company_info && loadingLogo &&  company_info.logo ? `background-image: url(${base_url_img+company_info.logo_background}); background-size: cover;` : `background-image: url(${backgroundLogo}); background-size: cover;` "
      >
        <span class="mask bg-gradient-default opacity-6"></span>
              <v-container class="pb-0 position-relative" >
    <div >
        <v-row class="pl-5 pr-5" >
        <v-col cols="12" align="center" justify="center" >
                <img v-if="!company_info" @click="$router.push('/')" :height="this.$vuetify.breakpoint.xs ? 80 : 300" class="image-logo2" src="@/assets/img/logo.png">
                <img v-if="company_info && company_info.logo" @click="$router.push('/')" :height="this.$vuetify.breakpoint.xs ? 80 : 300" class="image-logo2" :src="base_url_img+company_info.logo">
        </v-col>
        </v-row>
        <v-row align="center" justify="center" class="mt-10">

        <v-col cols="11"  sm="11" md="11" lg="11" xs="10">
            <div class="mt-10"  >
            <h1 class=".font-weight-black mt-10" style="color:white;" >Company information</h1>
          </div>
          <v-row align="center" justify="center" class="mt-10">
            <v-col
                      cols="12"
                      md="6"
                    >
                    <span style="color:white;">First Name<span class="required_span">*</span></span>
                     <v-text-field
                        id="name"
                        name="name"
                        rounded
                        class="font-size-input input-style input-login"
                        width="300px"
                        outlined
                        ref="name"
                        v-model="company.first_name"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <span style="color:white;">Last Name<span class="required_span">*</span></span>
                     <v-text-field
                        id="name"
                        name="name"
                        rounded
                        outlined
                        class="font-size-input input-style input-login"
                        width="300px"
                        ref="name"
                        v-model="company.last_name"
                        />

                    </v-col>
             <v-col
                      cols="12"
                      style="margin-top:-35px;" 
                      md="6"
                    >
                    <span style="color:white;">Business Name<span class="required_span">*</span></span>
                     <v-text-field
                        id="name"
                        name="name"
                        rounded
                        class="font-size-input input-style input-login"
                        outlined
                        width="300px"
                        ref="name"
                        v-model="company.name"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      style="margin-top:-35px;" 
                    >
                    <span style="color:white;">Email/User Name<span class="required_span">*</span></span>
                     <v-text-field
                        id="name"
                        name="name"
                        outlined
                        rounded
                        class="font-size-input input-style input-login"
                        width="300px"
                        ref="name"
                        v-model="company.contact_email"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      style="margin-top:-35px;" 
                    >
                    <span style="color:white;">Password<span class="required_span">*</span></span>
                     <v-text-field
                        id="name"
                        name="name"
                        outlined
                        rounded
                        type="password"
                        class="font-size-input input-style input-login"
                        width="300px"
                        ref="name"
                        v-model="company.password"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      style="margin-top:-35px;" 
                      md="6"
                    >
                    <span style="color:white;">Confirm Password<span class="required_span">*</span></span>
                     <v-text-field
                        id="name"
                        name="name"
                        outlined
                        rounded
                        type="password"
                        class="font-size-input input-style input-login"
                        width="300px"
                        ref="name"
                        v-model="company.confirm_password"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      style="margin-top:-35px;" 
                    >
                    <span style="color:white;">Contact Phone<span class="required_span">*</span></span>
                     <v-text-field
                        id="name"
                        name="name"
                        class="font-size-input input-style input-login"
                        outlined
                        rounded
                        v-mask="'(###) ###-####'"
                        width="300px"
                        ref="name"
                        v-model="company.contact_phone"
                        />

                    </v-col>
                     <v-col
                      cols="12"
                      md="6"
                      style="margin-top:-35px;" 
                    >
                    <span style="color:white;">Full Name</span>
                     <v-text-field
                        id="name"
                        name="name"
                        rounded
                        class="font-size-input input-style input-login"
                        outlined
                        width="300px"
                        ref="name"
                        v-model="company.details"
                        />

                    </v-col>
                    <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:white;">Address<span class="required_span">*</span></span>
                          <vuetify-google-autocomplete
                                  id="map"
                                  v-model="company.address"
                                  placeholder="Enter a location"
                                  outlined
                                  class="font-size-input input-style input-login"
                                  rounded
                                  v-on:placechanged="getAddressData"
                                  country="us"
                              ></vuetify-google-autocomplete>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:white;">City</span>
                          <v-text-field
                          class="font-size-input input-style input-login"
                          v-model="company.city"
                          rounded
                          outlined
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:white;">State</span>
                          <v-select
                          rounded
                          :items="stateOptions"
                          item-text="name"
                          item-value="abbreviation"
                          class="font-size-input input-style input-login"
                          placeholder="Select State"
                          v-model="company.state"
                          outlined
                    ></v-select>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:white;">Zip Code</span>
                          <v-text-field
                          rounded
                          v-model="company.zip"
                          class="font-size-input input-style input-login"
                          outlined
                    ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:white;">What is the cost of Consultation and document preparation (For Complex Cases)?</span>
                          <v-text-field
                          rounded
                          v-model="company.question_1"
                          outlined
                          class="font-size-input input-style input-login"
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:white;">What is the cost of Consultation and document preparation?</span>
                          <v-text-field
                          rounded
                          v-model="company.question_2"
                          class="font-size-input input-style input-login"
                          outlined
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:white;">What is the your monthly cost to client? (Expect to pay up to 6 Months)</span>
                          <v-text-field
                          rounded
                          v-model="company.question_3"
                          class="font-size-input input-style input-login"
                          outlined
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:white;">What is Client Required to pay for monitoring monthly?</span>
                          <v-text-field
                          rounded
                          v-model="company.question_4"
                          outlined
                          class="font-size-input input-style input-login"
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:white;">Other Fees?</span>
                          <v-text-field
                          class="font-size-input input-style input-login"
                          rounded
                          v-model="company.question_5"
                          outlined
                    ></v-text-field>
                      </v-col> -->
            <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-25px;" ></v-col>
            <v-col cols="12"  sm="12" md="12" lg="12" style="margin-top:-25px;" >
                <vue-recaptcha
                    :sitekey="sitekey"
                    :loadRecaptchaScript="true"
                    ref="recaptcha"
                    type="invisible"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                >
            </vue-recaptcha>
            </v-col>
           <v-col cols="12"  sm="12" md="12" lg="12" >
                <v-btn
                    :disabled="isDisable"
                    outlined
                    rounded
                    color="#FDE038"
                    style="height: 53px;
                        padding: 0 40px;
                        text-transform: none;
                        margin-bottom: 50px;" 
                        @click="checkForm"
                        :loading="loading"
                    :dark="isDark"
                >
                    Submit
                </v-btn>
            </v-col>
            <v-col cols="12" >
                <p style="font-size: 18px;
            color: #999999;
            margin-top:-40px;
            line-height: 150%;" >By entering my contact information and clicking "Submit my information now" I consent by electronic signature to be contacted by telephone or by email.</p>
                <p style="font-size: 18px;
            color: #999999;
            line-height: 150%;" ><v-icon style="font-size: 18px;color: #999999;margin-top:-5px">mdi-lock</v-icon> <span>Secure Area</span> | This website is protected by 256-bit SSL security.<br>
            
          </p>
        </v-col>
          </v-row>
        </v-col>
        </v-row>
        <v-dialog
        v-model="dialogClientExist"
        persistent
        max-width="290"
        >
        <v-card>
            <v-card-title class="text-h5">
            Please Confirm
            </v-card-title>
            <v-card-text>One client profile found with same email address. Would you like to continue?</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="dialogClientExist = false"
            >
                Disagree
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="checkForm2(),clientExistFlag = true"
            >
                Agree
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
    </v-container>
    </div>
    </v-main>
 </v-app>
</template>

<script>
import DateDropdown from 'vue-date-dropdown'
import VueRecaptcha from 'vue-recaptcha'
import moment from 'moment'
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
export default {
    components: {
        DateDropdown,
        'vue-recaptcha' : VueRecaptcha,
        'RotateLoader': ClipLoader
    },
    data()  {
        return {
            address: '',
            dob:'',
            age: 0,
            verifyLoad: false,
            dialogClientExist: false,
            verfiedFlag : false,
            base_url_img : process.env.VUE_APP_WASBI_URL,
            loadingLogo : false,
            backgroundLogo: localStorage.getItem('admin_bg'),
            verifyUser: '',
            rules: [
                value => !!value || 'Required.',
            ],
            temp_email  : localStorage.getItem('temp_email') ? true : false,
            company_info : '',
            company : {
                first_name: '',
                last_name: '',
                name: "",
                contact_email: "",
                contact_phone: "",
                details:"",
                email: "",
                password: "",
                password_confirmation: "",
                address: '',
                city: '',
                state: '',
                zip:'',
                question_1 : '',
                question_2 : '',
                question_3 : '',
                question_4 : '',
                question_5 : '',
                recaptcha: '',


                },
            partner : {
                first_name : "",
                last_name : '',
                middle_name: '',
                email : localStorage.getItem('temp_email') ? localStorage.getItem('temp_email') : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                dob: '',
                is_exist : "",
                ssn : '',
                phone_m : '',
                fax : '',
                address :'',
                address1: '',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                is_verified:  0,
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: localStorage.getItem('temp_pass') ? localStorage.getItem('temp_pass') :"123456",
                confirm_password: "",
                crc_id : ""

            },
            isDisable :false,
            loading: false,
            isDark: true,
            clientExistFlag : false,
            existOption: ["Yes","No"],
            stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            year : new Date().getFullYear(),
            form: {
            // Some form values...
                recaptcha: '',
            },
            loadingFlag: false,
            url: '',
            sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
        }
    },
    created () {
        this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';
        if(this.url)
            this.getCompanyInfo(this.url)
        else
            this.loadingFlag = true;
    },
    watch: {
      dob (val) {
        this.age = val ?  moment().diff(this.dob, 'years') :0
        console.log(this.age);
        if(isNaN(this.age))
          this.age = 0;
      },
    },
    beforeCreate: function () {
        if (localStorage.getItem('token')) {
            if(localStorage.getItem('is_admin'))
                this.$router.push('/admin/dashboard')
            else
                this.$router.push('/dashboard')
        }
    },
    methods : {
        getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company_info = response.data.company;
            // this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
        onCaptchaVerified: function (recaptchaToken) {
        this.company.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.company.address = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        verifyCrc(){
            let self = this;
            let flag = 1;
            if (!this.partner.crc_id) {
                Vue.$toast.error('Customer ID is required', {
                    position: 'top-right'
                });
                flag = 0;
            }else{
                self.verifyLoad = true;
                axios({
                method: "POST",
                 
                url: "/api/auth/verify-crc",
                data:{crc_id : self.partner.crc_id},
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.data.success == 'True'){
                        self.verifyUser = response.data.data.result;
                        self.verfiedFlag = true;
                    }else{
                        Vue.$toast.error('Invalid customer id', {
                            position: 'top-right'
                        });
                    }
                    self.verifyLoad = false
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    self.verifyLoad = flase;

                });
            }
        },
        autofill(){
            let self = this;
            self.partner.first_name = self.verifyUser.firstname
            self.partner.last_name = self.verifyUser.lastname
            if(!self.temp_email)
                self.partner.email = self.verifyUser.email
            self.partner.middle_name = self.verifyUser.middlename
            self.partner.phone_m = self.verifyUser.phone_mobile
            self.partner.phone_h = self.verifyUser.phone_home
            self.partner.phone_w = self.verifyUser.phone_work
            self.partner.ssn = self.verifyUser.ssno
            self.address = self.verifyUser.street_address
            if(self.verifyUser.state){
                self.stateOptions.forEach(element => {
                    if(self.verifyUser.state.toUpperCase()  == element.name)
                        self.partner.state = element.abbreviation
                });
            }
            self.partner.zip = self.verifyUser.zip
            self.partner.city = self.verifyUser.city
            if(self.verifyUser.birth_date){
                var temp_dob = self.verifyUser.birth_date.split("/");
                self.partner.dob = temp_dob[1]+'.'+temp_dob[0]+'.'+temp_dob[2]
                console.log(self.partner.dob);
            }
            
        },
        checkForm(){
            let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.company.name) {
            Vue.$toast.error('Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.first_name && flag == 1) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_email && flag == 1) {
            Vue.$toast.error('Contact email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.password && flag == 1) {
            Vue.$toast.error('Password is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (this.company.password != this.company.confirm_password && flag == 1) {
            Vue.$toast.error('Confirm Password is not matched', {
                position: 'top-right'
            });
            flag = 0;
        }
        
        if (!this.company.contact_phone && flag == 1) {
            Vue.$toast.error('Contact phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        
     
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.company.contact_email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
         if (!this.company.recaptcha && flag == 1) {
            Vue.$toast.error('Recaptcha is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        this.company.email = this.company.contact_email;
        

        if (flag == 1) {
            self.isDisable = true;
            self.isDark = false;
          self.company.address_1 = self.company.address;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/register-company",
          data: self.company
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('You have successfully registered with us.', {
                    position: 'top-right'
                  })
                  this.$router.push('admin/')

            } else {
                self.isDisable = false;
            self.isDark = true;
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
                self.isDisable = false;
                self.isDark = true;
            if(err.response.data.flag){
                if(err.response.data.flag == 2){
                     Vue.$toast.error('Company exist with same email address!', {
                    position: 'top-right'
                    });
                }
                if(err.response.data.flag == 3){
                    this.dialogClientExist = true;
                }
            }else{
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });      
            }      
          });
        }
        },
        checkForm2(){
            let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.company.name) {
            Vue.$toast.error('Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_email && flag == 1) {
            Vue.$toast.error('Contact email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_phone && flag == 1) {
            Vue.$toast.error('Contact phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        
     
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.company.contact_email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
         if (!this.company.recaptcha && flag == 1) {
            Vue.$toast.error('Recaptcha is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        this.company.email = this.company.contact_email;
        

        if (flag == 1) {
            self.isDisable = true;
            self.isDark = false;
          self.company.address_1 = self.company.address;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/register-company-revised",
          data: self.company
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('You have successfully registered with us.', {
                    position: 'top-right'
                  })
                  this.dialogClientExist = false;
                  this.$router.push('admin/')

            } else {
                self.isDisable = false;
            self.isDark = true;
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
                self.isDisable = false;
                self.isDark = true;
            if(err.response.data.flag){
                if(err.response.data.flag == 2){
                     Vue.$toast.error('Company exist with same email address!', {
                    position: 'top-right'
                    });
                }
                if(err.response.data.flag == 3){
                    this.dialogClientExist = true;
                }
            }else{
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });      
            }      
          });
        }
        }
    }
}
</script>

<style>
/* .image-logo2{
position: absolute;
margin: -78px -5px -4px 170px;
} */
.date-dropdown-container{
    height: 85px;
}
.date-dropdown-select{
    border: 1px solid #9E9E9E!important;
    padding: 15px!important;
}
.required_span{
    color:red;
}

</style>
<style>
    .input-login .v-icon{
        color: #FDE038!important;
    }
    .input-login input{
        color: #FDE038!important;
    }
    .input-login .v-label{
      color: #FDE038!important;
    }
    .input-login #input-6{
      color: #FDE038!important;
    }
    .input-login #pass{
      color: #FDE038!important;
    }
    .input-login #input-19{
      color: #FDE038!important;
    }
    </style>