<template>
<v-app id="inspire">

<vue-header></vue-header>
<v-main>
    <v-container fluid>
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="8">
              <v-card class="center"  align=""
              style="margin-top:100px;margin-bottom:50px;"
            justify="center">
                <v-card-text style="margin-bottom: -30px;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      v-if="old_request"
                    >
                    <h1 class=" mb-5">Upload required documents and submit to start the process..</h1>
                    <h3 class=" mb-5">Process: {{old_request.process_name}}</h3>
                    <h3 class=" mb-5">Round: {{old_request.round_selected}}</h3>
                    <h3 class=" mb-5">Submited on: {{old_request.created_at}}</h3>
                    </v-col>
                    <v-col
                    v-else
                      cols="12"
                      md="12"
                    >
                    <h1 class=" mb-5">Process not found</h1>
                    </v-col>
                   
                   
                    <v-col cols="12" md="6">
                        <v-file-input
                                    outlined
                                    show-size
                                    v-if="!file_flag.id.name"
                                    v-model="files.id"
                                    accept="image/png, image/jpeg, image/bmp, application/pdf "
                                    prepend-icon=""
                                    label="ID (Max size 5MB)"
                                > <template v-slot:append-outer>
                                    <v-btn
                                      style="margin-top: -18px!important;"
                                      color="blue-grey"
                                      @click="uploadFile('id')"
                                      class="ma-2 white--text"
                                      fab
                                    >
                                      <v-icon dark>
                                        mdi-cloud-upload
                                      </v-icon>
                                   </v-btn>

                                  </template></v-file-input>
                            <v-text-field
                                        outlined
                                      v-if="file_flag.id.name"
                                        v-model="file_flag.id.name"
                                        @keypress="disableinput"
                                        type="text"
                                        :append-icon="'mdi-close'"
                                        @click:append="deleteChip('id')"
                                        label="ID (Max size 5MB)"
                                    >
                                <template v-slot:append-outer>
                                   <v-btn
                                      style="margin-top: -18px!important;"
                                      color="green"
                                      class="ma-2 white--text"
                                      @click="downloadFile(file_flag.id.id)"
                                      fab
                                    >
                                      <v-icon dark>
                                        mdi-cloud-download
                                      </v-icon>
                                   </v-btn>
                                  </template>
                                </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                       <v-file-input
                                    outlined
                                    show-size
                                    v-if="!file_flag.bill.name"
                                    v-model="files.bill"
                                    accept="image/png, image/jpeg, image/bmp, application/pdf "
                                    prepend-icon=""
                                    label="Bill (Max size 5MB)"
                                > <template v-slot:append-outer>
                                    <v-btn
                                      style="margin-top: -18px!important;"
                                      color="blue-grey"
                                      @click="uploadFile('bill')"
                                      class="ma-2 white--text"
                                      fab
                                    >
                                      <v-icon dark>
                                        mdi-cloud-upload
                                      </v-icon>
                                   </v-btn>

                                  </template></v-file-input>
                         <v-text-field
                                    outlined
                                   v-if="file_flag.bill.name"
                                    v-model="file_flag.bill.name"
                                    @keypress="disableinput"
                                    type="text"
                                    :append-icon="'mdi-close'"
                                    @click:append="deleteChip('bill')"
                                    label="Bill (Max size 5MB)"
                                >
                                <template v-slot:append-outer>
                                   <v-btn
                                      style="margin-top: -18px!important;"
                                      color="green"
                                      class="ma-2 white--text"
                                      @click="downloadFile(file_flag.bill.id)"
                                      fab
                                    >
                                      <v-icon dark>
                                        mdi-cloud-download
                                      </v-icon>
                                   </v-btn>
                                  </template>
                                </v-text-field>
                    </v-col>
                     <v-col cols="12" md="6">
                      <v-file-input
                                    outlined
                                    show-size
                                    v-if="!file_flag.letter_a.name"
                                    v-model="files.letter_a"
                                    accept="image/png, image/jpeg, image/bmp, application/pdf "
                                    prepend-icon=""
                                    label="Letter A (Max size 5MB)"
                                > <template v-slot:append-outer>
                                    <v-btn
                                      style="margin-top: -18px!important;"
                                      color="blue-grey"
                                      @click="uploadFile('letter_a')"
                                      class="ma-2 white--text"
                                      fab
                                    >
                                      <v-icon dark>
                                        mdi-cloud-upload
                                      </v-icon>
                                   </v-btn>

                                  </template></v-file-input>
                         <v-text-field
                                    outlined
                                   v-if="file_flag.letter_a.name"
                                    v-model="file_flag.letter_a.name"
                                    @keypress="disableinput"
                                    type="text"
                                    :append-icon="'mdi-close'"
                                    @click:append="deleteChip('letter_a')"
                                    label="Letter A (Max size 5MB)"
                                >
                                <template v-slot:append-outer>
                                   <v-btn
                                      style="margin-top: -18px!important;"
                                      color="green"
                                      class="ma-2 white--text"
                                      @click="downloadFile(file_flag.letter_a.id)"
                                      fab
                                    >
                                      <v-icon dark>
                                        mdi-cloud-download
                                      </v-icon>
                                   </v-btn>
                                  </template>
                                </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-file-input
                                    outlined
                                    show-size
                                    v-if="!file_flag.letter_b.name"
                                    v-model="files.letter_b"
                                    accept="image/png, image/jpeg, image/bmp, application/pdf "
                                    prepend-icon=""
                                    label="Letter B (Max size 5MB)"
                                > <template v-slot:append-outer>
                                    <v-btn
                                      style="margin-top: -18px!important;"
                                      color="blue-grey"
                                      @click="uploadFile('letter_b')"
                                      class="ma-2 white--text"
                                      fab
                                    >
                                      <v-icon dark>
                                        mdi-cloud-upload
                                      </v-icon>
                                   </v-btn>

                                  </template></v-file-input>
                         <v-text-field
                                    outlined
                                   v-if="file_flag.letter_b.name"
                                    v-model="file_flag.letter_b.name"
                                    @keypress="disableinput"
                                    type="text"
                                    :append-icon="'mdi-close'"
                                    @click:append="deleteChip('letter_b')"
                                    label="Letter B (Max size 5MB)"
                                >
                                <template v-slot:append-outer>
                                   <v-btn
                                      style="margin-top: -18px!important;"
                                      color="green"
                                      class="ma-2 white--text"
                                      @click="downloadFile(file_flag.letter_b.id)"
                                      fab
                                    >
                                      <v-icon dark>
                                        mdi-cloud-download
                                      </v-icon>
                                   </v-btn>
                                  </template>
                                </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-file-input
                                    outlined
                                    show-size
                                    v-if="!file_flag.letter_c.name"
                                    v-model="files.letter_c"
                                    accept="image/png, image/jpeg, image/bmp, application/pdf "
                                    prepend-icon=""
                                    label="Letter C (Max size 5MB)"
                                > <template v-slot:append-outer>
                                    <v-btn
                                      style="margin-top: -18px!important;"
                                      color="blue-grey"
                                      @click="uploadFile('letter_c')"
                                      class="ma-2 white--text"
                                      fab
                                    >
                                      <v-icon dark>
                                        mdi-cloud-upload
                                      </v-icon>
                                   </v-btn>

                                  </template></v-file-input>
                         <v-text-field
                                    outlined
                                   v-if="file_flag.letter_c.name"
                                    v-model="file_flag.letter_c.name"
                                    @keypress="disableinput"
                                    type="text"
                                    :append-icon="'mdi-close'"
                                    @click:append="deleteChip('letter_c')"
                                    label="Letter C (Max size 5MB)"
                                >
                                <template v-slot:append-outer>
                                   <v-btn
                                      style="margin-top: -18px!important;"
                                      color="green"
                                      class="ma-2 white--text"
                                      @click="downloadFile(file_flag.letter_c.id)"
                                      fab
                                    >
                                      <v-icon dark>
                                        mdi-cloud-download
                                      </v-icon>
                                   </v-btn>
                                  </template>
                                </v-text-field>
                    </v-col>
                    <v-col cols="12"  sm="12" md="4" lg="4" ></v-col>
                     <v-col cols="12"  sm="12" md="4" lg="4" >
                    <v-card-actions class="justify-center">
                  <!-- <v-btn
                      dark
                    width="300px"
                    color="primary"
                    class="justify-center"
                    @click="saveProfile"
                  >Save</v-btn> -->
                  <v-btn
                    :disabled="isDisable"
                    color="#00aeef"
                    block
                    style="height: 53px;
                    width:50%;
                        padding: 0 40px;
                        margin-bottom: 50px;" 
                        @click="saveProfile"
                        :loading="loading"
                    :dark="isDark"
                >
                    Submit
                </v-btn>
                </v-card-actions>
                    </v-col>
                     
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
    </v-container>
    
     <vue-footer></vue-footer>
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        name:'',
        phone:'',
        password : '',
        is_admin:0,
        confpassword : '',
        role : '',
        user: [],
        companyname : '',
        sector : '',
        isDark: true,
        loading:false,
        isDisable :false,
        no_of_employee : '',
        showPassword : false,
        file_flag : {
          agreement : {
                          name : '',
                          id :''
                        },
          id :{
                          name : '',
                          id :''
                        },
          bill :{
                          name : '',
                          id :''
                        },
          letter_a : {
                          name : '',
                          id :''
                        },
          letter_b : {
                          name : '',
                          id :''
                        },
          letter_c : {
                          name : '',
                          id :''
                        },
        },
        phoneFlag:0,
        emailFlag:0,
        files : {
          agreement : [],
          id : [],
          bill:[],
          letter_a : [],
          letter_b : [],
          letter_c : [],
        },
        country :'',
        otp_email:'',
        otp_phone:'',
        company : {
          name: "",
          contact_email: "",
          contact_phone: "",
          details:"",
          email: "",
          password: "",
          password_confirmation: ""

        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        old_request: '',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    disableinput($event){
       $event.preventDefault();
    },
    downloadFile(key){
      let self = this;
            this.isDisableDownload = true;
            this.isDarkDownload = false;
                axios({
                    method: "POST",
                    url: "/api/download-file",
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                    data: {key : key}
                    })
                    .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   var fileLink = document.createElement('a');

                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', response.headers['file-name']);
                   document.body.appendChild(fileLink);

                   fileLink.click();
                    
                    self.isDisableDownload = false;
                    self.isDarkDownload = true;
                    })
                    .catch(err => {
                        Vue.$toast.error(err, {
                        position: 'top-right'
                        });
                    });
    },
    uploadFile(id){
      let self = this;
      let flag = 1;
      if(id =='agreement' && self.files.agreement.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }
      else if(id =='id' && self.files.id.length == 0){
         Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='bill' && self.files.bill.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_a' && self.files.letter_a.length == 0){
         Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_b' && self.files.letter_b.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_c' && self.files.letter_c.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }
      else{
          const data = new FormData();
          if(id == 'agreement')
            data.append('file_agreement', self.files.agreement);
          if(id == 'id')
            data.append('file_id', self.files.id);
          if(id == 'bill')
            data.append('file_bill', self.files.bill);
          if(id == 'letter_a')
            data.append('file_letter_a', self.files.letter_a);
          if(id == 'letter_b')
            data.append('file_letter_b',self.files.letter_b);
          if(id == 'letter_c')
            data.append('file_letter_c',self.files.letter_c);
          data.append('flag',id);
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      }
    },
    deleteChip(index){
      if(index == 'agreement')
        this.file_flag.agreement.name = ''
      if(index == 'id')
        this.file_flag.id.name = '';
      if(index == 'bill')
        this.file_flag.bill.name = '';
      if(index == 'leter_a')
        this.file_flag.letter_a.name = '';
      if(index == 'letter_b')
        this.file_flag.letter_b.name = '';
      if(index == 'letter_c')
        this.file_flag.letter_c.name = '';
    },
    getRequestDetails(){
      console.log(this.$route);
       let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-request-details/"+this.$route.params.id,header)
        .then(response => {
            let data = response.data;
            console.log(data);
            this.old_request = data.data;
        })
        .catch(err =>{

        })
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password is required', {
                position: 'top-right'
            });
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password is required', {
                position: 'top-right'
            });
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success('Password has been changed', {
                position: 'top-right'
                });
            this.password = '';
            this.confpassword = '';

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },

    add: function () {
         // for setting tab
    },

    getUserProfile() {
    let flag = 1;
    let self = this;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.user = data.user;
              if(data.docs.length != 0){
                data.docs.forEach(element => {
                  console.log(element);
                  if(element.file_name.includes("ID")){
                    self.file_flag.id.name = element.file_old_name;
                    self.file_flag.id.id = element.file_name;
                  }
                  if(element.file_name.includes("Agrement")){
                    self.file_flag.agreement.name = element.file_old_name;
                    self.file_flag.agreement.id = element.file_name;
                  }
                  if(element.file_name.includes("Bill")){
                    self.file_flag.bill.name = element.file_old_name;
                    self.file_flag.bill.id = element.file_name;
                  }
                  if(element.file_name.includes("Letter_A")){
                    self.file_flag.letter_a.name = element.file_old_name;
                    self.file_flag.letter_a.id = element.file_name;
                  }
                  if(element.file_name.includes("Letter_B")){
                    self.file_flag.letter_b.name = element.file_old_name;
                    self.file_flag.letter_b.id = element.file_name;
                  }
                  if(element.file_name.includes("Letter_C")){
                    self.file_flag.letter_c.name = element.file_old_name;
                    self.file_flag.letter_c.id = element.file_name;
                  }
                });
              }
              console.log(self.files);
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },




    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.old_request) {
            Vue.$toast.error('Process not found', {
                position: 'top-right'
            });
            flag = 0;
        }
        
        if (flag == 1) {
          this.isDisable = true;
          this.isDark  = false;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/restart-process",
          data: this.old_request
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Request submitted', {
                    position: 'top-right'
                  })
                  this.isDisable = false;
                   this.isDark  = true;
                  this.$router.push('/my-profile')

            } else {
                Vue.$toast.error('Failed To submit. Please contact support', {
                position: 'top-right'
                });
                this.isDisable = false;
                this.isDark  = true;
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },

created() {
      localStorage.removeItem('temp_request_id')
      console.log('created called.');
      this.getUserProfile();
      this.getRequestDetails();
    },
    

}
</script>

