<template>
<v-app >
<vue-header-admin></vue-header-admin>
 <v-main>
<v-container fluid style="margin-bottom:150px;"  class="px-6 py-6">
            <v-row class="mb-12 mt-5">

         <v-col md="12" class="mt-5">



            <v-card
    class="card-shadow border-radius-xl"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <div class="px-6 pt-4 pb-0 d-flex">
      <v-avatar
        color="bg-gradient-success border-radius-xl mt-n8"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24"
          >language</v-icon
        >
      </v-avatar>
      <p
        class="font-weight-bold text-h6 mb-0 ms-3"
        :class="$route.name == 'VrDefault' ? 'text-white' : 'text-typo'"
      >
        Grants
      </p>
    </div>
    <v-row>
      <v-col lg="12" md="12">
        <v-card-text class="py-3">
          <v-list class="py-0 bg-transparent">
            <v-list-item
              class="px-4 py-1"
            >
              

              <v-list-item-content >
                <!-- <span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Focus Area:
                </span> -->
                <v-list-item-title
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ><v-select
                            item-text="name"
                            item-value="id"
                            label="Focus Area"
                            @change="getDashboardGrant()"
                            :disabled="loadingAssign"
                            v-model="selected_focus_area"
                            :items="focusarea"
                            ></v-select></v-list-item-title>
              </v-list-item-content>

              <v-list-item-content >
               
                <v-list-item-title
                  class="text-sm mb-0 ml-10"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ><span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Grant Total: {{focus_area_grant_total}}
                </span> </v-list-item-title>
              </v-list-item-content>

              

              <v-list-item-content >
                
                <v-list-item-title
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ><span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Grant Completed: {{focus_area_grant_completed}}
                </span></v-list-item-title>
              </v-list-item-content>
               <v-list-item-content >
                
                <v-list-item-title
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ><span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Grant Value: {{getValue(focus_area_value)}}
                </span></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list class="py-0 bg-transparent">
            <v-list-item
              class="px-4 py-1"
            >
              

              <v-list-item-content >
                <v-list-item-title
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ><v-select
                            item-text="name"
                            item-value="id"
                            label="Location:"
                             @change="getDashboardGrant()"
                            :disabled="loadingAssign"
                            v-model="selected_location"
                            :items="locations"
                            ></v-select></v-list-item-title>
              </v-list-item-content>

              <v-list-item-content >
                
                <v-list-item-title
                  class="text-sm mb-0 ml-10"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ><span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Grant Total: {{location_grant_total}}
                </span></v-list-item-title>
              </v-list-item-content>

              

              <v-list-item-content >
                
                <v-list-item-title
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ><span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Grant Completed: {{location_grant_completed}}
                </span></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content >
                
                <v-list-item-title
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ><span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Grant Value: {{getValue(location_value)}}
                </span></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-col>
      <v-col lg="6" md="5">
        <div id="map" class="mt-0 mt-lg-n4 w-100 h-100 overflow-hidden"></div>
      </v-col>
    </v-row>
  </v-card>





         </v-col>
      </v-row>

      <v-row class="mb-6 mt-10">
        <v-col
          v-for="(item, i) in imageCards"
          :key="item.title + i"
          lg="4"
          class="pt-0 mb-lg-0 mb-10"
        >
          <v-card
            class="card card-shadow border-radius-xl py-5 text-center"
            data-animation="true"
          >
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <img
                  :src="item.image"
                  class="img-fluid shadow border-radius-lg"
                  :alt="item.image"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }"
              ></div>
            </div>
            <div class="d-flex mx-auto mt-n8">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="material-icons-round text-primary ms-auto px-5"
                    size="18"
                  >
                    refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="material-icons-round text-info me-auto px-5"
                    size="18"
                  >
                    edit
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </div>
            <h5 class="font-weight-normal text-typo text-h5 mt-7 mb-2 px-4">
              <a
                href="javascript:;"
                class="text-decoration-none text-default"
                >{{ item.title }}</a
              >
            </h5>
            <p class="mb-0 text-body font-weight-light px-5">
              {{ item.text }}
            </p>
            <hr class="horizontal dark my-6" />
            <div class="d-flex text-body mx-2 px-4">
              <p class="mb-0 font-weight-normal text-body">
                ${{ item.price }}/night
              </p>
              <i
                class="
                  material-icons-round
                  position-relative
                  ms-auto
                  text-lg
                  me-1
                  my-auto
                "
                >place</i
              >
              <p class="text-sm my-auto font-weight-light">
                {{ item.location }}
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
</v-container>
<vue-footer-admin></vue-footer-admin>
</v-main>


    <!-- <v-main>
    <v-container fluid>
        <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12" md="12">
                <v-card-text style="margin-bottom: -30px;">
                  <v-row>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <h1 class=" mb-5" style="color: rgba(0,0,0,.6);">Dashboard</h1>
                    </v-col>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                    </v-col>
                     <v-col
                      cols="12"
                      md="3"
                      v-if="role.name !='super_admin' && role.name !='company_user'"
                    >
                      <v-card
                            class="mx-auto"
                            elevation="2"
                            outlined
                        >
                            <v-list-item three-line>
                              
                             <v-btn
                             @click="$router.push('/admin/pricing')"
                            class="mx-2 mr-10 ml-5"
                            fab
                            dark
                            small
                            color="success"
                            >
                            <v-icon dark>
                                mdi-wallet
                            </v-icon>
                            </v-btn>
                            <v-list-item-content>
    
                                <v-list-item-title class="headline mb-1">
                                 CREDIT
                                </v-list-item-title>
                                <v-list-item-subtitle>Available Credit:</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="credit == 0 || credit == 0.00"><h1 style="color:red;" >${{credit}}</h1></v-list-item-subtitle>
                                <v-list-item-subtitle v-else><h1 style="color:green;" >{{credit}}</h1></v-list-item-subtitle>
                            </v-list-item-content>

                            </v-list-item>
                        </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
            </v-col>
        </v-row>
        <v-dialog
          v-model="dialogDoc"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Process 1
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(1)"
                            :color="'green'"
                          >
                          Server 1
                          </v-btn>
              </span>
            </v-card-text>
            <v-card-text >
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="StartProcess1(2)"
                            :color="'green'"
                          >
                          Server 2
                          </v-btn>
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogDoc = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
         <v-dialog
          v-model="process_status_dialog"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Transaction {{transaction.Key}}
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <b>Status: </b>
              </span>
              <span class="text-h5" v-if="!transaction.ProcessingException" >
                {{transaction.Status}}
              </span>
              <span class="text-h5" v-else >
                {{transaction.Status}} ({{transaction.ProcessingException.Reason}})
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="process_status_dialog = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </v-container>
</v-main>

</v-app> -->
<!-- <v-app id="inspire" v-if="role.name == 'super_admin'" style="background:#F9F9F9;">
<vue-header-admin></vue-header-admin>
    <v-main>
                       <v-tabs
                          v-model="tab"
                        background-color="deep-purple accent-4"
                        centered
                        dark
                        icons-and-text
                      >
                        <v-tabs-slider></v-tabs-slider>

                        <v-tab v-for=" link in links" :key="link.id" :target="link.is_iframe == 0 ? '_blank':''" :href="link.is_iframe == 1 ? '#tab-'+link.id : link.url" style="white-space: inherit;text-transform:none;">
                          {{link.title}}
                          <v-icon>{{link.icon}}</v-icon>
                        </v-tab>
                       
                      </v-tabs>
                      
                      <v-tabs-items v-model="tab">
                        <v-tab-item
                          v-for="link in links"
                          :key="link.id"
                          style="height: 100%!important;"
                          :value="'tab-' + link.id"
                        >
                          <iframe
                            id="JotFormIFrame-90142788412154"
                            title="Trainee Registration Form"
                            onload="window.parent.scrollTo(0,0)"
                            allowtransparency="true"
                            allowfullscreen="true"
                            allow="geolocation; microphone; camera"
                            :src="link.url"
                            frameborder="0"
                            style="width: 1px;
                            min-width: 100%;
                            height:100%;
                            border:none;"
                            scrolling="yes"
                            ></iframe>
                        </v-tab-item>
                      </v-tabs-items>
                  
</v-main>

</v-app> -->
</v-app>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import {Circle8} from 'vue-loading-spinner'
import moment from 'moment';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
import SalesCountry from "@/views/Dashboard/Widgets/SalesCountry.vue";
import CardWebsiteViews from "@/views/Dashboard/Widgets/CardWebsiteViews.vue";
import CardDailySales from "@/views/Dashboard/Widgets/CardDailySales.vue";
import CardCompletedTasks from "@/views/Dashboard/Widgets/CardCompletedTasks.vue";
import Footer from '../Footer.vue';
export default {
    components : {
      'RotateLoader': ClipLoader,
      SalesCountry,
      CardWebsiteViews,
      CardDailySales,
      CardCompletedTasks
    },
    
    data () {
      return {
        header : { headers : {
            Authorization:"Bearer " + localStorage.getItem('token')
            },
        },
        imageCards : [],
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 3 || 'Min 3 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
        transaction : [],
        loading:true,
        dialog:false,
        focusarea : [],
        locations : [],
        selected_focus_area : 'all',
        selected_location : 'all',
        focus_area_grant_total : 0,
        focus_area_grant_completed : 0,
        focus_area_value : 0,
        location_value : 0,
        location_grant_total: 0,
        location_grant_completed : 0,
        tab: null,
        initials:'',
        role: JSON.parse(localStorage.getItem('role')),
        valid:true,
        pageCount:1,
        documentList:[],
        totalCount:0,
        page:1,
        links: [],
        itemsPerPage:10,
        selected_job:'',
        credit : localStorage.getItem('credit') ?   localStorage.getItem('credit') : 0.00,
        dialogDoc:false,
        offset: 0,
        selected_id : '',
        limit: 10,
        selected_project:'',
        checkbox: '',
        process_status_dialog : false,
        headers_jobs: [
          { text: 'Name', value: 'name',
            sortable: false },
          { text: 'Email', value: 'email',
            sortable: false },
          { text: 'Phone', value: 'phone',
            sortable: false },
            { text: 'Date of birth', value: 'date_of_birth',
            sortable: false },
            { text: 'Address', value: 'address',
            sortable: false },
            { text: 'Status', value: 'status',
            sortable: false },
            // { text: 'CRC ID', value: 'crc_id',
            // sortable: false },
          { text: 'Created at', value: 'created_at',
            sortable: false,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: false,width: '200' },
            { text: 'Documents', value: 'document',
            sortable: false,width: '150' },
            { text: 'Change Status', value: 'status_id',
            sortable: false,width: '150' },
            { text: 'Process 1', value: 'process1',
            sortable: false,width: '150' },
            { text: 'Process 1 Status', value: 'process_1_key',
            sortable: false,width: '150' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        projects: [
          
        ],
        users: []
      }
    },
    created () { 
        if(!localStorage.getItem('token'))
            this.$router.push('/login')
        this.getUsers();
        this.getFocusArea();
        this.getLocation();
        this.getDashboardGrant();
        this.getLinks();
    },
    methods:{
      dateFormat(date){
        return moment(date).format("MM/DD/YYYY")+', Age '+moment().diff(date, 'years');
      },
        formatDate(value) {
            return moment(value).format("DD MMM YYYY")
        },
        StartProcess1(flag){
          let id = this.selected_id;
          var r = confirm("Are you sure, You went to start process 1?");
            if (r == true) {
              this.loading = true;
              this.dialogDoc = false;
                    axios
                .get("/api/process1/"+id+"/"+flag,this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Process started successfully', {
                            position: 'top-right'
                            });
                            this.loading = false;
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                            this.loading = false;
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        openDocModal(id){
          
          this.dialogDoc = true;
          axios
            .get("/api/get-documents/"+id,this.header)
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.documentList = data.data;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
           });
        },
        editItem(id){
          this.$router.push('/add-parking/'+id)
        },
        getFocusArea(){
       axios({
          method: "POST",
          url: "/api/get-all-focus-area",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.focusarea.push({name : 'All',id :'all'})
              data.forEach(element => {
                this.focusarea.push(element)
              });
            }
            })
    },
    getValue(val){
      var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',

        });

        return formatter.format(val); /* $2,500.00 */
    },
    
    getLocation(){
       axios({
          method: "POST",
          url: "/api/get-all-location",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.locations.push({name : 'All',id :'all'})
              data.forEach(element => {
                this.locations.push(element)
              });
            }
            })
    },
    getDashboardGrant(){
       axios({
          method: "POST",
          url: "/api/get-dashboard-grant",
          data : {
            focus_area : this.selected_focus_area,
            location : this.selected_location
          },
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data;
            if(data){
              this.focus_area_grant_total = data.focus_area_total
              this.focus_area_grant_completed = data.focus_area_completed
              this.location_grant_total = data.location_total
              this.location_grant_completed = data.location_completed
              this.focus_area_value = data.focus_area_value
              this.location_value = data.location_value
            }
            })
    },

        deleteItem(id){
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-profile/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getUsers();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        changeStatus(id,value){
            this.loading = true
             var r = confirm("Are you sure, You went to change status?");
            if (r == true) {
                    axios
                .get("/api/change-status/"+id+"/"+value,this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Profile status changed successfully', {
                            position: 'top-right'
                            });
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        getLinks(){
          let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
          axios
            .get("/api/get-all-link", {headers: headers})
            .then(response => {
              console.log(response);
              this.links = response.data.data
            })
        },
        getUsers(){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            this.selected_project = name;
            let req = {
              limit : this.limit,
              offset : this.offset
            }
            axios({
          method: "POST",
           
          url: "/api/get-clients",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });
        },
        getProcessStatus(id){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            axios({
              method: "GET",
               
              url: "/api/get-process-status/"+id,
              header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.transaction = data.data.value[0];
                console.log(this.transaction);
                this.process_status_dialog = true;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        

    }
    
}
</script>

<style>
.v-tabs-items{
  height: 100%;
  margin-bottom: 70px;
}
.v-window-item .v-window-item--active{
  height: inherit;
}
</style>