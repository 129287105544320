<template>
<v-app id="inspire">

<vue-header-admin v-if="role.name != 'client'"></vue-header-admin>
<vue-header v-if="role.name == 'client'"></vue-header>
<v-main :style="role.name == 'client' ? 'padding:0px!important;margin-top: 150px;background:white;'  :'background:white;margin-top:15px;'">
    <v-container fluid>
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="8">
              
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h1 class="heading-h2 mt-10 text-h3 text-typo font-weight-bold">Letter Wizard</h1>
                    <p class="">This is where you select items to dispute so you can build your letter. All new clients start with a Round 1 Dispute. Next "Add New Items" manually or "Add Saved/Pending Items." For editing or updating dispute items already saved, use the Dispute Items Page.</p>
                    </v-col>
                    <v-col
                    style="border: solid 1px #666;"
                      cols="12"
                      md="12"
                    >
                    
                   <div class="tbleheading font-18 font-400 m-b-24"><b class="font-700">Step 1:</b> Choose Letter Type</div>
                   <v-radio-group v-model="radioLetter" style="color:black;">
                      <v-radio
                      class="radio-div"
                        :label="`Round 1: Basic Dispute - Credit Bureaus`"
                        value="round_1"
                      ></v-radio>
                      <v-radio
                        :label="`Dispute Process Letters`"
                        value="round_2"
                      ></v-radio>
                    </v-radio-group>
                    <div class="tbleheading font-18 font-400 m-b-24"><b>Letter Recipient</b></div>
                   <v-radio-group v-model="radioLetterRecipient" style="color:black;">
                      <v-radio
                      class="radio-div"
                      @check="step2 = true"
                        :label="`Credit Bureau`"
                        value="credit_1"
                      ></v-radio>
                      <v-radio
                        :label="`Creditor/Furnisher Reporting the Item`"
                        value="credit_2"
                      ></v-radio>
                    </v-radio-group>
                    </v-col>
                    <v-col
                      cols="12"
                      class="mt-5"
                      v-show="radioLetterRecipient =='credit_1'"
                       style="border: solid 1px #666;"
                      md="12"
                    >
                    <div class="tbleheading font-18 font-400 m-b-24"><b class="font-700">Step 2:</b> Choose Dispute Items</div>
                   <p class="grey1 mt-2" id="r1_stepc" style="color: #666 !important;">We recommend never sending more than 5 dispute items per month to each credit bureau (unless it’s identity theft and you are including a police report), otherwise the bureaus may mark your disputes as “frivolous and irrelevant” and reject them.</p>

                   <v-btn
                        color="green"
                        class="mr-5"
                        style="height: 45px;
                        text-transform: none;
                            padding: 0 40px;
                            " 
                            @click="addPendingItemDialog = true"
                        dark
                    >
                        + Add Saved Pending Items
                    </v-btn>
                        <v-btn
                        color="green"
                        outlined
                        style="height: 45px;
                        text-transform: none;
                            padding: 0 40px;
                            " 
                            
                        dark
                    >
                       + Add New Dispute Item
                    </v-btn>
                    <v-data-table
                          item-key="id"
                          :headers="headerDisputeItems"
                          :items="disputeItems"
                          :items-per-page="100"
                          hide-default-footer
                          class="elevation-1 mt-5 mb-5"
                        >
                        <template v-slot:header.trn="{ header }">
                          <img height="25" class="image-logo2" src="@/assets/img/tr.png">
                        </template>
                        <template v-slot:header.exp="{ header }">
                          <img height="25" class="image-logo2" src="@/assets/img/ex.png">
                        </template>
                        <template v-slot:header.eq="{ header }">
                          <img height="25" class="image-logo2" src="@/assets/img/eq.png">
                        </template>
                        <template v-slot:header.delete="{ header }">
                          
                        </template>
                        <template v-slot:item.account="{ item }">
                                            <span> Transunion: {{item.account.transunion}}</span><br/>
                                            <span> Experian: {{item.account.experian}}</span><br/>
                                            <span> Equifax: {{item.account.equifax}}</span><br/>
                                        </template>
                        <template v-slot:item.trn="{ item }">
                              <div style="text-align:center;" >
                                <v-checkbox
                                style="margin-bottom: -35px;"
                                  v-model="item.transunion"
                                  :label="``"
                                ></v-checkbox>
                                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
                              </div>
                        </template>
                        <template v-slot:item.exp="{ item }">
                              <div style="text-align:center;" >
                               <v-checkbox
                                style="margin-bottom: -35px;"
                                  v-model="item.experian"
                                  :label="``"
                                ></v-checkbox>
                                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
                              </div>
                        </template>
                        <template v-slot:item.eq="{ item }">
                              <div style="text-align:center;" >
                                <v-checkbox
                                style="margin-bottom: -35px;"
                                  v-model="item.equifax"
                                  :label="``"
                                ></v-checkbox>
                                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
                              </div>
                        </template>
                        <template v-slot:item.delete="{ item }">
                              <v-btn
                                class="mx-2"
                                text
                                dark
                                @click="unAssignDisputeItems(item)"
                                x-small
                                color="red"
                              >
                                <v-icon dark>
                                  fa-trash
                                </v-icon>
                              </v-btn>
                        </template>
                        
                        </v-data-table>
                        <div style="text-align:right;">
                         <v-btn
                          dark
                          style="height: 45px;
                        text-transform: none;
                            padding: 0 40px;
                            " 
                          @click="generateLeter"
                            color="green"
                            v-show="!step3"
                          >
                            Save and Continue
                          </v-btn>
                        </div>
                    </v-col>
                    <v-col
                    style="border: solid 1px #666;"
                      cols="12"
                      class="mt-5"
                       v-show="step3"
                      md="12"
                    >
                    
                   <div class="tbleheading font-18 font-400 m-b-24"><b class="font-700">Step 3:</b> Choose A Letter</div>
                   <v-row class="mt-5">
                   <v-col
                      cols="12"
                      md="6"
                    >
                    <v-select
                      :items="categories"
                      label="Letter Category *"
                      item-text="category"
                      item-value="id"
                      @input="getLeterName"
                      v-model="letter_category"
                    ></v-select>
                   </v-col>
                   
                   <v-col
                      cols="12"
                      md="6"
                    >
                    <v-select
                      :items="letterNameOptions"
                      label="Letter Name *"
                      item-text="title"
                      item-value="id"
                      v-model="letter_name"
                    ></v-select>
                   </v-col>
                   </v-row>
                   <div style="text-align:right;">
                         <v-btn
                          dark
                          style="height: 45px;
                        text-transform: none;
                            padding: 0 40px;
                            " 
                          @click="generateLtterSave"
                            color="green"
                          >
                            Generate Letter
                          </v-btn>
                        </div>
                    </v-col>

                  </v-row>
            </v-col>
          </v-row>
          <v-overlay :value="overlay">
     <div class="loadingio-spinner-spinner-2k3nmd4xown"><div class="ldio-7xwhhkt0pnf">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>




    </v-overlay>
    <v-dialog
        persistent
      v-model="addPendingItemDialog"
      width="auto"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Add Saved/Pending Items
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <v-data-table
           item-key="id"
           v-model="seletedPendingItems"
          :headers="headerPendingItems"
          :items="pendingItems"
          show-select
          :items-per-page="100"
          hide-default-footer
          class="elevation-1"
        >
        <template v-slot:header.trn="{ header }">
          <img height="25" class="image-logo2" src="@/assets/img/tr.png">
        </template>
        <template v-slot:header.exp="{ header }">
          <img height="25" class="image-logo2" src="@/assets/img/ex.png">
        </template>
        <template v-slot:header.eq="{ header }">
          <img height="25" class="image-logo2" src="@/assets/img/eq.png">
        </template>
        <template v-slot:item.account="{ item }">
                            <span> Transunion: {{item.account.transunion}}</span><br/>
                            <span> Experian: {{item.account.experian}}</span><br/>
                            <span> Equifax: {{item.account.equifax}}</span><br/>
                        </template>
        <template v-slot:item.trn="{ item }">
              <div style="text-align:center;" v-if="item.transunion">
                <v-icon dark color="red">
                  mdi-close-box
                </v-icon> 
                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
              </div>
        </template>
        <template v-slot:item.exp="{ item }">
              <div style="text-align:center;" v-if="item.experian">
                <v-icon dark color="red">
                  mdi-close-box
                </v-icon> 
                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
              </div>
        </template>
        <template v-slot:item.eq="{ item }">
              <div style="text-align:center;" v-if="item.equifax">
                <v-icon dark color="red">
                  mdi-close-box
                </v-icon> 
                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
              </div>
        </template>
        
        </v-data-table>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          dark
          @click="assignDisputeItems"
            color="green"
          >
            Add to Dispute
          </v-btn>
           <v-btn
            color="green"
            outlined
            @click="addPendingItemDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
    </v-container>
    
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        user: '',
        role : JSON.parse(localStorage.getItem('role')),
        client_id : "",
        overlay: false,
        addPendingItemDialog: false,
        seletedPendingItems: [],
        headerPendingItems: [{
          text: 'Creditor/Furnisher',
          align: 'start',
          value: 'creditor',
          sortable: false
        },
        { text: 'Account #', value: 'account',
          sortable: false },
        { text: 'Reason', value: 'reason',
          sortable: false },
        { text: 'Disputed', value: 'disputed',
          sortable: false },
        { text: 'trn', value: 'trn',
          sortable: false },
        { text: 'exp', value: 'exp',
          sortable: false },
        { text: 'eq', value: 'eq',
          sortable: false },],
          headerDisputeItems: [{
          text: 'Creditor/Furnisher',
          align: 'start',
          value: 'creditor',
          sortable: false
        },
        { text: 'Account #', value: 'account',
          sortable: false },
        { text: 'Reason', value: 'reason',
          sortable: false },
        { text: 'Disputed', value: 'disputed',
          sortable: false },
        { text: 'trn', value: 'trn',
          sortable: false },
        { text: 'exp', value: 'exp',
          sortable: false },
        { text: 'eq', value: 'eq',
          sortable: false },
          { text: 'delete', value: 'delete',
          sortable: false },
          ],
        pendingItems : [],
        displayFlag :[],
        disputeItems: [],
        categories: [],
        radioLetter : 'round_2',
        radioLetterRecipient: '',
        StatusOptions : ['Negative', 'Positive', 'Repaired', 'Deleted', 'In Dispute', 'Verified', 'Updated', 'Unspecified', 'Ignore'],
        reasonOptions: ['Please correct/update this inaccurate information on my credit report.','The following accounts were closed by me and should state that','The following account was a Bankruptcy/Charge-off. Balance should be $0','I never gave written permission for this account to be  on my consumer report','This account is not reporting to Experian or Equifax','This account is in violation of my consumer rights'],
        instructionOptions: ['Please correct/update this inaccurate information on my credit report.','Please remove it from my credit report.','This is not mine. I am a victim of ID Theft and I have included a police report. Please investigate and remove from my credit report.','Please supply information on how you have verified this item.','Please ensure that all information is accurate'],
        userFeedback : {
          inquiry : {
            check : [],
            status : {
              transunion : 'Negative',
              experian : 'Negative',
              equifax : 'Negative'
            },
            reason :'',
            instruction : '',
            new_reason: false,
            new_instruction: false
          },
          public_record : [],
          account_history: []
        },
        letterNameOptions : [],
        letter_category : '',
        letter_name: '',
        step2: false,
        step3 :false,
        FinalData: {
          profile : {
                score : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                report_date : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                name : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                known_as : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                birth : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                current_address: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                employer: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                previous_address : {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
          },
          credit_summary : {
                  transunion : {},
                  experian : {},
                  equifax : {},
                
          },
          credit_inquiries: [],
          public_record : [],
          account_history : []
        }
      }
    },

    methods: {
      tableExpand(flag, index){
        

        if (flag === 1) {
          if(!this.displayFlag.includes(index))
            this.displayFlag.push(index);
        } else if(flag === 2) {
         const tmp = this.displayFlag;
         this.displayFlag = [];
         tmp.forEach(val => {
           if(val != index){
             this.displayFlag.push(val);
           }
         })
        }
      },
    assignDisputeItems(){
      this.disputeItems = this.seletedPendingItems;
      let self = this;
      var filtered = this.pendingItems.filter(function(value, index, arr){ 
        if(!self.seletedPendingItems.includes(value)){
            return value
        }
        
    });
    this.pendingItems = filtered;
    this.addPendingItemDialog = false;
    },
    generateLtterSave(){
      if(!this.letter_category || !this.letter_name){
        Vue.$toast.error('Select Letter to continue', {
            position: 'top-right'
            });
      }else{
        localStorage.setItem('credit-report-step-3',JSON.stringify([{
          dispute_items : this.disputeItems,
          letter_category : this.letter_category,
          letter_name : this.letter_name
        }]))
        if(this.role.name == 'client')
          this.$router.push('/credit-report-wizard-step-3/'+this.$route.params.id)
        else
          this.$router.push('/admin/credit-report-wizard-step-3/'+this.$route.params.id)
      }
    },
    unAssignDisputeItems(item){
      let self = this;
      var filtered = this.disputeItems.filter(function(value, index, arr){ 
        if(value != item){
            return value
        }
        
    });
    this.disputeItems = filtered;
    this.pendingItems.push(item);
    this.addPendingItemDialog = false;
    this.seletedPendingItems = [];
    },
    generateLeter(){
      if(this.disputeItems.length == 0){
        Vue.$toast.error('No item seleted', {
            position: 'top-right'
            });
      }else{
        this.step3 = true;
      }
    },
    getUserProfile() {
    let flag = 1;
    let self = this;
    self.overlay = true;
    let id  = this.$route.params.id;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-credit-report/"+id,header)
        .then(response => {

              this.user = JSON.parse(response.data.value.report);
              console.log('suman',this.user.BundleComponents.BundleComponent[6].TrueLinkCreditReportType.Borrower.CreditScore[0]);
              this.assignData();
              this.overlay = false;
         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
    calculatePendingItems(){
      let item = JSON.parse(localStorage.getItem('credit-report-saved-items'))
      let id = 1;
      console.log(item);
      if(item.inquiry.check.length != 0){
        item.inquiry.check.forEach(val => {
          let itemArr = val.split(",");
          this.pendingItems.push({
            transunion : itemArr[1] == "transunion" ? 1 : 0,
            experian : itemArr[1] == "experian" ? 1 : 0,
            equifax : itemArr[1] == "equifax" ? 1 : 0,
            creditor : val.split(',')[0],
            inquiry_date : itemArr[2],
            account : {
              transunion : '',
              experian : '',
              equifax : ''
            },
            reason : item.inquiry.reason,
            instruction : item.inquiry.instruction,
            disputed : 'No',
            id : id
          })
          id = id+1;
        })
      }
      item.public_record.forEach(val => {
        if(val.status.transunion == 'Negative' || val.status.experian == 'Negative' || val.status.equifax == 'Negative'){
          this.pendingItems.push({
            transunion : val.status.transunion == 'Negative' ? 1 : 0,
            experian : val.status.experian == 'Negative' ? 1 : 0,
            equifax : val.status.equifax == 'Negative' ? 1 : 0,
            creditor : val.creditor,
            account : {
              transunion : val.status.transunion == 'Negative' ? val.account.transunion : '',
              experian : val.status.experian == 'Negative' ? val.account.experian : '',
              equifax : val.status.equifax == 'Negative' ? val.account.equifax : ''
            },
            reason : val.reason,
            instruction : val.instruction,
            disputed : 'No',
            id : id
          })
          id = id+1;
        }
      })
      item.account_history.forEach(val => {
        if(val.status.transunion == 'Negative' || val.status.experian == 'Negative' || val.status.equifax == 'Negative'){
          this.pendingItems.push({
            transunion : val.status.transunion == 'Negative' ? 1 : 0,
            experian : val.status.experian == 'Negative' ? 1 : 0,
            equifax : val.status.equifax == 'Negative' ? 1 : 0,
            creditor : val.creditor,
            account : {
              transunion : val.status.transunion == 'Negative' ? (val.account.transunion ? val.account.transunion : '') : '',
              experian : val.status.experian == 'Negative' ? (val.account.experian ? val.account.experian : '') : '',
              equifax : val.status.equifax == 'Negative' ? (val.account.equifax ? val.account.equifax : ''): ''
            },
            reason : val.reason,
            instruction : val.instruction,
            disputed : 'No',
            id : id
          })
          id = id+1;
        }
      })
      
    },



    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.user.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.user.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }

        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/updateprofile",
          data: self.user
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Profile updated', {
                    position: 'top-right'
                  })
                  //this.$router.push('company-profiles')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },
    getCategory() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-category",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.categories = response.data.value
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     getLeterName() {
    let id = this.letter_category;
    let client_id = localStorage.getItem('selected_client');
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-letter-category/"+id+"/"+client_id,header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.letterNameOptions = response.data.value
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },

 },

created() {
      console.log('created called.');
      this.calculatePendingItems()
      this.getCategory();
      // this.getUserProfile();
      

    },

}
</script>
<style >
.heading-h2 {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 38px;
  letter-spacing: normal;
  color: #4a4a4a;
}
.v-input__slot {
  align-items: center;
  justify-content: center;
}
.checkbox-credit .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  left: 0px;
  right: auto;
  position: relative;
  text-align: center!important;
  display: contents!important;
}
.red--text .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  color: red;
}

</style>

<style type="text/css">
@keyframes ldio-7xwhhkt0pnf {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-7xwhhkt0pnf div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-7xwhhkt0pnf linear 1s infinite;
  background: #ffffff;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}.ldio-7xwhhkt0pnf div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #ffffff;
}
.loadingio-spinner-spinner-2k3nmd4xown {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-7xwhhkt0pnf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-7xwhhkt0pnf div { box-sizing: content-box; }
/* generated by https://loading.io/ */
.radio-div .red--text .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  color: black!important;
}
</style>